import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import UpcomingMain from "./UpcomingMain";
import AllreadyDone from "./AllreadyDone";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { widgetReminderListStart } from "../../store/slices/AdminSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";

const UpcomingIndex = (props) => {
  const [step, setStep] = useState(1);
  const params = useParams();
  const dispatch = useDispatch();
  const widgetReminderList = useSelector((state) => state.admin.widgetReminderList);
  const [widgetReminderData, setWidgetReminderData] = useState(null);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    dispatch(widgetReminderListStart());
  }, [params.type]);

  useEffect(() => {
    if (!skipRender && !widgetReminderList.loading && Object.keys(widgetReminderList.data).length > 0 && widgetReminderList.data.widget_reminders.length > 0) 
    {
      setWidgetReminderData(widgetReminderList.data.widget_reminders.find(item => item.widget_type_formatted.toLowerCase() == params.type));
    }
    setSkipRender(false);
  }, [widgetReminderList]);

  return (
    <>
      <div className="upcoming-wrapped">
        {widgetReminderList.loading ? (
          <PageLoader />
        ) : Object.keys(widgetReminderList.data).length > 0 && widgetReminderData ? (
          <>
            {widgetReminderData.status == 1 ? <AllreadyDone widgetReminderData={widgetReminderData} setStep={setStep} /> : <UpcomingMain setWidgetReminderData={setWidgetReminderData} widgetReminderData={widgetReminderData} setStep={setStep} />}  
          </>
        ) : (
          <SomethingWentWrong />
        )}
      </div>
    </>
  );
};

export default UpcomingIndex;
