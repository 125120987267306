import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OnBoarding.css";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchStaticPageStart } from "../../store/slices/PageSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";

const PrivacyPolicyIndex = (props) => {
  const t = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state.page.pageData);

  useEffect(() => {
    dispatch(fetchStaticPageStart({ static_page_unique_id: params.unique_id }));
    window.scrollTo(0, 0);
  }, [params.unique_id]);

  return (
    <>
      <div className="static-page-sec">
        <div className="static-page-box">
          <Row className="justify-content-md-center">
            <Col md={12} xl={7} lg={8}>
              <div className="static-page-card">
                {/* <h3>Privacy Policy</h3>
                            <p>Get a closer look at how Talkdesk drives exceptional business outcomes with a cloud contact center platform built for your industry that is trusted, flexible, and innovative. Watch a demo instantly or request a customized conversation.</p>
                            <ul className="list-unstyled static-page-list">
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" fill="#03363d" />
                                <path d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z" fill="white" stroke="white" />
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" stroke="#03363d" />
                                </svg>
                                <span>See what it's like when all the tools and data an agent needs are unified on a single pane of glass, providing the entire context of a customer's issue.</span>
                            </li>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" fill="#03363d" />
                                <path d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z" fill="white" stroke="white" />
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" stroke="#03363d" />
                                </svg>
                                <span>Watch how customer interactions can be converted seamlessly between channels, keeping all data and context preserved.</span>
                            </li>
                            <li>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" fill="#03363d" />
                                <path d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z" fill="white" stroke="white" />
                                <rect x="0.5" y="0.5" width="22.76" height="22.3251" rx="11.1625" stroke="#03363d" />
                                </svg>
                                <span>Learn how to provide next best actions to assist a customer with an Al-powered assistant that listens, learns and assists in every conversation.</span>
                            </li>
                            </ul> */}
                {pageData.loading ? (
                  <div className="static-page-content">
                    <Skeleton count={1} height={30} className="mb-2" />
                    <Skeleton count={1} height={175} />
                  </div>
                ) : Object.keys(pageData.data).length > 0 &&
                  Object.keys(pageData.data.static_page).length > 0 ? (
                  <div className="static-page-content">
                    <h1 className="text-capitalize">
                      {pageData.data.static_page.title}
                    </h1>
                    <p
                      className="text-capitalize"
                      dangerouslySetInnerHTML={{
                        __html: pageData.data.static_page.description,
                      }}
                    ></p>
                  </div>
                ) : (
                  <NoDataFound />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyIndex;
