import React, { useEffect, useRef, useState, Fragment } from "react";
import { Form, InputGroup, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import {
  sendPreviewMessageStart,
  previewChatListStart,
  previewChatMessagesStart,
} from "../../store/slices/ChatSlice";
import EmojiPicker from "emoji-picker-react";
import ChatPreviewLoader from "../Helper/ChatPreviewLoader";
import AddFaqModal from "./Faqs/AddFaqModal";
import { faqSaveStart } from "../../store/slices/FaqSlice";

const ChatPreview = (props) => {

  const dispatch = useDispatch();
  const { selectedBot, botDetails } = props;
  const previewChats = useSelector((state) => state.chat.previewChats);
  // const selectedBot = useSelector((state) => state.admin.selectedBot);
  const previewChatMessages = useSelector((state) => state.chat.previewChatMessages);
  const sendPreviewMessage = useSelector(
    (state) => state.chat.sendPreviewMessage
  );
  const faqSave = useSelector((state) => state.faq.faqSave);

  const [showEmoji, setShowEmoji] = useState(false);
  const [singleMsg, setSingleMsg] = useState("");
  const [cursorPointer, setCursorPointer] = useState(0);
  const messageField = useRef();
  const lastMsg = useRef();
  const chatEnd = useRef()
  const [addFaq, setAddFaq] = useState(false);
  const [faq, setFaq] = useState({});
  const faqLikeUnlikeList = useSelector((state) => state.faq.faqLikeUnlikeList);

  const handleAddFaq = (message) => {
    setFaq({...faq, 
      question: message.question,
      answer: message.answer,
      preview_chat_message_id: message.preview_chat_message_id,
      status: 1,
    })
    setAddFaq(true);
  }

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (singleMsg && singleMsg.match(/^ *$/) == null) {
      setShowEmoji(false);
      dispatch(
        sendPreviewMessageStart({
          question: singleMsg,
          bot_id: selectedBot.bot_id,
        })
      );
    }
    setSingleMsg("");
  };

  useEffect(() => {
    handleScrollDown();
    if (
      !sendPreviewMessage.loading &&
      Object.keys(sendPreviewMessage.data).length > 0
    ) {
      messageField?.current?.focus();
      chatEnd?.current?.scrollIntoView({ behavior: 'smooth' });
      lastMsg?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [sendPreviewMessage]);

  const onEmojiPick = (data) => {
    const ref = messageField.current;
    ref.focus();
    const start = singleMsg.substring(0, ref.selectionStart);
    const end = singleMsg.substring(ref.selectionStart);
    const text = start + data.emoji + end;
    setSingleMsg(text);
    setCursorPointer(start.length + data.emoji.length);
  };

  useEffect(() => {
    if (Object.keys(selectedBot).length > 0) {
      dispatch(previewChatListStart({ bot_id: selectedBot.bot_id}));
      dispatch(previewChatMessagesStart({ bot_id: selectedBot.bot_id}));
    }
  }, [selectedBot]);

  const handleLikeUnlike = (index, type, message) => {
      if(type==1){
        dispatch(faqSaveStart({
          question: message.question,
          response: message.answer,
          status: 2,
          bot_id: selectedBot.bot_id,
          preview_chat_message_id: message.preview_chat_message_id,
        }))
      }
  };

  const handleScrollDown = () => {
    chatEnd?.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(()=> {
    if(!previewChatMessages.loading && Object.keys(previewChatMessages.data).length> 0)
    handleScrollDown();
  }, [previewChatMessages])


  return selectedBot && Object.keys(selectedBot).length > 0 ? (
    <>
      {/* {previewChats.loading ? (
        <ChatPreviewLoader />
      ) : ( */}
      <>
        <div className="new-chat-preview-card-left-header-sec">
          <div className="new-chat-preview-ip-info-sec">
            <div className="new-chat-preview-user-img-sec bg-yellow">
              <Image
                className="new-chat-preview-user-img"
                src={
                  window.location.origin + "/images/your-bot/your-bot-img.svg"
                }
              />
            </div>
            <div className="new-chat-preview-user-info">
              <h4>{botDetails.name}</h4>
              <a
                target="_blank"
                href={botDetails.website}
                rel="noreferrer"
                className="new-chat-preview-user-url-link">
                {botDetails.website.replace("https://", "")}
              </a>
            </div>
          </div>
          {/* <div className="new-chat-preview-ip-icon-right">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#fff"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M15 3v18c0 1.66-1.34 3-3 3s-3-1.34-3-3V3c0-1.66 1.34-3 3-3s3 1.34 3 3zm6 3c-1.66 0-3 1.34-3 3v12c0 1.66 1.34 3 3 3s3-1.34 3-3V9c0-1.66-1.34-3-3-3zM3 12c-1.66 0-3 1.34-3 3v6c0 1.66 1.34 3 3 3s3-1.34 3-3v-6c0-1.66-1.34-3-3-3z"></path>
            </svg>
          </div> */}
        </div>
        <div className="new-chat-preview-card-left-body-sec">
          {previewChatMessages.loading ? null : Object.keys(
              previewChatMessages.data
            ).length > 0 &&
            previewChatMessages.data.preview_chat_messages &&
            previewChatMessages.data.preview_chat_messages.length > 0 ? (
            <>
              {previewChatMessages.data.preview_chat_messages.map(
                (dailyChat, i) => (
                  <Fragment key={i}>
                    <div className="new-chat-preview-sticky-date-time">
                      {dailyChat.date}
                    </div>
                    {dailyChat.messages.map((message, i) =>
                      message.sent_by !== "user" ? (
                        <div
                          className="new-chat-preview-user-receiver-card"
                          key={i}
                        >
                          <div className="new-chat-preview-user-receiver-icon-sec">
                            <Image
                              className="new-chat-preview-user-receiver-icon"
                              src={
                                window.location.origin +
                                "/images/chat/new-chat-bot-img.png"
                              }
                            />
                          </div>
                          <div className="new-chat-preview-user-receiver-message-card">
                            <div className="new-chat-preview-user-receiver-message">
                              {/* {message.answer} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: urlify(message.message),
                                }}
                              />
                            </div>
                            <div className="client-chat-user-receiver-time">
                              <span>{message.send_at}</span>
                              {message.chat_message_faq.status == 3 && (
                                <div className="client-chat-user-receiver-thumbs-down-icon">
                                  {faqLikeUnlikeList.liked.length > 0 &&
                                  faqLikeUnlikeList.liked.includes(
                                    message.id
                                  ) ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      fill="#048a89"
                                      stroke="#048a89"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      className="feather feather-thumbs-up"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                                    </svg>
                                  ) : (
                                    <Button
                                      variant="link"
                                      disabled={
                                        faqSave.buttonDisable ||
                                        faqLikeUnlikeList.unliked.includes(
                                          message.id
                                        )
                                      }
                                      onClick={() =>
                                        handleLikeUnlike(i, 1, {
                                          question: dailyChat.messages[i - 1]
                                            ? dailyChat.messages[i - 1].message
                                            : "",
                                          answer: message.message,
                                          preview_chat_message_id: message.id,
                                        })
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        stroke="#048a89"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="feather feather-thumbs-up"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                                      </svg>
                                    </Button>
                                  )}
                                  {faqLikeUnlikeList.unliked.length > 0 &&
                                  faqLikeUnlikeList.unliked.includes(
                                    message.id
                                  ) ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      fill="#048a89"
                                      stroke="#048a89"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      className="feather feather-thumbs-down"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                                    </svg>
                                  ) : (
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        handleAddFaq({
                                          answer: message.message,
                                          question: dailyChat.messages[i - 1]
                                            ? dailyChat.messages[i - 1].message
                                            : "",
                                          preview_chat_message_id: message.id,
                                        })
                                      }
                                      disabled={
                                        faqSave.buttonDisable ||
                                        faqLikeUnlikeList.liked.includes(
                                          message.id
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        stroke="#048a89"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        className="feather feather-thumbs-down"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                                      </svg>
                                    </Button>
                                  )}
                                </div>
                              )}
                              {message.chat_message_faq.status == 1 && (
                                <div className="client-chat-user-receiver-thumbs-down-icon">
                                  <Button variant="link" disabled>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      fill="none"
                                      stroke="#048a89"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      className="feather feather-thumbs-up"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                                    </svg>
                                  </Button>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="#048a89"
                                    stroke="#048a89"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="feather feather-thumbs-down"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                                  </svg>
                                </div>
                              )}
                              {message.chat_message_faq.status == 2 && (
                                <div className="client-chat-user-receiver-thumbs-down-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="22"
                                    fill="#048a89"
                                    stroke="#048a89"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    className="feather feather-thumbs-up"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                                  </svg>
                                  <Button variant="link" disabled>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="22"
                                      height="22"
                                      fill="none"
                                      stroke="#048a89"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      className="feather feather-thumbs-down"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                                    </svg>
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="new-chat-preview-user-sender-card"
                          key={i}
                        >
                          <div className="new-chat-preview-user-sender-icon-sec">
                            <Image
                              className="new-chat-preview-user-sender-icon"
                              src={
                                window.location.origin + "/images/chat/user.png"
                              }
                            />
                          </div>
                          <div className="new-chat-preview-user-sender-message-card">
                            <div className="new-chat-preview-user-sender-message">
                              {/* {message.question} */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: urlify(message.message),
                                }}
                              />
                            </div>
                            <div className="client-chat-user-sender-time">
                              {message.send_at}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </Fragment>
                )
              )}
            </>
          ) : (
            <div className="new-chat-preview-user-receiver-card">
              <div className="new-chat-preview-user-receiver-icon-sec">
                <Image
                  className="new-chat-preview-user-receiver-icon"
                  src={
                    window.location.origin + "/images/chat/new-chat-bot-img.png"
                  }
                />
              </div>
              <div className="new-chat-preview-user-receiver-message-card">
                <div className="new-chat-preview-user-receiver-message">
                  {selectedBot.welcome_message}
                </div>
                <div className="client-chat-user-receiver-time">
                  {new Date().toLocaleString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </div>
              </div>
            </div>
          )}
          {Object.keys(previewChats.data).length > 0 &&
          previewChats.data.messages &&
          previewChats.data.messages.length > 0 ? (
            <>
              {/* <div className="new-chat-preview-sticky-date-time">July 20, 2023</div> */}
              {previewChats.data.messages.map((message, i) =>
                message.sent_by === "user" ? (
                  <div className="new-chat-preview-user-receiver-card" key={i}>
                    <div className="new-chat-preview-user-receiver-icon-sec">
                      <Image
                        className="new-chat-preview-user-receiver-icon"
                        src={
                          window.location.origin +
                          "/images/chat/new-chat-bot-img.png"
                        }
                      />
                    </div>
                    <div className="new-chat-preview-user-receiver-message-card">
                      <div className="new-chat-preview-user-receiver-message">
                        {/* {message.answer} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(message.answer),
                          }}
                        />
                      </div>
                      <div className="client-chat-user-receiver-time">
                        <span>{message.send_at}</span>
                        <div className="client-chat-user-receiver-thumbs-down-icon">
                          {faqLikeUnlikeList.liked.length > 0 &&
                          faqLikeUnlikeList.liked.includes(message.id) ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="#048a89"
                              stroke="#048a89"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="feather feather-thumbs-up"
                              viewBox="0 0 24 24"
                            >
                              <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                            </svg>
                          ) : (
                            <Button
                              variant="link"
                              disabled={
                                faqSave.buttonDisable ||
                                faqLikeUnlikeList.unliked.includes(message.id)
                              }
                              onClick={() =>
                                handleLikeUnlike(i, 1, {
                                  question: message.question,
                                  answer: message.answer,
                                  preview_chat_message_id: message.id,
                                })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#048a89"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="feather feather-thumbs-up"
                                viewBox="0 0 24 24"
                              >
                                <path d="M14 9V5a3 3 0 00-3-3l-4 9v11h11.28a2 2 0 002-1.7l1.38-9a2 2 0 00-2-2.3zM7 22H4a2 2 0 01-2-2v-7a2 2 0 012-2h3"></path>
                              </svg>
                            </Button>
                          )}
                          {faqLikeUnlikeList.unliked.length > 0 &&
                          faqLikeUnlikeList.unliked.includes(message.id) ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              fill="#048a89"
                              stroke="#048a89"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="feather feather-thumbs-down"
                              viewBox="0 0 24 24"
                            >
                              <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                            </svg>
                          ) : (
                            <Button
                              variant="link"
                              onClick={() =>
                                handleAddFaq({
                                  answer: message.answer,
                                  question: message.question,
                                  preview_chat_message_id: message.id,
                                })
                              }
                              disabled={
                                faqSave.buttonDisable ||
                                faqLikeUnlikeList.liked.includes(message.id)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                fill="none"
                                stroke="#048a89"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="feather feather-thumbs-down"
                                viewBox="0 0 24 24"
                              >
                                <path d="M10 15v4a3 3 0 003 3l4-9V2H5.72a2 2 0 00-2 1.7l-1.38 9a2 2 0 002 2.3zm7-13h2.67A2.31 2.31 0 0122 4v7a2.31 2.31 0 01-2.33 2H17"></path>
                              </svg>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="new-chat-preview-user-sender-card" key={i}>
                    <div className="new-chat-preview-user-sender-icon-sec">
                      <Image
                        className="new-chat-preview-user-sender-icon"
                        src={window.location.origin + "/images/chat/user.png"}
                      />
                    </div>
                    <div className="new-chat-preview-user-sender-message-card">
                      <div className="new-chat-preview-user-sender-message">
                        {/* {message.question} */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(message.question),
                          }}
                        />
                      </div>
                      <div className="client-chat-user-sender-time">
                        {message.send_at}
                      </div>
                    </div>
                  </div>
                )
              )}
              <div ref={lastMsg} />
            </>
          ) : null}
          <div ref={chatEnd} />
          <div className="new-chat-preview-card-left-footer-form">
            <Form onSubmit={handleSubmit}>
              {showEmoji ? <EmojiPicker onEmojiClick={onEmojiPick} /> : null}
              <div className="new-chat-preview-input-typing-card">
                <div className="new-chat-preview-input-typing-card-top-sec">
                  <ChatMessageBox
                    value={singleMsg}
                    onChange={(e) => setSingleMsg(e.target.value)}
                    textareaRef={messageField}
                    handleSubmit={handleSubmit}
                    disabled={sendPreviewMessage.buttonDisable}
                  />
                </div>
                <div className="new-chat-preview-input-typing-card-bottom-sec">
                  <div className="new-chat-preview-input-typing-card-left-sec">
                    <Button
                      className="emoji-btn"
                      onClick={() => setShowEmoji(!showEmoji)}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22a10 10 0 1010 10A10.011 10.011 0 0012 2zm5.666 13.746a1 1 0 00-1.33-1.494A7.508 7.508 0 0112 16a7.509 7.509 0 01-4.334-1.746 1 1 0 00-1.332 1.492A9.454 9.454 0 0012 18a9.454 9.454 0 005.666-2.254zM6 10c0 1 .895 1 2 1s2 0 2-1a2 2 0 00-4 0zm8 0c0 1 .895 1 2 1s2 0 2-1a2 2 0 00-4 0z"></path>
                      </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        data-name="Layer 1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zM8 8a2 2 0 012 2c0 1-.895 1-2 1s-2 0-2-1a2 2 0 012-2zm9.666 7.746A9.454 9.454 0 0112 18a9.454 9.454 0 01-5.666-2.254 1 1 0 011.332-1.492A7.509 7.509 0 0012 16a7.508 7.508 0 004.336-1.748 1 1 0 011.33 1.494zM16 11c-1.105 0-2 0-2-1a2 2 0 014 0c0 1-.895 1-2 1z"></path>
                      </svg>
                    </Button>
                    {/* <div className="upload-btn-wrapper">
                    <div className="new-chat-attached-file">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22.95 9.6a1 1 0 00-1.414 0L10.644 20.539a5 5 0 11-7.072-7.071L14.121 2.876a3 3 0 014.243 4.242L7.815 17.71a1.022 1.022 0 01-1.414 0 1 1 0 010-1.414l9.392-9.435a1 1 0 00-1.414-1.414l-9.392 9.435a3 3 0 000 4.243 3.073 3.073 0 004.243 0L19.778 8.532a5 5 0 00-7.071-7.07L2.158 12.054a7 7 0 009.9 9.9L22.95 11.018a1 1 0 000-1.418z"></path>
                      </svg>
                      <input type="file" name="myfile" />
                    </div>
                  </div> */}
                  </div>
                  <div className="new-chat-preview-input-typing-card-end-sec">
                    <Button
                      type="submit"
                      className="send-btn"
                      disabled={sendPreviewMessage.buttonDisable}
                    >
                      {sendPreviewMessage.buttonDisable ? "Loading" : "Send"}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          {((!previewChatMessages.loading && previewChatMessages.data.preview_chat_messages &&
            previewChatMessages.data.preview_chat_messages.length > 0 ) || (Object.keys(previewChats.data).length > 0 &&
            previewChats.data.messages && previewChats.data.messages.length > 0)) && <div className="scroll-down-button" onClick={handleScrollDown}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#03363d"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M12.836.029C9.367-.206 5.961 1.065 3.509 3.521S-.211 9.378.03 12.854C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.861 19.097.454 12.836.029zm3.871 14.56l-2.535 2.535a2.992 2.992 0 01-2.122.877H12a2.982 2.982 0 01-2.071-.877l-2.636-2.636a.999.999 0 111.414-1.414L11 15.367V7a1 1 0 012 0v8.467l2.293-2.293a.999.999 0 111.414 1.414z"></path>
            </svg>
          </div>}
        </div>
      </>
      {/* )} */}
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
          faq={faq}
        />
      )}
    </>
  ) : (
    <ChatPreviewLoader />
  );
};

const ChatMessageBox = ({ value, onChange, textareaRef, handleSubmit, disabled }) => {
  // const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current.style.height = "0px";
    const scrollHeight = textareaRef.current.scrollHeight;
    console.log(scrollHeight);
    textareaRef.current.style.height =
      scrollHeight < 76
        ? "76px"
        : scrollHeight > 192
        ? "192px"
        : scrollHeight + "px";
  }, [value]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      className="form-control w-full border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:z-10 hover:border-slate-400 chat-msg"
      placeholder="Write your message"
      type="text"
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    />
  );
};

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  text = text.replaceAll("\n", " \n");
  text = text.replaceAll("\n", "<br />");
  return text.replaceAll(urlRegex, function (url) {
    return (
      '<a className="text-info underline" href="' +
      url +
      '" target="_blank">' +
      url +
      "</a>"
    );
  });
};

export default ChatPreview;

