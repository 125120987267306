import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  faqDeleteFailure,
  faqDeleteSuccess,
  faqImportFailure,
  faqImportSuccess,
  faqLikeUnlikeList,
  faqListFailure,
  faqListStart,
  faqListSuccess,
  faqSaveFailure,
  faqSaveSuccess,
  faqTrainFailure,
  faqTrainSuccess
} from "../slices/FaqSlice";
import { customTrainingListStart, fineTuneListSuccess } from "../slices/FineTuneSlice";
import { previewChatMessagesSuccess } from "../slices/ChatSlice";
import { customTrainingListSuccess } from "../slices/FineTuneSlice";


function* faqListAPI(action) {
  const faqListData = yield select(state => state.faq.faqList.data);
  yield getCommonSaga({
    apiUrl: "chat_message_faq",
    payload: action.payload,
    successCallback: true,
    successNotify: false,
    success: data => {
      if (Object.keys(faqListData).length > 0)
      {
        store.dispatch(faqListSuccess({
          ...faqListData,
          chat_message_faqs: [...faqListData.chat_message_faqs, ...data.chat_message_faqs],
          total_chat_message_faqs: data.total_chat_message_faqs,
        }));
      }
      else
      store.dispatch(faqListSuccess(data));
    },
    failure: faqListFailure,
  });
}

function* faqSaveAPI(action) {
  const faqListData = yield select(state => state.faq.faqList.data);
  const fineTuneListData = yield select(state => state.fineTune.fineTuneList.data);
  // let previewChatsMessagesData = yield select(state => state.chat.previewChatMessages.data);
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "chat_message_faq/store",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(faqSaveSuccess(data));
      store.dispatch(faqLikeUnlikeList(data));
      if(action.payload.status != 2)
      {
        if (Object.keys(faqListData).length > 0) {
          const item = faqListData.chat_message_faqs ? faqListData.chat_message_faqs.find(faq => faq.chat_message_faq_id === data.chat_message_faq.chat_message_faq_id) : "";
          store.dispatch(faqListSuccess({
            ...faqListData,
            chat_message_faqs: item ? faqListData.chat_message_faqs.map(faq => faq.chat_message_faq_id === data.chat_message_faq.chat_message_faq_id ? data.chat_message_faq : faq)
              : [data.chat_message_faq, ...faqListData.chat_message_faqs],
            total_chat_message_faqs: faqListData.total_chat_message_faqs + 1,
            total_faqs : action.payload.chat_message_faq_id ? faqListData.total_faqs : faqListData.total_faqs + 1,
          }));
        }
        if(!action.payload.chat_message_faq_id)
        {
          if (Object.keys(fineTuneListData).length > 0) {
            store.dispatch(fineTuneListSuccess({
              ...fineTuneListData,
              total_faq_count: fineTuneListData.total_faq_count + 1,
            }));
          }
        }
        if (Object.keys(customTrainingListData).length > 0) {
          const item = customTrainingListData.custom_trainings
            ? customTrainingListData.custom_trainings.find(
                (tune) => tune.chat_message_faq_id === data.chat_message_faq.chat_message_faq_id
              )
            : "";
          store.dispatch(
            customTrainingListSuccess({
              ...customTrainingListData,
              custom_trainings: item
                ? customTrainingListData.custom_trainings.map((tune) =>
                    tune.chat_message_faq_id === data.chat_message_faq.chat_message_faq_id
                      ? data.chat_message_faq
                      : tune
                  )
                : [
                    data.chat_message_faq,
                    ...customTrainingListData.custom_trainings,
                  ],
              total_custom_training_count: action.payload.chat_message_faq_id ? customTrainingListData.total_custom_training_count :
                customTrainingListData.total_custom_training_count + 1,
            })
          );
        }
      }
    },
    failure: faqSaveFailure,
  });
}

function* faqDeleteAPI(action) {
  const faqListData = yield select(state => state.faq.faqList.data);
  const fineTuneListData = yield select(state => state.fineTune.fineTuneList.data);
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "chat_message_faq/destroy",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(faqDeleteSuccess(data));
      if (Object.keys(faqListData).length > 0) {
        store.dispatch(faqListSuccess({
        ...faqListData,
        chat_message_faqs: faqListData.chat_message_faqs.filter(faq => faq.chat_message_faq_id !== action.payload.chat_message_faq_id),
        total_chat_message_faqs: faqListData.total_chat_message_faqs - 1,
        total_faqs : data.total_faqs,
      }));
      }
      store.dispatch(fineTuneListSuccess({
        ...fineTuneListData,
        total_faq_count: fineTuneListData.total_faq_count - 1,
      }));
      if (Object.keys(customTrainingListData).length > 0) {
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            custom_trainings: customTrainingListData.custom_trainings.filter((tune) =>
                  tune.chat_message_faq_id !== action.payload.chat_message_faq_id),
            total_custom_training_count:
              customTrainingListData.total_custom_training_count - 1,
          })
        );
      }
    },
    failure: faqDeleteFailure,
  })
}

function* faqImportAPI(action) {
  const fineTuneListData = yield select(state => state.fineTune.fineTuneList.data);
  const customTrainingListData = yield select(
    (state) => state.fineTune.customTrainingList.data
  );
  yield getCommonSaga({
    apiUrl: "chat_message_faq/import_faqs",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(faqImportSuccess(data));
      store.dispatch(fineTuneListSuccess({
        ...fineTuneListData,
        total_faq_count: fineTuneListData.total_faq_count + 1,
      }));
      store.dispatch(faqListStart({ bot_id: action.payload.bot_id, skip: 0, take: 12 }));
      if (Object.keys(customTrainingListData).length > 0) {
        store.dispatch(
          customTrainingListSuccess({
            ...customTrainingListData,
            total_custom_training_count:
              customTrainingListData.total_custom_training_count + 1,
          })
        );
      }
      store.dispatch(customTrainingListStart({bot_id: action.payload.bot_id,skip: 0,take: 12}))
    },
    failure: faqImportFailure,
  })
}

function* faqTrainAPI(action) {
  const faqListData = yield select(state => state.faq.faqList.data);
  yield getCommonSaga({
    apiUrl: "chat_message_faq/train",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(faqTrainSuccess(data));
      store.dispatch(faqListSuccess({
        ...faqListData,
        chat_message_faqs: faqListData.chat_message_faqs.map(faq => faq.training_status === 0 ? {...faq, training_status: 1, training_status_formatted: "On Progress" } : faq),
        total_chat_message_faqs: faqListData.total_chat_message_faqs,
      }));
    },
    failure: faqTrainFailure,
  })
}

export default function* FaqSaga() {
  yield all([
    yield takeLatest('faq/faqListStart', faqListAPI),
    yield takeLatest('faq/moreFaqListStart', faqListAPI),
    yield takeLatest('faq/faqSaveStart', faqSaveAPI),
    yield takeLatest('faq/faqDeleteStart', faqDeleteAPI),
    yield takeLatest('faq/faqImportStart', faqImportAPI),
    yield takeLatest('faq/faqTrainStart', faqTrainAPI),
  ]);
}

