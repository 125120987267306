import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { forgotPasswordStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OnBoardingVerificationModal from "./OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo";
import AuthenticationRightSection from "./AuthenticationRightSection";

const AIasForgotPassword = (props) => {
  const t = useTranslation("forgot_password");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  const handleForgotPassword = (values) => {
    dispatch(forgotPasswordStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !forgotPassword.loading &&
      Object.keys(forgotPassword.data).length > 0 &&
      Object.keys(forgotPassword.data.user).length > 0
    ) {
        setShowVerify(forgotPassword.data.user);
    }
    setSkipRender(false);
  }, [forgotPassword]);


  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>
                      {t("message")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={forgotPasswordSchema}
              onSubmit={handleForgotPassword}
            >
              {({ setFieldValue, values }) => (
                <FORM noValidate className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("email.placeholder")}
                            name="email"
                          />
                          <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className="onboarding-footer-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="ai-as-service-btn-sec">
                          <Button
                            className="onboarding-btn"
                            type="submit"
                            disabled={forgotPassword.buttonDisable}
                          >
                            {forgotPassword.buttonDisable
                              ? t("submit_btn.loading")
                              : t("submit_btn.text")}
                          </Button>
                        </div>
                        <div className="onbaording-link-sec">
                          {t("have_account")}
                          <Link to="/login">{t("login")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
        <AuthenticationRightSection/>
      </div>
      {showVerify ?
        <OnBoardingVerificationModal forgotPassword={1} showVerify={showVerify} closeVerify={() => setShowVerify(false)} />
        : null}
    </>
  );
};

export default AIasForgotPassword;
