import React, { useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
// import "./NewUI.css";
import { Link, useLocation } from "react-router-dom";
import ReactJoyride from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { updateTourStart } from "../../../store/slices/AdminSlice";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";

const Sidebar = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);

  const Step1 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/dashboard-tour-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>View your current subscription plan details</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>See chat statistics for today and overall history.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Check the number of bots available to you.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>View custom tuning options and chat messages balance.</span>
          </li>
        </ul>
      </div>
    )
  }
  const Step2 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/your-bot-tour-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Browse all the bots you've created.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Quickly access and manage each individual bot.</span>
          </li>
        </ul>
      </div>
    )
  }

  const Step3 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/customize-tour-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Explore and modify bot settings.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Fine-tune/Train your bot's responses with new Documents, Web Scraping, or FAQ.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Access the bot's inbox, source, and analytics options.</span>
          </li>
        </ul>
      </div>
    )
  }

  const Step4 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/all-message-tour-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>View a comprehensive list of all received chats.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Monitor and review interactions of users with your bots.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Use the chat option to disable bot response and input your response as a human.</span>
          </li>
        </ul>
      </div>
    )
  }

  const Step5 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/subscription-tour-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Review your active subscription plan details.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Explore and compare other available subscription plans.</span>
          </li>
        </ul>
      </div>
    )
  }

  const
    steps = [
      {
        target: '.my-first-step',
        title: 'Dashboard',
        content: <Step1 />,
        disableBeacon: true,
      },
      {
        target: '.my-second-step',
        title: 'Your Bots',
        content: <Step2 />,
        disableBeacon: true,
      },
      {
        target: '.my-third-step',
        title: 'Customize',
        content: <Step3 />,
        disableBeacon: true,
      },
      {
        target: '.my-fourth-step',
        title: 'All Chats',
        content: <Step4 />,
        disableBeacon: true,
      },
      {
        target: '.my-fifth-step',
        title: 'Subscription',
        content: <Step5 />,
        disableBeacon: true,
      },
    ]

  const handleJoyrideCallback = (data) => {
    if (['next', 'skip'].includes(data.action)) {
      if(
        ((['skip'].includes(data.action) && data.lifecycle == 'complete') || (['next'].includes(data.action) && data.lifecycle == 'init')) 
        && !profile.loading && Object.keys(profile.data).length > 0 && !profile.data.dahsboard_tour) {
          dispatch(updateTourStart({
            name: profile.data.name,
            email: profile.data.email,
            dashboard_tour: 1,
          }))
      }
    }
  };

  return (
    <>
      <div className={`admin-ui-sidebar`}>
        <div className="admin-ui-sidebar-collapse">
          <div className="admin-ui-collapse-logo-sec">
            <Link className="" to="/">
              <Image
                className="admin-ui-collapse-logo"
                src={
                  window.location.origin + "/images/haive-logo.svg"
                }
              />
            </Link>
          </div>
          {(!['/settings', '/inbox', '/upcoming'].includes(location.pathname) && !profile.loading && Object.keys(profile.data).length > 0 && !profile.data.dashboard_tour) ? <ReactJoyride
            floaterProps={{ placement: "right" }}
            steps={steps}
            run={true}
            continuous={true}
            spotlightClicks={true}
            scrollToFirstStep={true}
            disableScrolling={true}
            showSkipButton
            showProgress={true}
            disableOverlayClose={true}
            callback={handleJoyrideCallback}
            hideCloseButton={true}
            autoStart
            className="joyride-dashboard"
            styles={{
              options: {
                borderRadius: '0',
                arrowColor: '#fff',
                backgroundColor: '#fff',
                overlayColor: '#312f2f',
                primaryColor: '#000',
                textColor: '#004a14',
                width: 550,
                zIndex: 99999,
              },
              buttonNext: {
                fontSize: "0.9em",
                padding: "1em 1em",
                display: "inline-block",
                backgroundColor: "#048a89",
                border: '0',
                letterSpacing: ".5px",
                borderRadius: "0!important",
                fontWeight: "500",
                marginLeft: "16px",
                outline: 'none!important',
                position: 'relative',
              },
              overlay: {
                backgroundColor: 'none',
                mixBlendMode: 'unset',
              },
              spotlight: {
                backgroundColor: '#FFFFFF66',
                borderRadius: 0,
                boxShadow: '0px 0px 0px 9999px rgba(0,0,0,0.7)',
                marginTop: '10px', marginLeft: '0px', maxHeight: '59px', maxWidth: '4.8em',
              },
            }}
            {
            ...props
            }>
            Test
          </ReactJoyride> : null}
          <div className="admin-ui-collapse-body">
            <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <Link to="/" className={`my-first-step ${location.pathname === "/" ? "active" : ""}`}>
                  {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7 0H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM20 0h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4V4a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2V4a2 2 0 012-2h3a2 2 0 012 2zM7 13H4a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2H4a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2zM20 13h-3a4 4 0 00-4 4v3a4 4 0 004 4h3a4 4 0 004-4v-3a4 4 0 00-4-4zm2 7a2 2 0 01-2 2h-3a2 2 0 01-2-2v-3a2 2 0 012-2h3a2 2 0 012 2z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 21 20"
                  >
                    <path
                      d="M1.243.735A2.493 2.493 0 013.017.013C4.059.009 5.079.009 6.047.009h1.54c1.324.004 2.422 1.101 2.445 2.453a127.42 127.42 0 010 4.578c-.027 1.402-1.11 2.473-2.52 2.488-.761.012-1.527.016-2.273.016C3.075 9.564.7 9.837.517 6.966c.007-.84 0-1.668 0-2.504.05-1.25-.29-2.777.726-3.727zM10.036 14.829c0 .828-.004 1.687.004 2.531.039 1.414-1.121 2.656-2.535 2.633-1.172-.027-2.438.004-3.625-.004-.934.043-1.973-.008-2.637-.73-.644-.598-.785-1.54-.73-2.399-.004-1.285-.004-2.613.008-3.922a2.465 2.465 0 011.835-2.375c1.008-.203 1.95-.062 3.082-.105.653 0 1.301 0 1.953.004 2.7.14 2.73 2.215 2.645 4.367zM19.098 11.864c2.98 2.945.832 8.156-3.367 8.129-4.172.023-6.375-5.18-3.367-8.121 1.777-1.856 4.957-1.875 6.734-.008zM10.97 6.99c-.02-1.59-.02-3.056 0-4.473A2.502 2.502 0 0113.462.009h.031a294.5 294.5 0 014.55 0c1.294-.02 2.458 1.156 2.442 2.449.008.871 0 1.726 0 2.59-.043 1.25.27 2.8-.73 3.746-1.02 1.066-2.711.664-4.031.746-.75 0-1.5-.004-2.227-.016a2.518 2.518 0 01-2.527-2.511v-.024z"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/bots" className={`my-second-step ${location.pathname === "/bots" ? "active" : ""}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 21 24"
                  >
                    <path
                      d="M18.601 23.222h-15.2c-.525 0-.95-.454-.95-1.013 0-3.355 2.556-6.082 5.7-6.082h5.7c3.143 0 5.7 2.727 5.7 6.082 0 .56-.425 1.013-.95 1.013zm.475-17.14h-.475c0-2.236-1.704-4.055-3.8-4.055h-2.85V1.014c0-.56-.425-1.014-.95-1.014-.525 0-.95.454-.95 1.014v1.013h-2.85c-2.096 0-3.8 1.819-3.8 4.055h-.475c-.786 0-1.425.682-1.425 1.52V9.63c0 .838.64 1.52 1.425 1.52h.475c0 2.236 1.704 4.055 3.8 4.055h7.6c2.096 0 3.8-1.819 3.8-4.055h.475c.786 0 1.425-.682 1.425-1.52V7.603c0-.839-.64-1.521-1.425-1.521zm-9.54 3.085a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.672.297.918.918 0 01-.671-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.672.297.918.918 0 01-.672-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                    ></path>
                  </svg>
                </Link>
              </li>
              {/* 
              <li>
                <Link to="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                    </svg>
                </Link>
              </li> */}
              {/* <li>
                <Link to="/billings" className={`${location.pathname === "/billings" ? "active" : ""}`} >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                    <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                    <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                  </svg>
                </Link>
              </li> */}
              <li>
                <Link to="/settings" className={`my-third-step ${location.pathname === "/settings" ? "active" : ""}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                    <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 22 22"
                  >
                    <path
                      d="M19.1 11c0-.253-.01-.495-.032-.748l2.008-1.551c.432-.33.551-.946.281-1.43l-2.02-3.553a1.07 1.07 0 00-.587-.494 1.048 1.048 0 00-.762.032l-2.322 1.001c-.4-.286-.82-.539-1.263-.748L14.089.968A1.09 1.09 0 0013.02 0H8.992c-.55 0-1.015.418-1.08.968L7.6 3.509a8.158 8.158 0 00-1.263.748L4.014 3.256a1.056 1.056 0 00-1.35.462L.644 7.282a1.123 1.123 0 00.282 1.43l2.008 1.551a8.226 8.226 0 000 1.485L.926 13.299a1.12 1.12 0 00-.281 1.43l2.02 3.553c.27.484.852.682 1.35.462l2.32-1.001c.4.286.822.539 1.264.748l.314 2.541c.064.55.529.968 1.069.968h4.028a1.09 1.09 0 001.069-.968l.313-2.541a8.158 8.158 0 001.263-.748l2.322 1.001c.497.22 1.08.022 1.35-.462l2.02-3.553a1.123 1.123 0 00-.282-1.43l-2.008-1.551c.032-.253.043-.495.043-.748zm-8.056 3.85c-2.084 0-3.78-1.727-3.78-3.85s1.696-3.85 3.78-3.85c2.084 0 3.78 1.727 3.78 3.85s-1.696 3.85-3.78 3.85z"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/inbox" className={`my-fourth-step ${location.pathname === "/inbox" ? "active" : ""}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                    <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 21 20"
                  >
                    <path
                      d="M3.427 2.93a9.998 9.998 0 0114.143 0c3.143 3.141 3.737 7.812 1.879 11.532l1.03 4.529a.822.822 0 01-.983.982l-4.531-1.03C8.377 22.232.498 17.473.498 9.999c0-2.67 1.04-5.18 2.93-7.068zm2.9 9.557h4.353a.821.821 0 100-1.642H6.326a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.326a.821.821 0 100 1.642z"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/subscription" className={`my-fifth-step ${["/subscription", "/billings"].includes(location.pathname) ? "active" : ""}`}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM22 19c0 1.654-1.346 3-3 3h-5.917c-6.034 0-10.686-3.905-11.058-9.285a10.027 10.027 0 012.898-7.782 10.019 10.019 0 017.778-2.91C17.915 2.377 22 6.909 22 12.339v6.66zm-6-5c0 1.654-1.346 3-3 3v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001z"></path>
                  </svg> */}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.029a12.043 12.043 0 00-9.327 3.492 12.027 12.027 0 00-3.48 9.333C.47 19.208 6.082 24 13.083 24H19c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.097.454 12.836.029zM13 17v1a1 1 0 11-2 0v-1h-.268a3.01 3.01 0 01-2.598-1.499 1 1 0 111.73-1.001c.179.308.511.5.867.5h2.268c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 017.999 10c0-1.654 1.346-3 3-3V6a1 1 0 112 0v1h.268c1.067 0 2.063.575 2.598 1.5a1 1 0 11-1.73 1.001 1.004 1.004 0 00-.867-.5H11c-.552 0-1 .449-1 1 0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14.001c0 1.654-1.346 3-3 3z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                    viewBox="0 0 21 21"
                  >
                    <path
                      fillRule="evenodd"
                      d="M21 10.502c0 5.799-4.701 10.5-10.5 10.5S0 16.301 0 10.502 4.701.002 10.5.002 21 4.703 21 10.502zm-11.487 2.22H10c1.907 0 3.48-1.344 3.653-3.055h.68c.367 0 .667-.275.667-.611 0-.337-.3-.612-.667-.612h-.806a3.267 3.267 0 00-.7-1.222h1.506c.367 0 .667-.275.667-.61 0-.337-.3-.612-.667-.612H7.667C7.3 6 7 6.275 7 6.611c0 .336.3.611.667.611H10c.933 0 1.733.501 2.107 1.222h-4.44C7.3 8.444 7 8.72 7 9.056c0 .336.3.61.667.61h4.64c-.16 1.04-1.134 1.834-2.307 1.834H8.62c-.527 0-.953.391-.953.874 0 .22.093.44.266.599l4.02 3.838c.127.122.3.189.48.189.587 0 .887-.642.48-1.033l-3.4-3.245z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/upcoming/whatsapp" className={`${location.pathname === "/upcoming/whatsapp" ? "active" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    enableBackground="new 0 0 512 512"
                    viewBox="0 0 512 512"
                  >
                    <path
                      // fill="#92a8ab"
                      d="M256.064 0h-.128C114.784 0 0 114.816 0 256c0 56 18.048 107.904 48.736 150.048l-31.904 95.104 98.4-31.456C155.712 496.512 204 512 256.064 512 397.216 512 512 397.152 512 256S397.216 0 256.064 0zm148.96 361.504c-6.176 17.44-30.688 31.904-50.24 36.128-13.376 2.848-30.848 5.12-89.664-19.264-75.232-31.168-123.68-107.616-127.456-112.576-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624 26.176-62.304c6.176-6.304 16.384-9.184 26.176-9.184 3.168 0 6.016.16 8.576.288 7.52.32 11.296.768 16.256 12.64 6.176 14.88 21.216 51.616 23.008 55.392 1.824 3.776 3.648 8.896 1.088 13.856-2.4 5.12-4.512 7.392-8.288 11.744-3.776 4.352-7.36 7.68-11.136 12.352-3.456 4.064-7.36 8.416-3.008 15.936 4.352 7.36 19.392 31.904 41.536 51.616 28.576 25.44 51.744 33.568 60.032 37.024 6.176 2.56 13.536 1.952 18.048-2.848 5.728-6.176 12.8-16.416 20-26.496 5.12-7.232 11.584-8.128 18.368-5.568 6.912 2.4 43.488 20.48 51.008 24.224 7.52 3.776 12.48 5.568 14.304 8.736 1.792 3.168 1.792 18.048-4.384 35.52z"
                      data-original="#000000"
                    ></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/upcoming/imessage" className={`${location.pathname === "/upcoming/imessage" ? "active" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.029C9.362-.206 5.961 1.065 3.508 3.521S-.211 9.378.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5v-6.66C24 5.862 19.096.454 12.836.029zM7 13.5a1.5 1.5 0 11.001-3.001A1.5 1.5 0 017 13.5zm5 0a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0112 13.5zm5 0a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0117 13.5z"></path>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to="/upcoming/sms" className={`${location.pathname === "/upcoming/sms" ? "active" : ""}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12.836.028a12.038 12.038 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5v-6.66C24 5.861 19.096.454 12.836.028zM7.878 13.482c-.16.899-1.019 1.552-2.04 1.552a2.938 2.938 0 01-2.124-.92.616.616 0 01-.164-.414c0-.345.28-.625.625-.625.183 0 .343.081.458.206.286.315.738.503 1.206.503.448 0 .764-.265.81-.521.062-.345-.451-.562-.61-.621-.752-.277-1.43-.58-1.43-.58-.6-.382-.864-.969-.777-1.579.094-.649.568-1.178 1.239-1.38.236-.071.464-.101.68-.101.993-.003 1.729.614 1.766.645a.616.616 0 01.239.48c0 .345-.28.625-.625.625a.614.614 0 01-.405-.158c-.021-.017-.437-.332-.946-.34a1.163 1.163 0 00-.349.047c-.293.088-.351.282-.362.361a.302.302 0 00.13.302c.016 0 .614.263 1.272.506 1.223.451 1.529 1.336 1.408 2.014zm7.122.893a.625.625 0 01-1.25 0h-.003v-3.267l-1.281 1.388a.634.634 0 01-.932 0l-1.282-1.389-.002 3.269a.625.625 0 01-1.25 0v-4.75c0-.345.28-.625.625-.625.445 0 .772.165 1.023.452L12 11.001l1.333-1.526a1.388 1.388 0 011.042-.474c.345 0 .625.28.625.625l-.003 4.734.003.016zm5.436-.893c-.16.899-1.019 1.552-2.04 1.552a2.938 2.938 0 01-2.124-.92.616.616 0 01-.164-.414c0-.345.28-.625.625-.625.183 0 .343.081.458.206.286.315.738.503 1.206.503.448 0 .764-.265.81-.521.062-.345-.451-.562-.61-.621-.752-.277-1.43-.58-1.43-.58-.6-.382-.864-.969-.777-1.579.094-.649.568-1.178 1.239-1.38.236-.071.464-.101.68-.101.993-.003 1.729.614 1.766.645a.616.616 0 01.239.48c0 .345-.28.625-.625.625a.614.614 0 01-.405-.158c-.021-.017-.437-.332-.946-.34a1.163 1.163 0 00-.349.047c-.293.088-.351.282-.362.361a.302.302 0 00.13.302c.016 0 .614.263 1.272.506 1.223.451 1.529 1.336 1.408 2.014z"></path>
                  </svg>
                </Link>
              </li>

            </ul>
            {/* <ul className="admin-ui-sidebar-list list-unstyled">
              <li>
                <a href="https://haive.tech/" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M11.904 16a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0111.904 16zm1-2c0-.561.408-1.225.928-1.512a4.021 4.021 0 002.009-4.223c-.283-1.613-1.607-2.938-3.221-3.221a4.034 4.034 0 00-3.289.874 3.995 3.995 0 00-1.428 3.063 1 1 0 102 0 2.002 2.002 0 012.371-1.967c.787.138 1.458.81 1.596 1.596a1.995 1.995 0 01-1.004 2.125c-1.156.637-1.963 1.979-1.963 3.264a1 1 0 102 0zM24 19v-6.66C24 5.861 19.096.454 12.836.028a12.047 12.047 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5zM12.701 2.024C17.916 2.378 22 6.909 22 12.339v6.66c0 1.654-1.346 3-3 3h-5.917c-6.035 0-10.686-3.904-11.059-9.284a10.02 10.02 0 012.899-7.781A10.016 10.016 0 0112.7 2.023z"></path>
                  </svg>
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
