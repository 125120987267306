import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { registerStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OtpInput from "react18-input-otp";
import { emailVerifyStart, resendOtpStart } from "../../store/slices/AdminSlice";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import OnBoardingVerificationModal from "./OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo"
import AuthenticationRightSection from "./AuthenticationRightSection";

const AIasRegisterIndex = (props) => {
  const t = useTranslation("register");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const register = useSelector((state) => state.admin.register);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  // const [agreeTerms, setAgreeTerms] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const resendOtp = useSelector(state => state.admin.resendOtp);
  const [otp, setOTP] = useState("");
  const handleChange = (otp) => setOTP(otp);

  const registerSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required"))
      .matches(/^[^<>]*$/, t('name.invalid'))
      .trim().notOneOf(['']),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=\S*$)/, t("password.white_space"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
    password_confirmation: Yup.string()
      .required(t("password_confirmation.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("password_confirmation.invalid")
        ),
      }),
  });

  const handleRegister = (values) => {
    dispatch(registerStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !register.loading &&
      Object.keys(register.data).length > 0
    ) {
      if (register.data.email_status === 0) setShowVerify(register.data);
      else props.setStep(2);
    }
    setSkipRender(false);
  }, [register]);

  const handleSubmit = e => {
    e.preventDefault();
    if (otp)
      if (otp.length === 6)
        dispatch(emailVerifyStart({
          email: register.data.email,
          remember: register.data.remember,
          verification_code: otp,
        }));
      else getErrorNotificationMessage("The verification code should be 6 digits.")
    else getErrorNotificationMessage("The verification code field is required.");
  }

  const handleresendOtp = () => {
    dispatch(resendOtpStart({
      email: register.data.email,
      code_type: "email"
    }));
  }

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/onboarding-steps')
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
          {/* {!showVerify ? (
            <> */}
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>
                      {t("message")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                remember: false,
              }}
              validationSchema={registerSchema}
              onSubmit={handleRegister}
            >
              {({ setFieldValue, values }) => (
                <FORM noValidate className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        {/* <Form className="onboarding-form"> */}
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("name.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder={t("name.placeholder")}
                            name="name"
                          />
                          <ErrorMessage
                          component={"div"}
                          name="name"
                          className="errorMsg"
                        />
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("email.placeholder")}
                            name="email"
                          />
                          <ErrorMessage
                          component={"div"}
                          name="email"
                          className="errorMsg"
                        />
                        </Form.Group>
                        <Form.Label>
                        {t("password.label")} <span>*</span>
                        </Form.Label>
                        <div className="mb-3">
                          <InputGroup className="register-input-group">
                            <Field
                              type={showPassword.password ? "text" : "password"}
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              name="password"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                            >
                              <Image
                                className="register-input-group-eye-icon"
                                src={ showPassword.password ? 
                                  window.location.origin +
                                  "/images/onboarding/eye-visible-off-icon.svg"
                                  : window.location.origin +
                                  "/images/onboarding/eye-visible-on-icon.svg"
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </div>
                        <Form.Label>
                        {t("password_confirmation.label")} <span>*</span>
                        </Form.Label>
                        <div className="mb-3">
                          <InputGroup className="register-input-group">
                            <Field
                              type={showPassword.confirm ? "text" : "password"}
                              className="form-control"
                              placeholder={t("password_confirmation.placeholder")}
                              name="password_confirmation"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confirm: !showPassword.confirm,
                                })
                              }
                            >
                              <Image
                                className="register-input-group-eye-icon"
                                src={showPassword.confirm ? 
                                  window.location.origin +
                                  "/images/onboarding/eye-visible-off-icon.svg"
                                  : window.location.origin +
                                  "/images/onboarding/eye-visible-on-icon.svg"
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password_confirmation"
                            className="errorMsg"
                          />
                        </div>
                        <Form.Group
                          className="mb-3 flex-data"
                          id="formGridCheckbox"
                        >
                          {/* <Form.Check
                            type="checkbox"
                            label="I agree to all the"
                            onChange={() => setAgreeTerms(!agreeTerms)}
                          /> */}
                          {/* I agree to all the <Link to="#">Terms & Conditions*</Link> */}
                          {t("agreement")} <Link target="_blank" to="/page/terms">{t("terms")}</Link> {t("and")} <Link target="_blank" to="/page/privacy">{t("privacy")}</Link>
                        </Form.Group>
                        {/* </Form> */}
                      </Col>
                    </Row>
                  </div>
                  <div className="onboarding-footer-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="ai-as-service-btn-sec">
                          <Button
                            className="onboarding-btn"
                            type="submit"
                            disabled={register.buttonDisable}
                          >
                            {register.buttonDisable
                              ? "Loading"
                              : "Create Account"}
                          </Button>
                        </div>
                        <div className="onbaording-link-sec">
                          {t("have_account")}
                          <Link to="/login">{t("sign_in")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FORM>
              )}
            </Formik>
            {/* </> ) : (
              <>
              <div className="flex justify-between rounded-t-lg bg-slate-200 px-4 py-3 dark:bg-navy-800 sm:px-5">
                <h3 className="text-base font-medium text-slate-700 dark:text-navy-100">
                  Email Verification
                </h3>
                <button
                  className="btn -mr-1.5 h-7 w-7 rounded-full p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4.5 w-4.5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
              <div className="scrollbar-sm overflow-y-auto px-4 py-4 sm:px-5">
                <div className="verification-sec">
                  <div className="verification-box card">
                    <div className="verification-logo-sec">
                      <img
                        className="verification-logo"
                        src={
                          window.location.origin + "/images/verify-code-img.svg"}
                      />
                    </div>
                    <h4>
                      Please enter the one time password to Verify your account
                    </h4>
                    <form onSubmit={handleSubmit}>
                      <div className="verification-item">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          separator={<span>-</span>}
                          containerStyle="justify-content-center"
                        />
                      </div>
                      <div className="verification-btn-sec">
                        <button
                          type="button"
                          className="btn min-w-[7rem] rounded-full border border-slate-300 font-medium text-slate-800 hover:bg-slate-150 focus:bg-slate-150 active:bg-slate-150/80 dark:border-navy-450 dark:text-navy-50 dark:hover:bg-navy-500 dark:focus:bg-navy-500 dark:active:bg-navy-500/90"
                          onClick={() => handleresendOtp()}
                          disabled={resendOtp.buttonDisable}
                        >
                          {resendOtp.buttonDisable ? "Loading" : "Resend"}
                        </button>
                        <button
                          type="submit"
                          className="btn min-w-[7rem] rounded-full bg-primary font-medium text-white hover:bg-primary-focus focus:bg-primary-focus active:bg-primary-focus/90 dark:bg-accent dark:hover:bg-accent-focus dark:focus:bg-accent-focus dark:active:bg-accent/90"
                          disabled={emailVerify.buttonDisable}
                        >
                          {emailVerify.buttonDisable ? "Loading" : "Validate"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              </>
            )} */}
          </div>
        </div>
        <AuthenticationRightSection/>
      </div>
      {showVerify ?
        <OnBoardingVerificationModal showVerify={showVerify} closeVerify={() => setShowVerify(false)} />
        : null}
    </>
  );
};

export default AIasRegisterIndex;
