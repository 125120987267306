import React from "react";
import { Row, Col } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const ChatLoader = () => {
  return (
    <div className="admin-ui-body-sec chat-loader" style={{ height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col md={8} className="text-center">
          <div className="pills-loader mb-3">
            {[...Array(5)].map((i, key) => (
              <Skeleton height={50} key={key} width="100%" />
            ))}
          </div>
          <div className="mb-3" style={{display:"flex", justifyContent:'space-between'}}>
            <Skeleton height={50} width={150} className="mb-3" /> 
            <Skeleton height={50} width={400} className="mb-3" /> 
          </div>
          <Skeleton height={100} className="mb-3" count={5} />
        </Col>
        <Col xs={4} className="text-center">
          <Skeleton height="100%" width="100%" />
        </Col>
      </Row>
    </div>
  );
};

export default ChatLoader;
