import React, { useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Table,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
// import "../Layout/NewHeader/NewUI.css";
import { Link, useLocation } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import useDesign from "../../hooks/useDesign";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch } from "react-redux";
import { selecteInboxBot } from "../../store/slices/ChatSlice";

const DashBoardRecentChatIndex = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("dashboard")
  const { getChatTypeName } = useDesign();

  return (
    <>
      <div className="new-dashboard-recent-chat-list-sec">
        <div className="new-dashboard-recent-chat-list-header-sec">
          <h3>{t("recent_chats")}</h3>
          {props.chats.length > 0 ? (
          <Link to="/inbox" onClick={()=> dispatch(selecteInboxBot({
            label: "All Chats",
            value: "",
          }))} className="view-all-link">
            View All
          </Link>
          ) : null}
        </div>
        <div className="new-dashboard-recent-chat-list-body-sec">
          <div className="new-dashboard-recent-chat-list-table-sec">
            {props.chats.length > 0 ? (
              <Table responsive className="table-hover">
                <thead>
                  <tr>
                    <th>SI.No</th>
                    <th>Ref ID</th>
                    <th>Date</th>
                    <th>Chat Type</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.chats.map((chat, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <div className="new-dashboard-recent-chat-list-refer-id-sec">
                          <h4>{chat.chat_unique_id}</h4>
                          <ul className="new-dashboard-recent-chat-list-refer-id-list list-unstyled">
                            <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#546594"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg>
                              Chat Messages
                              <span className="count">
                                {chat.chat_messages_count}
                              </span>
                            </li>
                            {/* <li>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#546594"
                                height="20"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 21H3a3 3 0 01-3-3v-3.922l1.096.003c1.1-.029 1.986-.933 1.986-2.04s-.884-2.009-1.982-2.04l-1.1.003V6a3 3 0 013-3h18a3 3 0 013 3v4h-.888c-.997 0-1.922.679-2.084 1.662A2.002 2.002 0 0023 14h1v4a3 3 0 01-3 3zM3 19h18a1 1 0 001-1v-2.126c-1.723-.445-3-2.013-3-3.874s1.277-3.428 3-3.874V6a1 1 0 00-1-1H3a1 1 0 00-1 1v2.115c1.767.432 3.082 2.028 3.082 3.926S3.767 15.535 2 15.967V18a1 1 0 001 1z"></path>
                              </svg>
                              Ticket Created
                              <span className="count">
                                {chat.is_ticket ? "Yes" : "No"}
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </td>
                      <td>{chat.created_at}</td>
                      <td>
                        <div className={getChatTypeName(chat.widget_type)}>
                          {chat.widget_type_formatted}
                        </div>
                      </td>
                      {/* <td className="recent-chat-menu-btn">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <div className="recent-chat-menu-icon-sec">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 512 512"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                              >
                                <circle
                                  cx="458.667"
                                  cy="256"
                                  r="53.333"
                                ></circle>
                                <circle cx="256" cy="256" r="53.333"></circle>
                                <circle
                                  cx="53.333"
                                  cy="256"
                                  r="53.333"
                                ></circle>
                              </svg>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                fill="#03363d"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.908 4.908 0 000 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.908 4.908 0 000-5.162zm-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.918 2.918 0 010-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.918 2.918 0 010 3.068z"></path>
                                <path d="M12 7a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3z"></path>
                              </svg>
                              View
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#546594"
                                height="20"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 21H3a3 3 0 01-3-3v-3.922l1.096.003c1.1-.029 1.986-.933 1.986-2.04s-.884-2.009-1.982-2.04l-1.1.003V6a3 3 0 013-3h18a3 3 0 013 3v4h-.888c-.997 0-1.922.679-2.084 1.662A2.002 2.002 0 0023 14h1v4a3 3 0 01-3 3zM3 19h18a1 1 0 001-1v-2.126c-1.723-.445-3-2.013-3-3.874s1.277-3.428 3-3.874V6a1 1 0 00-1-1H3a1 1 0 00-1 1v2.115c1.767.432 3.082 2.028 3.082 3.926S3.767 15.535 2 15.967V18a1 1 0 001 1z"></path>
                              </svg>
                              Create Ticket
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(DashBoardRecentChatIndex);
