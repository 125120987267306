import React from "react";

const OnboardingStepsContent = ({ step }) => {

  const getStepContent = (step) => {
    switch (step) {
      case 0: return "Select from the leading chat support platforms to enable real-time communication with your visitors. These solutions will provide you with a widget solution which can be configured on your website."
      case 1: return "Enter the bot name, website URL, and About description to create your first bot on the platform. This bot can later be edited or deleted from the dashboard."
      case 2: return "You can now train your bot by either importing a Web Page by providing an URL or you can upload a document containing the required information for your bot."
      case 4: return "Choose a suitable monthly or yearly subscription plan for your business and proceed to make the payment."
      default: return ""
    }
  }

  const getStepTitle = (step) => {
    switch (step) {
      case 0: return "Choose Chat Solution"
      case 1: return "Bot Creation"
      case 2: return "Train Your Bot"
      case 4: return "Subscription Plans"
      default: return ""
    }
  }

  return (
    <div className="onboarding-right-sec">
      <div className="onboarding-installation-content">
        <div className="stepper-wrapper">
          <div className={`stepper-item ${step > 0 ? "completed" : ""}`}>
            <div className="step-counter">1</div>
          </div>
          <div className={`stepper-item ${step > 1 ? "completed" : ""}`}>
            <div className="step-counter">2</div>
          </div>
          <div className={`stepper-item ${step > 2 ? "completed" : ""}`}>
            <div className="step-counter">3</div>
          </div>
          <div className={`stepper-item ${step > 4 ? "completed" : ""}`}>
            <div className="step-counter">4</div>
          </div>
        </div>
        <h3>{getStepTitle(step)}</h3>
        <p>
          {getStepContent(step)}
        </p>
      </div>
    </div>
  );
};

export default OnboardingStepsContent;
