import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { subscriptionSaveStart } from "../../store/slices/SubscriptionSlice";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const SubscriptionConfirmModal = (props) => {
  
  const t = useTranslation("subscriptions.subscription_confirm");
  const dispatch = useDispatch();
  const generateStripeIntent = useSelector((state) => state.subscription.generateStripeIntent);
  const subscriptionSave = useSelector((state) => state.subscription.subscriptionSave);
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    if(values.coupon_code)
    {
      dispatch(subscriptionSaveStart({
        amount: props.subscriptionConfirm == "monthly" ? props.newPlan.amount_monthly : props.newPlan.amount_yearly,
        coupon_code: values.coupon_code,
        subscription_plan_unique_id: props.newPlan.subscription_plan_unique_id,
        onboarding: props.onboarding,
      }))
    }
    else
    props.handleStripeIntent(props.newPlan, props.subscriptionConfirm)
  };

  useEffect(() => {
    if (
      !skipRender &&
      !generateStripeIntent.loading &&
      Object.keys(generateStripeIntent.data).length > 0
    ) {
      props.closeSubscriptionConfirm();
    }
    setSkipRender(false);
  }, [generateStripeIntent]);

  useEffect(() => {
    if (
      !skipRender &&
      !subscriptionSave.loading &&
      Object.keys(subscriptionSave.data).length > 0
    ) {
      props.closeSubscriptionConfirm();
      navigate("/billings")
    }
    setSkipRender(false);
  }, [subscriptionSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.subscriptionConfirm}
        onHide={props.closeSubscriptionConfirm}
      >
        <Modal.Body>
          <h4>{t("heading")}</h4>
          <Button
            className="modal-close"
            onClick={props.closeSubscriptionConfirm}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              coupon_code: "",
            }}
            validationSchema={Yup.object().shape({
              coupon_code: Yup.string()
              .matches(/^[^<> ]*$/, "Invalid Code")
            })}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ setFieldValue, values }) => (
              <FORM className="onboarding-form">
                <h4 className="mb-3">{t("plan_details")}</h4>
                <p>
                  {t("plan_name")} : <span style={{textTransform:"capitalize"}}>{props.newPlan.name}</span>
                </p>
                <p>
                  {t("bots")} : <span>{props.newPlan.bots}</span>
                </p>
                <p>
                  {t("fine_tunings")} : <span>{props.newPlan.fine_tunings}</span>
                </p>
                <p>
                  {t("messages")} : <span>{props.newPlan.messages}</span>
                </p>
                <hr />
                <h4 className="mb-3">{t("message")}</h4>
                  <p>{t("content")}</p> 
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("coupon_code.placeholder")}
                      name="coupon_code"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="coupon_code"
                      className="errorMsg"
                    />
                  </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={props.closeSubscriptionConfirm}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    className="onboarding-save-btn"
                    type="submit"
                    disabled={generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable}
                  >
                    {generateStripeIntent.buttonDisable || subscriptionSave.buttonDisable
                      ? t("loading")
                      : t("confirm")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionConfirmModal;
