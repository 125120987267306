import React from "react";
import { Navbar, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const NavLogo = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/home">
            <Image
              className="onboarding-logo"
              src={window.location.origin + "/images/haive-main-logo.png"}
            />
          </Link>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default NavLogo;
