import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Row,
  Col,
  Button,
  NavItem,
  NavLink
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  profileStart,
  selectedBotSuccess,
} from "../../../store/slices/AdminSlice";
import {
  selecteInboxBot,
} from "../../../store/slices/ChatSlice";
import useLogout from "../../../hooks/useLogout";
import useSocket from "../../../hooks/useSocket";
import { setChatSocket } from "../../../store/slices/ChatSlice";
import { socketUrl } from "../../../Environment";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import { toast } from "react-toastify";

const AdminHeader = (props) => {
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      // minWidth: "100%",
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#048A8912!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
      cursor:"pointer",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#048A89",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logout } = useLogout();
  const profile = useSelector((state) => state.admin.profile);
  const socket = useSelector((state) => state.chat.socket);
  const botsList = useSelector((state) => state.bots.botsList);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const selectedInboxBot = useSelector((state) => state.chat.selectedInboxBot);

  const [bots, setBots] = useState([]);
  const [inboxBots, setInboxBots] = useState([]);
  const [selectedBotDetail, setSelectedBotDetail] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = (isOpen) => {
    setDropdownOpen(isOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length == 0) {
      dispatch(profileStart());
    }
  }, []);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding_step < 6) navigate("/login");
      else {
        const webSocket = new WebSocket(
          `${socketUrl}?key=${profile.data.api_key}&user_id=${profile.data.user_id}`
        );
        dispatch(setChatSocket(webSocket));
      }
    }
  }, [profile]);

  useEffect(() => {
    console.log("socket", socket);
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  useEffect(() => {
    if (
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      let bots = botsList.data.bots.map((bot) => ({
        label: bot.name,
        value: bot.bot_id,
      }));
      setInboxBots([
        {
          label: "All Chats",
          value: "",
        },
        ...bots
      ]);
      // if(pathname == "/inbox") {
      if (Object.keys(selectedInboxBot).length > 0 && selectedInboxBot.value) {
        let bot = botsList.data.bots.find((bot) => bot.bot_id == selectedInboxBot.value);
        dispatch(selecteInboxBot( bot ? {
          label: bot.name,
          value: bot.bot_id,
        } : {
          label: "All Chats",
          value: "",
        }));
      }
      // }
      setBots(bots);
      if (Object.keys(selectedBot).length > 0) {
        let bot = botsList.data.bots.find((bot) => bot.bot_id == selectedBot.bot_id);
        setSelectedBotDetail({
          label: bot.name,
          value: bot.bot_id,
        });
      } else {
        setSelectedBotDetail(bots[0]);
        dispatch(selectedBotSuccess(botsList.data.bots[0]));
      }
    }
  }, [botsList]);

  const handleChangeBot = (option) => {
    if(option.value) {
      setSelectedBotDetail(option);
      let bot = botsList.data.bots.find((bot) => bot.bot_id == option.value);
      dispatch(selectedBotSuccess(bot));
    }
    dispatch(selecteInboxBot({
      label: option.label,
      value: option.value,
    }));
  };

  // const handleChangeInboxBot = (option) => {
  //   dispatch(selecteInboxBot({
  //     label: option.label,
  //     value: option.value,
  //   }));
  //   if(option.value) {
  //     handleChangeBot(option)
  //   }
  // };

  const getPageName = () => {
    switch (pathname) {
      case "/":
        return "Dashboard";
      case "/settings":
        return "";
      case "/bots":
        return "Bots";
      case "/subscription":
        return "Subscriptions";
      case "/billings":
        return "Billings";
      case "/inbox":
        return "";
      default:
        return "";
    }
  };

  return (
    <div className="admin-ui-header-sec">
      <Navbar expand="lg">
        <Container fluid>
          <div className="admin-ui-title-sec">
            <div
              className="admin-ui-collapse-btn"
              onClick={() => dispatch(toggleSidebar())}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#03363d"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
              </svg>
            </div>
            <h2>{getPageName()}</h2>
            <div className="my-sixth-step" style={{minWidth:'125px', minHeight:"49px"}}>
            {pathname == "/settings" ? (
              botsList.loading ? (
                <Skeleton height={45} width={150} />
              ) : Object.keys(botsList.data).length > 0 &&
                botsList.data.bots.length > 0 ? (
                bots.length > 0 ? (
                  <div className="admin-header-bot-select">
                    <Select
                      options={bots}
                      // menuIsOpen={true}
                      styles={customStyles}
                      value={selectedBotDetail}
                      isSearchable={false}
                      placeholder={<div>Select Bot</div>}
                      onChange={(option) => {
                        handleChangeBot(option);
                      }}
                    />
                  </div>
                ) : null
              ) : null
            ) : null}
            {pathname == "/inbox" ? (
              botsList.loading && Object.keys(selectedInboxBot).length <= 0 ? (
                <Skeleton height={45} width={150} />
              ) : Object.keys(botsList.data).length > 0 &&
                botsList.data.bots.length > 0 ? (
                bots.length > 0 ? (
                  <div className="admin-header-bot-select">
                    <Select
                      options={inboxBots}
                      // menuIsOpen={true}
                      styles={customStyles}
                      value={selectedInboxBot}
                      isSearchable={false}
                      placeholder={<div>Select Bot</div>}
                      onChange={(option) => {
                        handleChangeBot(option);
                      }}
                    />
                  </div>
                ) : null
              ) : null
            ) : null}
            </div>
            
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            {/* <Image
              className="hamburger-menu"
              src={window.location.origin + "/images/hamburger-menu.png"}
              type="image/png"
            /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-name="Layer 1"
              viewBox="0 0 24 24"
              width="30"
              height="30"
              fill="#03363d"
            >
              <path d="M18.5 1h-13A5.506 5.506 0 000 6.5v12C0 21.533 2.467 24 5.5 24h13c3.033 0 5.5-2.467 5.5-5.5v-12C24 3.467 21.533 1 18.5 1zM21 18.5c0 1.378-1.122 2.5-2.5 2.5h-13A2.503 2.503 0 013 18.5v-12C3 5.122 4.122 4 5.5 4h13C19.878 4 21 5.122 21 6.5v12zm-2-11A1.5 1.5 0 0117.5 9h-6a1.5 1.5 0 110-3h6A1.5 1.5 0 0119 7.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 7.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 12.5zm11 5a1.5 1.5 0 01-1.5 1.5h-6a1.5 1.5 0 110-3h6a1.5 1.5 0 011.5 1.5zm-11 0a1.5 1.5 0 11-3.001-.001A1.5 1.5 0 018 17.5z"></path>
            </svg>
          </Navbar.Toggle>
          {profile.loading ? (
            <Row className="header-profile-loader">
              <Col>
                <Skeleton circle width={50} height={50} />
              </Col>
              <Col>
                <Skeleton circle width={50} height={50} />
              </Col>
              <Col>
                <Skeleton width={100} height={50} />
              </Col>
            </Row>
          ) : Object.keys(profile.data).length > 0 ? (
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                  <div className="support-help-sec">
                    <a href="https://haive.tech/" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 33 27"
                      >
                        <path
                          fill="#03363D"
                          d="M32.49 13.439c.01-.394.02-.8.003-1.227a4.598 4.598 0 00-4.617-4.39C25.968 3.456 21.61.398 16.55.398c-5.06 0-9.417 3.058-11.325 7.422A4.597 4.597 0 00.61 12.213c-.018.425-.007.832.004 1.226.01.418.022.85.002 1.335a4.6 4.6 0 004.59 4.79c.58 0 1.157-.111 1.697-.327a.965.965 0 00.554-1.393 10.42 10.42 0 01-1.328-5.092c0-5.748 4.676-10.423 10.423-10.423s10.423 4.676 10.423 10.423a10.388 10.388 0 01-8.5 10.245 2.675 2.675 0 10.085 1.943 12.37 12.37 0 008.367-5.482 4.564 4.564 0 001.173.101 4.599 4.599 0 004.388-4.784c-.02-.485-.009-.918.002-1.336z"
                        ></path>
                        <path
                          fill="#03363D"
                          d="M32.49 13.439c.01-.394.02-.8.003-1.227a4.598 4.598 0 00-5.412-4.317.964.964 0 00-.738 1.28 10.437 10.437 0 01-.697 8.67.964.964 0 00.554 1.392 4.565 4.565 0 001.9.322 4.599 4.599 0 004.387-4.784c-.02-.485-.01-.918.002-1.336zM7.456 17.844a10.44 10.44 0 01-.698-8.668.965.965 0 00-.737-1.281A4.597 4.597 0 00.61 12.214c-.018.424-.007.832.004 1.225.01.418.022.85.002 1.335a4.599 4.599 0 004.59 4.79c.58 0 1.157-.11 1.697-.327a.965.965 0 00.554-1.393z"
                        ></path>
                        <path
                          fill="#048A89"
                          fillRule="evenodd"
                          d="M12.118 5.982a8.092 8.092 0 112.354 14.591l-3.275 1.892a.964.964 0 01-1.414-1.086l.837-3.123a8.092 8.092 0 011.498-12.274zm1.219 7.734a.965.965 0 01-.002-1.93h.002a.965.965 0 010 1.93zm3.214 0a.945.945 0 01-.536-.162.92.92 0 01-.266-.267 1.049 1.049 0 01-.089-.166.929.929 0 01-.074-.37.933.933 0 01.114-.454.963.963 0 011.812.55.925.925 0 01-.158.44 1.092 1.092 0 01-.121.147.933.933 0 01-.402.24 1.081 1.081 0 01-.185.038.931.931 0 01-.095.004zm2.534-.281a.965.965 0 10.682-1.648h-.002a.965.965 0 00-.68 1.647z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fill="#03363D"
                          d="M16.646 26.67a2.674 2.674 0 10-1.23-5.206 2.674 2.674 0 001.23 5.206z"
                        ></path>
                      </svg>
                    </a>
                  </div>
                <div className="user-dropdown-sec">
                  <Dropdown onToggle={handleDropdownToggle} show={dropdownOpen}>
                    <Dropdown.Toggle id="dropdown-basic">
                      {/* <Image
                        className="user-dropdown-img"
                        src={profile.data.picture}
                        type="image/png"
                      /> */}
                      <CustomLazyLoad
                        className="user-dropdown-img"
                        src={profile.data.picture}
                      />
                      <span>{profile.data.name}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Link to="/settings" onClick={() => handleDropdownClose()}>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                          <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                        </svg> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            x="0"
                            y="0"
                            enableBackground="new 0 0 512 512"
                            version="1.1"
                            viewBox="0 0 512 512"
                            xmlSpace="preserve"
                          >
                            <path d="M34.283 384c17.646 30.626 56.779 41.148 87.405 23.502l.062-.036 9.493-5.483A191.51 191.51 0 00192 437.055V448c0 35.346 28.654 64 64 64s64-28.654 64-64v-10.944a191.494 191.494 0 0060.757-35.115l9.536 5.504c30.633 17.673 69.794 7.167 87.467-23.467 17.673-30.633 7.167-69.794-23.467-87.467l-9.472-5.461a194.15 194.15 0 000-70.187l9.472-5.461c30.633-17.673 41.14-56.833 23.467-87.467-17.673-30.633-56.833-41.14-87.467-23.467l-9.493 5.483A191.518 191.518 0 00320 74.944V64c0-35.346-28.654-64-64-64s-64 28.654-64 64v10.944a191.494 191.494 0 00-60.757 35.115l-9.536-5.525C91.073 86.86 51.913 97.367 34.24 128s-7.167 69.794 23.467 87.467l9.472 5.461a194.15 194.15 0 000 70.187l-9.472 5.461C27.158 314.296 16.686 353.38 34.283 384zM256 170.667c47.128 0 85.333 38.205 85.333 85.333S303.128 341.333 256 341.333 170.667 303.128 170.667 256s38.205-85.333 85.333-85.333z"></path>
                          </svg>
                        <span>Settings</span>
                      </Link>
                      <Link to="/profile" onClick={() => handleDropdownClose()}>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zM12 14a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512 512"
                        >
                          <g fill="#6e6d8e">
                            <circle cx="256" cy="128" r="128" data-original="#000000"></circle>
                            <path
                              d="M256 298.667c-105.99.118-191.882 86.01-192 192C64 502.449 73.551 512 85.333 512h341.333c11.782 0 21.333-9.551 21.333-21.333-.117-105.99-86.009-191.883-191.999-192z"
                              data-original="#000000"
                            ></path>
                          </g>
                        </svg>
                        <span>My Account</span>
                      </Link>
                      <Link to="#" onClick={() => logout()}>
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M11.476 15a1 1 0 00-1 1v3a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2.476a3 3 0 013 3v3a1 1 0 002 0V5a5.006 5.006 0 00-5-5H5a5.006 5.006 0 00-5 5v14a5.006 5.006 0 005 5h2.476a5.006 5.006 0 005-5v-3a1 1 0 00-1-1z"></path>
                          <path d="M22.867 9.879l-4.586-4.586a1 1 0 10-1.414 1.414l4.263 4.263L6 11a1 1 0 000 2l15.188-.03-4.323 4.323a1 1 0 101.414 1.414l4.586-4.586a3 3 0 00.002-4.242z"></path>
                        </svg> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 374 374"
                        >
                          <path
                            fill="#6E6D8E"
                            d="M101.669 235h144v80c0 32.427-26.24 58.667-58.666 58.667h-128C26.576 373.667.336 347.427.336 315V59C.336 26.573 26.576.333 59.003.333h128c32.426 0 58.666 26.24 58.666 58.667v80h-144c-26.453 0-48 21.547-48 48 0 26.454 21.547 48 48 48zm267.307-59.306l-64-64a16.005 16.005 0 10-22.635 22.634l36.694 36.694H101.669a15.998 15.998 0 00-16 16 15.998 15.998 0 0016 16h217.366l-36.694 36.693a16 16 0 0022.614 22.634l64-64a16.007 16.007 0 004.69-11.317 16.013 16.013 0 00-4.69-11.317l.021-.021z"
                          ></path>
                        </svg>
                        <span>Logout</span>
                      </Link>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Nav>
            </Navbar.Collapse>
          ) : null}
        </Container>
      </Navbar>
    </div>
  );
};

export default AdminHeader;
