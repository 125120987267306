import React, { useEffect, useState } from "react";
import { Nav, Row, Col, Tab, Image } from "react-bootstrap";
import ChatCustomize from "./Customise/ChatCustomize";
import ChatPreview from "./ChatPreview";
import SettingsfineTune from "./CustomTraining/SettingsFinetune";
import SourceScriptIndex from "./Source/SourceScriptIndex";
import SettingAnalyticsIndex from "./Analytics/SettingAnalyticsIndex";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { botsListStart } from "../../store/slices/BotSlice";
import NoBotExists from "../Helper/NoBotExists";
import AddBotModal from "../Bots/AddBotModal";
import { useTranslation, withTranslation } from "react-multi-lang";
import {
  fineTuneListStart,
} from "../../store/slices/FineTuneSlice";
import ReactJoyride from "react-joyride";
import { updateTourStart } from "../../store/slices/AdminSlice";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const SettingsIndex = (props) => {

  const t = useTranslation("settings.options");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const botsList = useSelector((state) => state.bots.botsList);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const profile = useSelector((state) => state.admin.profile);

  const [tab, setTab] = useState(searchParams.get("tab") ? searchParams.get("tab") : "customize");
  const [bot, setBot] = useState(selectedBot);
  const [addBot, setAddBot] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [botDetails, setBotDetails] = useState({
    name: "",
    website: "",
  })

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  useEffect(() => {
    dispatch(botsListStart());
  }, []);

  useEffect(() => {
    if(Object.keys(selectedBot).length > 0) {
      setBot(selectedBot);
      setBotDetails({
        ...botDetails,
        name: selectedBot.name,
        website: selectedBot.website
      })
      // dispatch(
      //   fineTuneListStart({ bot_id: selectedBot.bot_id })
      // );
    }
  }, [selectedBot]);

  useEffect(()=> {
    if(!skipRender && !botsStore.loading && Object.keys(botsStore.data).length > 0 && Object.keys(botsStore.data.bot).length > 0) {
      setBot(botsStore.data.bot);
      setBotDetails({
        ...botDetails,
        name: botsStore.data.bot.name,
        website: botsStore.data.bot.website
      })
    }
    setSkipRender(false);
  }, [botsStore])

  const Step6 = () => {
    return (
      <div className="tour-step-1">
        <CustomLazyLoad src={window.location.origin + "/images/tour/bot-select-img.png"} className="tour-step-img" />
        <ul className="list-unstyled tour-step-list">
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Choose from a drop-down list of available bots.</span>
          </li>
          <li>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fillRule="evenodd"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 32 32"
            >
              <path
                fill="#048a89"
                d="M64.833 18.008H55.5a1 1 0 00-1 1v2.686a.499.499 0 01-.773.419c-.075-.049-6.982-5.675-7-5.686a.501.501 0 010-.838c.141-.092 6.839-5.582 7-5.686a.501.501 0 01.773.419v2.686a1 1 0 001 1s6.485 0 9.338-.015a1.987 1.987 0 001.876-2.617C64.8 5.922 59.604 2.008 53.5 2.008c-7.727 0-14 6.273-14 14s6.273 14 14 14c6.103 0 11.299-3.913 13.228-9.361l.01-.028a2 2 0 00-1.906-2.611zm-60.347 0a1.997 1.997 0 00-1.906 2.611l.01.028c1.929 5.448 7.125 9.361 13.228 9.361 7.727 0 14-6.273 14-14s-6.273-14-14-14c-6.104 0-11.3 3.914-13.214 9.368a1.987 1.987 0 001.881 2.617c2.848.015 9.333.015 9.333.015a1 1 0 001-1v-2.686a.5.5 0 01.773-.419c.161.104 6.859 5.594 7 5.686a.501.501 0 010 .838c-.018.011-6.925 5.637-7 5.686a.499.499 0 01-.773-.419v-2.686a1 1 0 00-1-1zM-23 4.502a1.997 1.997 0 00-2.611-1.906l-.028.009C-31.087 4.535-35 9.731-35 15.833c0 7.727 6.273 14 14 14s14-6.273 14-14c0-6.103-3.915-11.3-9.368-13.214l-.026-.008a1.986 1.986 0 00-2.591 1.89C-19 7.348-19 13.834-19 13.834a1 1 0 001 1h2.686a.499.499 0 01.419.772c-.105.161-5.594 6.859-5.686 7a.5.5 0 01-.838 0c-.011-.017-5.637-6.924-5.686-7a.499.499 0 01.419-.772H-24a1 1 0 001-1zm-37 22.997v-9.333a1 1 0 00-1-1h-2.686a.499.499 0 01-.419-.772c.049-.076 5.675-6.983 5.686-7a.5.5 0 01.838 0c.092.141 5.581 6.839 5.686 7a.499.499 0 01-.419.772H-55a1 1 0 00-1 1s0 6.486.015 9.338a1.984 1.984 0 002.591 1.885l.026-.008C-47.915 27.467-44 22.27-44 16.167c0-7.727-6.273-14-14-14s-14 6.273-14 14c0 6.102 3.913 11.298 9.361 13.228a1.997 1.997 0 001.818-.28c.517-.377.822-.978.821-1.617z"
                data-original="#000000"
              ></path>
            </svg>
            <span>Switch between bots easily and seamlessly.</span>
          </li>
        </ul>
      </div>
    )
  }

  const
    steps = [
      {
        target: '.my-sixth-step',
        title: 'Select Bot',
        content: <Step6/>,
        disableBeacon: true,
      }
    ]

    const handleJoyrideCallback = (data) => {
      if (['next', 'skip'].includes(data.action)) {
        if(
          ((['skip'].includes(data.action) && data.lifecycle == 'complete') || (['next'].includes(data.action) && data.lifecycle == 'init')) 
          && !profile.loading && Object.keys(profile.data).length > 0 && !profile.data.settings_tour) {
          dispatch(updateTourStart({
            name: profile.data.name,
            email: profile.data.email,
            settings_tour: 1,
          }))
        }
      }
    };

  return (
    <>
      <div className="admin-ui-setting-body-sec">
        <div className="new-settings-sec">
        {!profile.loading && Object.keys(profile.data).length > 0 && !profile.data.settings_tour && Object.keys(selectedBot).length > 0 ? <ReactJoyride
            floaterProps={{ placement: "bottom" }}
            steps={steps}
            run={true}
            continuous={true}
            spotlightClicks={true}
            scrollToFirstStep={true}
            disableScrolling={true}
            showSkipButton
            showProgress={true}
            disableOverlayClose={true}
            callback={handleJoyrideCallback}
            hideCloseButton={true}
            autoStart
            styles={{
              options: {
                borderRadius: '0',
                arrowColor: '#fff',
                backgroundColor: '#fff',
                overlayColor: '#312f2f',
                primaryColor: '#000',
                textColor: '#004a14',
                width: 550,
                zIndex: 99999,
              },
              buttonNext: {
                fontSize: "0.9em",
                padding: "1em 1em",
                display: "inline-block",
                backgroundColor: "#048a89",
                border: '0',
                letterSpacing: ".5px",
                borderRadius: "0!important",
                fontWeight: "500",
                marginLeft: "16px",
                outline: 'none!important',
                position: 'relative',
              },
              overlay: {
                backgroundColor: 'none',
                mixBlendMode: 'unset',
              },
              spotlight: {
                backgroundColor: '#FFFFFF66',
                borderRadius: 0,
                boxShadow: '0px 0px 0px 9999px rgba(0,0,0,0.7)',
                marginLeft: '0px',
                maxHeight: "66px"
              },   
            }}
            {
            ...props
            }>
            Test
          </ReactJoyride>:null}
          {/* <div className="new-settings-box"> */}
            {Object.keys(selectedBot).length > 0 ? (
              <div className="new-settings-box">
                <div className="new-settings-tab-sec">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey={searchParams.get("tab") ? searchParams.get("tab") : "customize"}
                    onSelect={(key, ev) => (ev.type != "click") && setTab(key)}
                  >
                    <Row>
                      <Col sm={12}>
                        <div className="new-settings-tab-nav-link-sec">
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="customize"
                                onClick={() => setTab("customize")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 8a4 4 0 104 4 4 4 0 00-4-4zm0 6a2 2 0 112-2 2 2 0 01-2 2z"></path>
                                  <path d="M21.294 13.9l-.444-.256a9.1 9.1 0 000-3.29l.444-.256a3 3 0 10-3-5.2l-.445.257A8.977 8.977 0 0015 3.513V3a3 3 0 00-6 0v.513a8.977 8.977 0 00-2.848 1.646L5.705 4.9a3 3 0 00-3 5.2l.444.256a9.1 9.1 0 000 3.29l-.444.256a3 3 0 103 5.2l.445-.257A8.977 8.977 0 009 20.487V21a3 3 0 006 0v-.513a8.977 8.977 0 002.848-1.646l.447.258a3 3 0 003-5.2zm-2.548-3.776a7.048 7.048 0 010 3.75 1 1 0 00.464 1.133l1.084.626a1 1 0 01-1 1.733l-1.086-.628a1 1 0 00-1.215.165 6.984 6.984 0 01-3.243 1.875 1 1 0 00-.751.969V21a1 1 0 01-2 0v-1.252a1 1 0 00-.751-.969A6.984 6.984 0 017.006 16.9a1 1 0 00-1.215-.165l-1.084.627a1 1 0 11-1-1.732l1.084-.626a1 1 0 00.464-1.133 7.048 7.048 0 010-3.75 1 1 0 00-.465-1.129l-1.084-.626a1 1 0 011-1.733l1.086.628A1 1 0 007.006 7.1a6.984 6.984 0 013.243-1.875A1 1 0 0011 4.252V3a1 1 0 012 0v1.252a1 1 0 00.751.969A6.984 6.984 0 0116.994 7.1a1 1 0 001.215.165l1.084-.627a1 1 0 111 1.732l-1.084.626a1 1 0 00-.463 1.129z"></path>
                                </svg> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 18 19"
                                >
                                  <path
                                    d="M15.81 9.5c0-.215-.008-.42-.027-.636l1.708-1.318a.952.952 0 00.238-1.216l-1.716-3.02a.91.91 0 00-.5-.42.89.89 0 00-.648.027l-1.973.851a6.931 6.931 0 00-1.074-.636L11.55.972a.927.927 0 00-.909-.823H7.219A.929.929 0 006.3.972l-.266 2.16a6.936 6.936 0 00-1.074.636l-1.974-.851a.898.898 0 00-1.147.393L.122 6.34a.954.954 0 00.239 1.215l1.707 1.319c-.038.42-.038.842 0 1.262L.361 11.454a.952.952 0 00-.239 1.216l1.717 3.02c.23.412.725.58 1.147.393l1.974-.85c.34.242.698.457 1.074.635l.266 2.16c.055.468.45.823.91.823h3.423a.927.927 0 00.91-.823l.265-2.16a6.94 6.94 0 001.074-.636l1.974.851a.898.898 0 001.147-.393l1.717-3.02a.954.954 0 00-.238-1.216l-1.708-1.318c.028-.215.037-.42.037-.636zm-6.847 3.273c-1.772 0-3.213-1.468-3.213-3.273 0-1.805 1.44-3.273 3.213-3.273 1.771 0 3.213 1.468 3.213 3.273 0 1.805-1.442 3.273-3.213 3.273z"
                                  ></path>
                                </svg>
                                {t("customise")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="fine-tune"
                                onClick={() => setTab("fine-tune")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                                </svg> */}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="none"
                                      viewBox="0 0 19 19"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M1.867 9.084H7.29c.738 0 1.339-.594 1.339-1.322v-3.38c0-.842-.544-1.827-1.552-1.997a.417.417 0 01-.345-.48.423.423 0 01.488-.342 2.537 2.537 0 011.409.743V.512a2.336 2.336 0 00-2.453-.29 2.276 2.276 0 00-1.293 2.245 2.289 2.289 0 001.673 1.985.416.416 0 01.293.513.423.423 0 01-.52.29 3.128 3.128 0 01-2.287-2.714 3.037 3.037 0 01.006-.598c-.66.25-1.207.722-1.546 1.351a2.888 2.888 0 00-.057 2.621c.1.208.012.457-.199.556a.426.426 0 01-.563-.196 3.696 3.696 0 01-.34-1.157A2.077 2.077 0 00.116 6.367a2.027 2.027 0 00.468 2.096c.34.35.796.57 1.284.62zm2.65-3.553c.137-.187.4-.23.59-.095.189.134.232.395.096.582-.247.34-.28.782-.083 1.153.193.367.572.594.99.594h.02c.231 0 .42.182.423.411a.42.42 0 01-.417.422h-.025c-.736 0-1.4-.398-1.74-1.042a1.908 1.908 0 01.146-2.025zm-3.173 8.352c.049-.398.16-.789.339-1.157a.426.426 0 01.563-.197c.21.1.3.348.2.556-.402.83-.381 1.809.056 2.621.339.63.886 1.102 1.546 1.351a3.04 3.04 0 01-.006-.598 3.128 3.128 0 012.288-2.714.423.423 0 01.52.29.416.416 0 01-.294.513 2.288 2.288 0 00-1.673 1.985 2.275 2.275 0 001.293 2.245c.804.382 1.769.263 2.453-.29v-1.793c-.36.37-.832.644-1.41.742a.42.42 0 01-.488-.341.417.417 0 01.346-.48c1.008-.17 1.552-1.156 1.552-1.997v-3.38c0-.729-.6-1.322-1.34-1.322H1.87c-.49.05-.946.27-1.287.62a2.028 2.028 0 00-.468 2.096c.202.578.66 1.034 1.229 1.25zm3.027-2.438a1.956 1.956 0 011.765-1.042c.233.002.42.192.417.421a.42.42 0 01-.423.412h-.02c-.418 0-.797.227-.99.593-.196.371-.164.814.083 1.154a.413.413 0 01-.097.581.423.423 0 01-.589-.095 1.908 1.908 0 01-.146-2.024zm6.967 7.13H9.474v-4.432c.11.008.221.012.333.012 2.604 0 4.714-2.084 4.714-4.654s-2.11-4.653-4.714-4.653c-.112 0-.223.004-.334.012V.428h1.865l.364 1.824 1.957.802 1.566-1.037 2.165 2.137-1.05 1.545.811 1.932 1.849.36v3.021l-1.849.36-.811 1.932 1.05 1.545-2.165 2.137-1.566-1.037-1.957.802-.364 1.824z"
                                        clipRule="evenodd"
                                      ></path>
                                    </svg>
                                {t("fine_tune")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link onClick={() => navigate("/inbox")}>
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                  <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                                </svg> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path
                                    d="M2.488 2.49a8.5 8.5 0 0112.022 0 8.49 8.49 0 011.597 9.801l.875 3.85a.698.698 0 01-.835.835l-3.852-.875C6.695 18.895-.002 14.85-.002 8.498c0-2.27.885-4.403 2.49-6.008zm2.464 8.123h3.7a.698.698 0 100-1.396h-3.7a.698.698 0 100 1.396zm0-2.835h7.093a.698.698 0 100-1.396H4.952a.698.698 0 100 1.396z"
                                  ></path>
                                </svg>
                                {t("inbox")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="source"
                                onClick={() => setTab("source")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1z"></path>
                                </svg> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path
                                    d="M.5 6.318v6.546c.001.964.353 1.888.977 2.57.625.681 1.473 1.065 2.356 1.066h9.334c.883-.001 1.73-.385 2.356-1.066a3.815 3.815 0 00.977-2.57V6.318H.5zm9.333 7.273H4.5a.64.64 0 01-.471-.213.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h5.333a.64.64 0 01.472.213.762.762 0 01.195.515c0 .192-.07.377-.195.514a.64.64 0 01-.472.213zm2.667-2.91h-8a.64.64 0 01-.471-.212.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h8a.64.64 0 01.471.213.762.762 0 01.196.515c0 .192-.07.377-.196.514a.64.64 0 01-.471.213zm4-5.817v-.728a3.815 3.815 0 00-.977-2.57C14.898.885 14.05.501 13.167.5H3.833c-.883.001-1.73.385-2.356 1.066A3.815 3.815 0 00.5 4.136v.728h16zm-9.333-2.91c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213z"
                                  ></path>
                                </svg>
                                {t("source")}
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="analytics"
                                onClick={() => setTab("analytics")}
                              >
                                {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M23 22H5a3 3 0 01-3-3V1a1 1 0 00-2 0v18a5.006 5.006 0 005 5h18a1 1 0 000-2z"></path>
                                  <path d="M6 20a1 1 0 001-1v-7a1 1 0 00-2 0v7a1 1 0 001 1zM10 10v9a1 1 0 002 0v-9a1 1 0 00-2 0zM15 13v6a1 1 0 002 0v-6a1 1 0 00-2 0zM20 9v10a1 1 0 002 0V9a1 1 0 00-2 0zM6 9a1 1 0 00.707-.293l3.586-3.586a1.025 1.025 0 011.414 0l2.172 2.172a3 3 0 004.242 0l5.586-5.586A1 1 0 0022.293.293l-5.586 5.585a1 1 0 01-1.414 0l-2.172-2.171a3 3 0 00-4.242 0L5.293 7.293A1 1 0 006 9z"></path>
                                </svg> */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 17 17"
                                >
                                  <path
                                    d="M4.781 9.563H1.594a.531.531 0 00-.532.53v5.313c0 .294.238.531.532.531H4.78a.531.531 0 00.532-.53v-5.313a.531.531 0 00-.532-.531zM15.406 5.313H12.22a.531.531 0 00-.531.53v9.563c0 .294.237.531.53.531h3.188a.531.531 0 00.531-.53V5.843a.531.531 0 00-.53-.532zM10.094 1.063H6.906a.531.531 0 00-.531.53v13.813c0 .294.238.531.531.531h3.188a.531.531 0 00.531-.53V1.593a.531.531 0 00-.531-.532z"
                                  ></path>
                                </svg>
                                {t("analytics")}
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content>
                          {tab == "customize" && (
                            <Tab.Pane eventKey="customize">
                              <ChatCustomize setBotDetails={setBotDetails} botDetails={botDetails} setBot={setBot} bot={bot} />
                            </Tab.Pane>
                          )}
                          {tab == "fine-tune" && (
                            <Tab.Pane eventKey="fine-tune">
                              <SettingsfineTune />
                            </Tab.Pane>
                          )}
                          {tab == "analytics" && (
                            <Tab.Pane eventKey="analytics">
                              <SettingAnalyticsIndex />
                            </Tab.Pane>
                          )}
                          {tab == "source" && (
                            <Tab.Pane eventKey="source">
                              <SourceScriptIndex />
                            </Tab.Pane>
                          )}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
                <div className="new-settings-preview-sec">
                  {Object.keys(selectedBot).length > 0 ? (
                    <ChatPreview selectedBot={bot} botDetails={botDetails} />
                  ) : null}
                </div>
              </div>
            ) : (!botsList.loading && Object.keys(botsList.data).length > 0 && Object.keys(botsList.data.bots).length <= 0) &&
            (
              <div className="mt-5">
                <NoBotExists setAddBot={setAddBot} />
                </div>
            )
            }
          {/* </div> */}
        </div>
      </div>
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsIndex);
