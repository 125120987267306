import React, { useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./OnBoarding.css";
import { useTranslation, withTranslation } from "react-multi-lang";

const OnPremiseSuccess = (props) => {

  const t = useTranslation("on_premise")
  const navigate = useNavigate();
  return (
    <>
      {/* <div className="onboarding-success-box">
        <div className="onboarding-success-header-logo">
            <div className="onboarding-logo-sec">
                <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <div className="haive-logo">
                            HaiVE
                        </div>
                    </Navbar.Brand>
                </Container>
                </Navbar>
            </div>
            <div className="on-premise-success-box">
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <div className="on-premise-success-card">
                            <div className="on-premise-success-img-sec">
                                <Image
                                    className="on-premise-success-img"
                                    src={
                                    window.location.origin + "/images/onboarding/on-premise-success-img.svg"
                                    }
                                />
                            </div>
                            <div className="on-premise-success-info">
                                <h4>Thanks!</h4>
                                <p>We’ve received your submission. Someone from our team will contact you very soon. </p>
                            </div>
                            <div className="on-premise-success-btn-sec">
                                <Link to="/onboarding" className="onboarding-btn">
                                    Go Home
                                </Link>
                            </div>
                        </div> 
                    </Col>  
                </Row>
            </div>
        </div>
      </div> */}
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <Navbar expand="lg">
              <Container>
                <Navbar.Brand href="/home">
                  <Image
                    className="onboarding-logo"
                    src={
                      window.location.origin + "/images/haive-text-logo.svg"
                    }
                  />
                </Navbar.Brand>
              </Container>
            </Navbar>
          </div>
          <div className="onboarding-steps-card">
            {/* <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>Contact Us</h2>
                    <p>Please provide all requested information to add in our business network.</p>
                  </div>
                </Col>
              </Row>
            </div> */}
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="on-premise-success-card">
                    <div className="on-premise-success-img-sec">
                      <Image
                        className="on-premise-success-img"
                        src={
                          window.location.origin +
                          "/images/onboarding/on-premise-success-img.svg"
                        }
                      />
                    </div>
                    <div className="on-premise-success-info">
                      {/* <h4>Thanks!</h4> */}

                      <p>
                        {t("success.heading")} <br></br>{t("success.sub_heading")}
                      </p>
                    </div>
                    <div className="on-premise-success-btn-sec">
                      <Button
                        className="onboarding-btn"
                        onClick={() => navigate("/home")}
                      >
                        {t("success.home")}
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="onboarding-right-sec">
          <div className="onboarding-installation-content">
            <h3>Power of HaiVE's On-Premises: Request a Demo Today!</h3>
            <p>
              Discover how HaiVE on-premises solution can drive exceptional
              business outcomes with our industry-specific contact center
              platform.
            </p>
            <p>
              You'll see the transformative capabilities of HaiVE's on-premises
              solution, including:
            </p>
            <ul className="list-unstyled onboarding-installation-list">
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#03363d"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#03363d"
                  />
                </svg>
                <span>
                  Unified Agent Experience: Tools and data unified on a single
                  pane of glass for personalised and efficient support.
                </span>
              </li>
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#03363d"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#03363d"
                  />
                </svg>
                <span>
                  Omni-channel Engagement: Seamless transition between channels
                  while preserving crucial data and context.
                </span>
              </li>
              <li>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    fill="#03363d"
                  />
                  <path
                    d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                    fill="white"
                    stroke="white"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="22.76"
                    height="22.3251"
                    rx="11.1625"
                    stroke="#03363d"
                  />
                </svg>
                <span>
                  AI-powered Assistance: Next best actions and proactive support
                  with our intelligent assistant
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnPremiseSuccess;
