import { createSlice } from "@reduxjs/toolkit";
import {
    getCommonInitialState,
    getCommonStart,
    getCommonSuccess,
    getCommonFailure,
} from "../utils";

const initialState = {
    theme: "",
    login: getCommonInitialState(),
    register: getCommonInitialState(),
    forgotPassword: getCommonInitialState(),
    verifyForgotPassword: getCommonInitialState(),
    resetPassword: getCommonInitialState(),
    profile: getCommonInitialState(),
    updateProfile: getCommonInitialState(),
    updateEmailCode: getCommonInitialState(),
    updateEmail: getCommonInitialState(),
    deleteProfile: getCommonInitialState(),
    dashboard: getCommonInitialState(),
    generateAPIKey: getCommonInitialState(),
    viewAPIKey: getCommonInitialState(),
    changePassword: getCommonInitialState(),
    settingsJson: getCommonInitialState(),
    emailVerify: getCommonInitialState(),
    updateTwoStep: getCommonInitialState(),
    twoStepAuth: getCommonInitialState(),
    resendOtp: getCommonInitialState(),
    logout: getCommonInitialState(),
    updateLiveChatConfiguration: getCommonInitialState(),
    sendInstallationDocuments: getCommonInitialState(),
    updateTour : getCommonInitialState(),
    widgetReminderList: getCommonInitialState(),
    saveWidgetReminder: getCommonInitialState(),
    selectedBot: {},
};

const AdminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {

        //set theme
        setTheme: (state, action) => { state.theme = action.payload },

        //Login
        loginStart: (state, action) => { state.login = getCommonStart() },
        loginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
        loginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

        //Register
        registerStart: (state, action) => { state.register = getCommonStart() },
        registerSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
        registerFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

        //Forgot password
        forgotPasswordStart: (state, action) => { state.forgotPassword = getCommonStart() },
        forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
        forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

        // Verify Forgot password
        verifyForgotPasswordStart: (state, action) => { state.verifyForgotPassword = getCommonStart() },
        verifyForgotPasswordSuccess: (state, action) => { state.verifyForgotPassword = getCommonSuccess(action.payload) },
        verifyForgotPasswordFailure: (state, action) => { state.verifyForgotPassword = getCommonFailure(action.payload) },

        //Reset Password
        resetPasswordStart: (state, action) => { state.resetPassword = getCommonStart() },
        resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
        resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

        //Profile
        profileStart: (state, action) => { state.profile = getCommonStart() },
        profileSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
        profileFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

        //Update Profile
        updateProfileStart: (state, action) => { state.updateProfile = getCommonStart() },
        updateProfileSuccess: (state, action) => { state.updateProfile = getCommonSuccess(action.payload) },
        updateProfileFailure: (state, action) => { state.updateProfile = getCommonFailure(action.payload) },

        //Update Email Verification Code
        updateEmailCodeStart: (state, action) => { state.updateEmailCode = getCommonStart() },
        updateEmailCodeSuccess: (state, action) => { state.updateEmailCode = getCommonSuccess(action.payload) },
        updateEmailCodeFailure: (state, action) => { state.updateEmailCode = getCommonFailure(action.payload) },

        //Update Email Verification Code
        updateEmailStart: (state, action) => { state.updateEmail = getCommonStart() },
        updateEmailSuccess: (state, action) => { state.updateEmail = getCommonSuccess(action.payload) },
        updateEmailFailure: (state, action) => { state.updateEmail = getCommonFailure(action.payload) },

        //Delete Profile
        deleteProfileStart: (state, action) => { state.deleteProfile = getCommonStart() },
        deleteProfileSuccess: (state, action) => { state.deleteProfile = getCommonSuccess(action.payload) },
        deleteProfileFailure: (state, action) => { state.deleteProfile = getCommonFailure(action.payload) },

        //Dashboard
        dashboardStart: (state, action) => { state.dashboard = getCommonStart() },
        dashboardSuccess: (state, action) => { state.dashboard = getCommonSuccess(action.payload) },
        dashboardFailure: (state, action) => { state.dashboard = getCommonFailure(action.payload) },
        dashboardListUpdate: (state, { payload }) => {
            if (state.dashboard.data.recent_chats)
                state.dashboard = getCommonSuccess({
                    ...state.dashboard.data,
                    recent_chats: [payload, ...state.dashboard.data.recent_chats.filter(chat => chat.chat_id !== payload.chat_id).slice(0, 5)],
                })
            else state.dashboard = state.dashboard
        },
        disableDashboardNotify: (state, { payload }) => {
            state.dashboard = getCommonSuccess({
                ...state.dashboard.data,
                recent_chats: state.dashboard.data.recent_chats.map(chat => chat.chat_id === payload ? { ...chat, notify: false } : chat)
            })
        },

        //Generate API key for user
        generateAPIKeyStart: (state, action) => { state.generateAPIKey = getCommonStart() },
        generateAPIKeySuccess: (state, action) => { state.generateAPIKey = getCommonSuccess(action.payload) },
        generateAPIKeyFailure: (state, action) => { state.generateAPIKey = getCommonFailure(action.payload) },

        //View API Key 
        viewAPIKeyStart: (state, action) => { state.viewAPIKey = getCommonStart() },
        viewAPIKeySuccess: (state, action) => { state.viewAPIKey = getCommonSuccess(action.payload) },
        viewAPIKeyFailure: (state, action) => { state.viewAPIKey = getCommonFailure(action.payload) },

        //Change Password
        changePasswordStart: (state, action) => { state.changePassword = getCommonStart() },
        changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
        changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

        // Settings JSON
        settingsJsonStart: (state, action) => { state.settingsJson = getCommonStart() },
        settingsJsonSuccess: (state, action) => { state.settingsJson = getCommonSuccess(action.payload) },
        settingsJsonFailure: (state, action) => { state.settingsJson = getCommonFailure(action.payload) },

        // Email Verification
        emailVerifyStart: (state) => { state.emailVerify = getCommonStart() },
        emailVerifySuccess: (state, { payload }) => { state.emailVerify = getCommonSuccess(payload) },
        emailVerifyFailure: (state, { payload }) => { state.emailVerify = getCommonFailure(payload) },

        // Two Step Auth
        twoStepAuthStart: (state) => { state.twoStepAuth = getCommonStart() },
        twoStepAuthSuccess: (state, { payload }) => { state.twoStepAuth = getCommonSuccess(payload) },
        twoStepAuthFailure: (state, { payload }) => { state.twoStepAuth = getCommonFailure(payload) },

        // Update Two step status
        updateTwoStepStart: (state) => { state.updateTwoStep = getCommonStart() },
        updateTwoStepSuccess: (state, { payload }) => { state.updateTwoStep = getCommonSuccess(payload) },
        updateTwoStepFailure: (state, { payload }) => { state.updateTwoStep = getCommonFailure(payload) },

        // Resend OTP Start
        resendOtpStart: (state) => { state.resendOtp = getCommonStart() },
        resendOtpSuccess: (state, { payload }) => { state.resendOtp = getCommonSuccess(payload) },
        resendOtpFailure: (state, { payload }) => { state.resendOtp = getCommonFailure(payload) },

        // Logout
        logoutStart: (state) => { state.logout = getCommonStart() },
        logoutSuccess: (state, { payload }) => { state.logout = getCommonSuccess(payload) },
        logoutFailure: (state, { payload }) => { state.logout = getCommonFailure(payload) },

        //Live chat configuration
        updateLiveChatConfigurationStart: (state, action) => { state.updateLiveChatConfiguration = getCommonStart() },
        updateLiveChatConfigurationSuccess: (state, action) => { state.updateLiveChatConfiguration = getCommonSuccess(action.payload) },
        updateLiveChatConfigurationFailure: (state, action) => { state.updateLiveChatConfiguration = getCommonFailure(action.payload) },

        //Send Installation Docs
        sendInstallationDocumentsStart: (state, action) => { state.sendInstallationDocuments = getCommonStart() },
        sendInstallationDocumentsSuccess: (state, action) => { state.sendInstallationDocuments = getCommonSuccess(action.payload) },
        sendInstallationDocumentsFailure: (state, action) => { state.sendInstallationDocuments = getCommonFailure(action.payload) },

        // Update Tour
        updateTourStart: (state, action) => { state.updateTour = getCommonStart() },
        updateTourSuccess: (state, action) => { state.updateTour = getCommonSuccess(action.payload) },
        updateTourFailure: (state, action) => { state.updateTour = getCommonFailure(action.payload) },

        // Widget Reminder List
        widgetReminderListStart: (state, action) => { state.widgetReminderList = getCommonStart() },
        widgetReminderListSuccess: (state, action) => { state.widgetReminderList = getCommonSuccess(action.payload) },
        widgetReminderListFailure: (state, action) => { state.widgetReminderList = getCommonFailure(action.payload) },

        // Save Widget Reminder
        saveWidgetReminderStart: (state, action) => { state.saveWidgetReminder = getCommonStart() },
        saveWidgetReminderSuccess: (state, action) => { state.saveWidgetReminder = getCommonSuccess(action.payload) },
        saveWidgetReminderFailure: (state, action) => { state.saveWidgetReminder = getCommonFailure(action.payload) },

        //Selected Bot 
        selectedBotSuccess: (state, action) => { state.selectedBot = action.payload }

    }
});


export const {
    setTheme,
    loginStart,
    loginSuccess,
    loginFailure,
    registerStart,
    registerSuccess,
    registerFailure,
    forgotPasswordStart,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    verifyForgotPasswordFailure,
    verifyForgotPasswordStart,
    verifyForgotPasswordSuccess,
    resetPasswordStart,
    resetPasswordSuccess,
    resetPasswordFailure,
    profileStart,
    profileSuccess,
    profileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    updateEmailCodeFailure,
    updateEmailCodeStart,
    updateEmailCodeSuccess,
    updateEmailFailure,
    updateEmailStart,
    updateEmailSuccess,
    deleteProfileFailure,
    deleteProfileStart,
    deleteProfileSuccess,
    dashboardStart,
    dashboardSuccess,
    dashboardFailure,
    dashboardListUpdate,
    disableDashboardNotify,
    generateAPIKeyStart,
    generateAPIKeySuccess,
    generateAPIKeyFailure,
    viewAPIKeyStart,
    viewAPIKeySuccess,
    viewAPIKeyFailure,
    changePasswordStart,
    changePasswordSuccess,
    changePasswordFailure,
    settingsJsonStart,
    settingsJsonSuccess,
    settingsJsonFailure,
    emailVerifyStart,
    emailVerifySuccess,
    emailVerifyFailure,
    twoStepAuthStart,
    twoStepAuthSuccess,
    twoStepAuthFailure,
    updateTwoStepStart,
    updateTwoStepSuccess,
    updateTwoStepFailure,
    resendOtpStart,
    resendOtpSuccess,
    resendOtpFailure,
    logoutStart,
    logoutSuccess,
    logoutFailure,
    updateLiveChatConfigurationStart,
    updateLiveChatConfigurationSuccess,
    updateLiveChatConfigurationFailure,
    sendInstallationDocumentsFailure,
    sendInstallationDocumentsStart,
    sendInstallationDocumentsSuccess,
    selectedBotSuccess,
    updateTourStart,
    updateTourSuccess,
    updateTourFailure,
    widgetReminderListStart,
    widgetReminderListSuccess,
    widgetReminderListFailure,
    saveWidgetReminderStart,
    saveWidgetReminderSuccess,
    saveWidgetReminderFailure,
} = AdminSlice.actions;

export default AdminSlice.reducer;