import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { requestEnterprisePlanStart } from "../../store/slices/SubscriptionSlice";
import { useNavigate } from "react-router-dom";

const EnterpriseSubscriptionCard = (props) => {

  const dispatch = useDispatch();
  const { plan, type } = props;
  const t = useTranslation("onboarding_subscription");
  const [skipRender, setSkipRender] = React.useState(true);
  const navigate = useNavigate();

  const requestEnterprisePlan = useSelector((state) => state.subscription.requestEnterprisePlan);

    useEffect(() => {
        if(!skipRender && Object.keys(requestEnterprisePlan.data).length > 0) {
            props.onboarding && navigate("/dashboard")
        }
    }
    , [requestEnterprisePlan.data]);
  return (
    <div className={`onboarding-subscription-plans-card advance-plan-card`}>
      <div className="onboarding-subscription-plans-header-sec">
        {plan.name}
      </div>
      <div className="onboarding-subscription-plans-body-sec">
        <div className="onboarding-subscription-plans-amount-sec">
          <h3>
            Custom Plan
            {/* <span>/ {type == "monthly" ? "Month" : "Year"}</span> */}
          </h3>
          {type == "yearly" && plan.yearly_save_note_percentage && (
            <p>{plan.yearly_save_note_percentage}</p>
          )}
        </div>
        <div className="onboarding-subscription-plans-choose-btn-sec">
          <Button
            className="onboarding-subscription-free-plans-btn"
            disabled={props.buttonDisable || requestEnterprisePlan.buttonDisable || Object.keys(requestEnterprisePlan.data).length > 0}
            onClick={(e) =>
              dispatch(
                requestEnterprisePlanStart({
                  onboarding: props.onboarding,
                })
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="17"
              fill="none"
              viewBox="0 0 21 17"
            >
              <path
                fill="#fff"
                d="M1.45 12.333L.197 4.178a.68.68 0 011.08-.648l3.91 2.932a.934.934 0 001.337-.23l3.254-4.88a.869.869 0 011.446 0l3.254 4.88a.934.934 0 001.338.23l3.908-2.932a.68.68 0 011.081.648l-1.255 8.155H1.451zM18.605 16.857H2.394a.944.944 0 01-.944-.943V13.84H19.55v2.073a.944.944 0 01-.944.943z"
              ></path>
            </svg>
            {(Object.keys(requestEnterprisePlan.data).length > 0 || props.buttonDisable) ? "Requested" : requestEnterprisePlan.buttonDisable
              ? t("plans.loading")
              : "Request Enterprise"}
          </Button>
        </div>
        <div className="onboarding-subscription-plans-info-list-sec">
          <ul className="list-unstyled onboarding-subscription-plans-info-list">
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  fill="#fff"
                />
                <path
                  d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                  fill="#03363d"
                  stroke="#03363d"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  stroke="#fff"
                />
              </svg>
              <span>
                1000+ Messages
              </span>
            </li>
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  fill="#fff"
                />
                <path
                  d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                  fill="#03363d"
                  stroke="#03363d"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  stroke="#fff"
                />
              </svg>
              <span>100+ Custom Training</span>
            </li>
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  fill="#fff"
                />
                <path
                  d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                  fill="#03363d"
                  stroke="#03363d"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  stroke="#fff"
                />
              </svg>
              <span>10+ Bots</span>
            </li>
            <li>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  fill="#fff"
                />
                <path
                  d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                  fill="#03363d"
                  stroke="#03363d"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22.76"
                  height="22.3251"
                  rx="11.1625"
                  stroke="#fff"
                />
              </svg>
              <span>24/7 Support</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseSubscriptionCard;
