import React, { useEffect, useState } from "react";
import {
  Nav,
  Row,
  Col,
  Tab,
  Table,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { delayMessageDeleteStart } from "../../store/slices/FineTuneSlice";

const DeleteMessageModal = (props) => {
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);

  const delayMessageDelete = useSelector(
    (state) => state.fineTune.delayMessageDelete
  );

  useEffect(() => {
    if (
      !skipRender &&
      !delayMessageDelete.loading &&
      Object.keys(delayMessageDelete.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [delayMessageDelete]);

  return (
    <>
      {/* <Modal
        show={props.modalShow}
        onHide={props.onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-dialog-center import-webpage-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Delay Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Button
            className="modal-close"
            onClick={() => props.closeDeleteBotModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
            </Button>
          <h6 className="mb-3">Are you sure you want to delete this delay message?</h6>
          <div className="onboarding-import-webpage-modal-btn-sec">
            <Button
              onClick={props.onHide}
              disabled={delayMessageDelete.buttonDisable}
              className="onboarding-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              disabled={delayMessageDelete.buttonDisable}
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(
                  delayMessageDeleteStart({
                    delay_message_id: props.modalShow.id,
                  })
                )
              }
            >
              {delayMessageDelete.buttonDisable ? "Loading" : "Delete"}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal> */}
      <Modal
        className="modal-dialog-center import-webpage-modal delay-text-modal"  
        size="md"
        centered
        show={props.modalShow}
        onHide={props.onHide}
      >
        <Modal.Body>
          <h4>Delete Delay Message</h4>
          <Button
            className="modal-close"
            onClick={props.onHide}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <div className="delete-bot-card">
            <p>Do you really want to delete this delay message?</p>
            <p>All your data will be deleted and cannot be recovered.</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={props.onHide}
            >
              Cancel{" "}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(delayMessageDeleteStart({ delay_message_id : props.modalShow.id }))
              }
              disabled={delayMessageDelete.buttonDisable}
            >
              {delayMessageDelete.buttonDisable ? "Loading" : "Delete"}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteMessageModal;
