import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import ImportWebpageModal from "./ImportWebpageModal";
import ImportDocumentModal from "./ImportDocumentModal";
import { useDispatch, useSelector } from "react-redux";
import { onboardingCreatedbotStart } from "../../store/slices/OnboardingSlice";
import OnboardingStepsContent from "./OnboardingStepsContent";
import NavLogo from "../Layout/Header/NavLogo";
import { useTranslation, withTranslation } from "react-multi-lang";

const FineTuningIndex = (props) => {

  const t = useTranslation("onboarding_fine_tuning")
  const dispatch = useDispatch();
  const onboardingBot = useSelector((state) => state.onboarding.onboardingbot);
  const [importWebpage, setImportWebpage] = useState(false);

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const [importDocument, setImportDocument] = useState(false);

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  useEffect(() => {
    dispatch(onboardingCreatedbotStart());
  }, []);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-body-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-fine-tuning-box">
                    <div
                      className="onboarding-fine-tuning-card"
                      onClick={() => setImportWebpage(true)}
                    >
                      <div className="onboarding-fine-tuning-img-sec">
                        <Image
                          className="onboarding-fine-tuning-img"
                          src={
                            window.location.origin +
                            "/images/onboarding/fine-tuning/import-from-web-icon.svg"
                          }
                        />
                      </div>
                      <div className="onboarding-fine-tuning-info">
                        <h4>{t("web.heading")}</h4>
                        <p>{t("web.message")}</p>
                      </div>
                    </div>
                    <div
                      className="onboarding-fine-tuning-card"
                      onClick={() => setImportDocument(true)}
                    >
                      <div className="onboarding-fine-tuning-img-sec">
                        <Image
                          className="onboarding-fine-tuning-img"
                          src={
                            window.location.origin +
                            "/images/onboarding/fine-tuning/import-from-doc-icon.svg"
                          }
                        />
                      </div>
                      <div className="onboarding-fine-tuning-info">
                        <h4>{t("doc.heading")}</h4>
                        <p>{t("doc.message")}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="onboarding-footer-sec">
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="onboarding-on-premise-btn-sec">
                    <Button
                      className="onboarding-back-btn"
                      onClick={() => props.setStep(2)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#03363d"
                          d="M20 11H6.83l2.88-2.88A.997.997 0 008.3 6.71L3.71 11.3a.996.996 0 000 1.41L8.3 17.3a.997.997 0 101.41-1.41L6.83 13H20c.55 0 1-.45 1-1s-.45-1-1-1z"
                        ></path>
                      </svg>
                      {t("back")}
                    </Button>
                    <Button
                      className="onboarding-btn"
                      onClick={() => props.setStep(4)}
                    >
                      {t("next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <OnboardingStepsContent step={2} />
      </div>
      {!onboardingBot.loading && (
        <>
          {importWebpage && (
            <ImportWebpageModal
              importWebpage={importWebpage}
              closeImportWebpageModal={closeImportWebpageModal}
              setImportWebpage={setImportWebpage}
              onboardingBot={onboardingBot.data}
              onboarding={1}
              step={props.step}
              setStep={props.setStep}
            />
          )}
          {importDocument && (
            <ImportDocumentModal
              importDocument={importDocument}
              closeImportDocumentModal={closeImportDocumentModal}
              setImportDocument={setImportDocument}
              onboardingBot={onboardingBot.data}
              onboarding={1}
              step={props.step}
              setStep={props.setStep}
            />
          )}
        </>
      )}
    </>
  );
};

export default withTranslation(FineTuningIndex);
