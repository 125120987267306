import React, { useEffect, useState } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./OnBoarding.css";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { loginStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import OnBoardingVerificationModal from "./OnboardingVerificationModal";
import NavLogo from "../Layout/Header/NavLogo";
import TFALoginModal from "./TFALoginModal";
import AuthenticationRightSection from "./AuthenticationRightSection";

const AIasLoginIndex = (props) => {
  const t = useTranslation("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector((state) => state.admin.login);
  const emailVerify = useSelector(state => state.admin.emailVerify);
  const [skipRender, setSkipRender] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [twoStep, setTwoStep] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
  });

  const handleLogin = (values) => {
    dispatch(loginStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !login.loading &&
      Object.keys(login.data).length > 0
    ) {
      if (login.data.email_status) {
        if(login.data.onboarding_step === 6)
        {
          if(login.data.tfa_status==0)
          navigate("/")
          else
          setTwoStep(login.data)
        }
        else if(login.data.onboarding_step === 5)
          navigate("/onboarding-subscription-plans");
        else
          navigate("/onboarding-steps");
      } else {
        setShowVerify(login.data)
      }
    }
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      navigate('/onboarding-steps')
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="onboarding-box">
        <div className="onboarding-left-sec">
          <div className="onboarding-logo-sec">
            <NavLogo/>
          </div>
          <div className="onboarding-steps-card">
            <div className="onboarding-header-sec">
              <Row className="justify-content-md-center">
                <Col md={10}>
                  <div className="onboarding-onpremise-title-sec">
                    <h2>{t("heading")}</h2>
                    <p>
                    {t("message")}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Formik
              initialValues={{
                email: "",
                password: "",
                remember: false,
              }}
              validationSchema={loginSchema}
              onSubmit={handleLogin}
            >
              {({ setFieldValue, values }) => (
                <FORM noValidate className="onboarding-form">
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            {t("email.label")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="email"
                            placeholder={t("email.placeholder")}
                            name="email"
                          /> 
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="errorMsg"
                          />
                        </Form.Group>
                        <Form.Label>
                        {t("password.label")} <span>*</span>
                        </Form.Label>
                        <div className="mb-3">
                          <InputGroup className="register-input-group">
                            <Field
                              type={showPassword.password ? "text" : "password"}
                              className="form-control"
                              placeholder={t("password.placeholder")}
                              name="password"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  password: !showPassword.password,
                                })
                              }
                            >
                              <Image
                                className="register-input-group-eye-icon"
                                src={
                                  showPassword.password
                                    ? window.location.origin +
                                      "/images/onboarding/eye-visible-off-icon.svg"
                                    : window.location.origin +
                                      "/images/onboarding/eye-visible-on-icon.svg"
                                }
                              />
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="errorMsg"
                          />
                        </div>
                        <div className="onbaording-link-sec-1">
                          {/* Forgot Password? */}
                          <Link to="/forgot-password">{t("forgot_password")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="onboarding-footer-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="ai-as-service-btn-sec">
                          <Button
                            className="onboarding-btn"
                            type="submit"
                            disabled={login.buttonDisable}
                          >
                            {login.buttonDisable ? t("login_btn.loading") : t("login_btn.text")}
                          </Button>
                        </div>
                        <div className="onbaording-link-sec">
                          {t("no_account")}
                          <Link to="/ai-as-service">{t("register")}</Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </div>
        <AuthenticationRightSection/>
      </div>
      {showVerify ? (
        <OnBoardingVerificationModal
          showVerify={showVerify}
          closeVerify={() => setShowVerify(false)}
        />
      ) : null}
      {twoStep && 
        <TFALoginModal
          showVerify={twoStep}
          closeVerify={()=> setTwoStep(false)}
        />
      }
    </>
  );
};

export default AIasLoginIndex;
