import axios from "axios";
const useBot = () => {

  const widgets = [
    {
      label: "FreshChat",
      value: 1,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-freshchat.png"
    },
    // {
    //   label: "FreshDesk",
    //   value: 6,
    //   picture: window.location.origin + "/images/onboarding/choose-solution/logo-freshdesk.png"
    // },
    {
      label: "ZenDesk",
      value: 3,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-zendesk.png"
    },
    // {
    //   label: "Salesforce",
    //   value: 5,
    //   picture: window.location.origin + "/images/onboarding/choose-solution/logo-salesforce.png"
    // },
    {
      label: "LiveChat",
      value: 4,
      picture: window.location.origin + "/images/onboarding/choose-solution/logo-live-chat.png"
    }
  ];

  const promptOptions = [
    { label: "Customer Support Representative", value: "Customer Support Representative" },
    { label: "Technical Support Specialist", value: "Technical Support Specialist" },
    { label: "Sales Consultant", value: "Sales Consultant" },
    { label: "Human Resources Assistant", value: "Human Resources Assistant" },
    { label: "Financial Advisor", value: "Financial Advisor" },
    { label: "Travel Agent", value: "Travel Agent" },
    { label: "Legal Consultant", value: "Legal Consultant" },
    { label: "Educational Advisor", value: "Educational Advisor" },
    { label: "Real Estate Agent", value: "Real Estate Agent" },
    { label: "Insurance Advisor", value: "Insurance Advisor" },
    { label: "Healthcare Advisor", value: "Healthcare Advisor" },
    { label: "Others", value: "Others" }
  ];
  
 
  const handlePrompt = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_PROMPT_URL}/api/v1/generate_prompt`,data);
    return response.data;
  };

  return {
    widgets,
    promptOptions,
    handlePrompt
  };
};

export default useBot;
