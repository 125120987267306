import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  faqDeleteStart,
  faqListStart,
  moreFaqListStart,
  faqTrainStart,
} from "../../../store/slices/FaqSlice";
import {
  fineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import AddFaqModal from "./AddFaqModal";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteFaqModal from "./DeleteFaqModal";

const FaqIndex = () => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const faqList = useSelector((state) => state.faq.faqList);
  const faqDelete = useSelector((state) => state.faq.faqDelete);
  const faqTrain = useSelector((state) => state.faq.faqTrain);
  const [addFaq, setAddFaq] = useState(false);
  const [faq, setFaq] = useState({});
  const [deleteFaq, setDeleteFaq] = useState(false);

  const handleAddFaq = (message) => {
    setFaq({ ...faq, question: message.question, answer: message.response, status: message.status, chat_message_faq_id: message.chat_message_faq_id });
    setAddFaq(true);
  };

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const closeDeleteFaqModal = () => {
    setDeleteFaq(false);
  };

  useEffect(() => {
    dispatch(faqListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 }));
    dispatch(
      fineTuneListStart({ bot_id: selectedBot.bot_id })
    );
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreFaqListStart({
        bot_id: selectedBot.bot_id,
        skip: faqList.data.chat_message_faqs.length,
        take: 12,
      })
    );
  };

  const handleTrain = () => {
    dispatch(faqTrainStart({ bot_id: selectedBot.bot_id }));
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <>
      <div className="new-finetune-faq-table-sec">
        <div className="new-finetune-faq-table-box">
          {faqList.loading ? (
            <div className="onboarding-fine-tunings-files-box">
              {[...Array(4)].map((i, key) => (
                <Skeleton height={80} key={key} />
              ))}
            </div>
          ) : Object.keys(faqList.data).length > 0 &&
            faqList.data.chat_message_faqs &&
            faqList.data.chat_message_faqs.length > 0 ? (
            <>
              {faqList.data.chat_message_faqs.find(
                (faq) => faq.training_status == 0
              ) && (
                <div className="admin-ui-dashboard-graph-header-sec">
                  <p className="notes">Total FAQs - {faqList.data.total_faqs}</p>
                  <Button
                    type="button"
                    className="onboarding-add-fine-tuning-btn btn btn-primary"
                    onClick={() => handleTrain()}
                    disabled={faqTrain.buttonDisable}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#fff"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16 0H8a5.006 5.006 0 00-5 5v18a1 1 0 001.564.825l2.106-1.439 2.106 1.439a1 1 0 001.13 0l2.1-1.439 2.1 1.439a1 1 0 001.131 0l2.1-1.438 2.1 1.437A1 1 0 0021 23V5a5.006 5.006 0 00-5-5zm3 21.1l-1.1-.752a1 1 0 00-1.132 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.131 0l-2.1 1.439-2.1-1.439a1 1 0 00-1.129 0L5 21.1V5a3 3 0 013-3h8a3 3 0 013 3z"></path>
                      <rect width="10" height="2" x="7" y="8" rx="1"></rect>
                      <rect width="8" height="2" x="7" y="12" rx="1"></rect>
                    </svg> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 12 17"
                    >
                      <path
                        fill="#fff"
                        d="M3.333.5A3.337 3.337 0 000 3.833v12a.667.667 0 001.043.55l1.404-.96 1.404.96a.667.667 0 00.753 0l1.4-.96 1.4.96a.667.667 0 00.754 0l1.4-.958 1.4.957A.666.666 0 0012 15.834v-12A3.337 3.337 0 008.667.5H3.333zm4 9.333h-4a.667.667 0 110-1.333h4a.667.667 0 110 1.333zm2-3.333a.667.667 0 01-.666.667H3.333a.667.667 0 010-1.334h5.334a.667.667 0 01.666.667z"
                      ></path>
                    </svg>
                    {faqTrain.buttonDisable ? "Loading" : "Train"}
                  </Button>
                </div>
              )}
              <InfiniteScroll
                dataLength={faqList.data.chat_message_faqs.length}
                next={fetchMoreData}
                hasMore={
                  faqList.data.chat_message_faqs.length <
                  faqList.data.total_chat_message_faqs
                }
                loader={[...Array(3)].map((i) => (
                  <Skeleton height={80} className="mb-3" />
                ))}
              >
                <div className="new-settings-finetune-table-sec pt-0">
                  <Table responsive="md">
                    {faqList.data.chat_message_faqs.map((faq, index) => (
                      <tbody key={index}>
                        {faq.file.length > 0 ? (
                          <tr>
                            <div className="new-finetune-faq-mesage">
                              <h4 className="question-text">{faq.batch_id.slice(0,5)}...{faq.batch_id.slice(-5)}</h4>
                            </div>
                            <div className="new-finetune-faq-btn-sec">
                              <div className={getStatusName(faq.training_status)}>
                                {faq.training_status_formatted}
                              </div>
                              <div className="new-finetune-faq-action-btn-sec">
                                <div className="recent-chat-menu-btn">
                                  {faqDelete.buttonDisable &&
                                  faqDelete.data.chat_message_faq_id == faq.chat_message_faq_id ? (
                                    <Image
                                      className="btn-loader"
                                      src={
                                        window.location.origin +
                                        "/images/loader/btn-loader.gif"
                                      }
                                    />
                                  ) : (
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <div className="recent-chat-menu-icon-sec">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            x="0"
                                            y="0"
                                            enableBackground="new 0 0 512 512"
                                            version="1.1"
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                          >
                                            <circle
                                              cx="458.667"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                            <circle
                                              cx="256"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                            <circle
                                              cx="53.333"
                                              cy="256"
                                              r="53.333"
                                            ></circle>
                                          </svg>
                                        </div>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href={faq.file}
                                          target="_blank"
                                        >
                                          <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="#546594"
                                              x="0"
                                              y="0"
                                              enableBackground="new 0 0 512.19 512.19"
                                              version="1.1"
                                              viewBox="0 0 512.19 512.19"
                                              xmlSpace="preserve"
                                            >
                                              <circle cx="256.095" cy="256.095" r="85.333"></circle>
                                              <path d="M496.543 201.034C463.455 147.146 388.191 56.735 256.095 56.735S48.735 147.146 15.647 201.034c-20.862 33.743-20.862 76.379 0 110.123 33.088 53.888 108.352 144.299 240.448 144.299s207.36-90.411 240.448-144.299c20.862-33.744 20.862-76.38 0-110.123zM256.095 384.095c-70.692 0-128-57.308-128-128s57.308-128 128-128 128 57.308 128 128c-.071 70.663-57.337 127.929-128 128z"></path>
                                          </svg>
                                          View
                                        </Dropdown.Item>
                                        {faq.training_status != 1 && (
                                          <Dropdown.Item
                                            disabled={faqDelete.buttonDisable}
                                            onClick={() => setDeleteFaq(faq.chat_message_faq_id)}
                                          >
                                            {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              fill="#546594"
                                              height="20"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                                            </svg> */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                fill="#546594"
                                                height="20"
                                                x="0"
                                                y="0"
                                                enableBackground="new 0 0 512 512"
                                                version="1.1"
                                                viewBox="0 0 512 512"
                                                xmlSpace="preserve"
                                              >
                                              <path d="M448 85.333h-66.133C371.66 35.703 328.002.064 277.333 0h-42.667c-50.669.064-94.327 35.703-104.533 85.333H64c-11.782 0-21.333 9.551-21.333 21.333S52.218 128 64 128h21.333v277.333C85.404 464.214 133.119 511.93 192 512h128c58.881-.07 106.596-47.786 106.667-106.667V128H448c11.782 0 21.333-9.551 21.333-21.333S459.782 85.333 448 85.333zM234.667 362.667c0 11.782-9.551 21.333-21.333 21.333-11.783 0-21.334-9.551-21.334-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zm85.333 0c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zM174.315 85.333c9.074-25.551 33.238-42.634 60.352-42.667h42.667c27.114.033 51.278 17.116 60.352 42.667H174.315z"></path>
                                            </svg>
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  )}
                                </div>
                              </div>
                            </div>
                          </tr>
                        ) : (
                          <tr>
                            <div className="new-finetune-faq-mesage">
                              <h4 className="question-text">{faq.question}</h4>
                              <p className="answer-text">{faq.response}</p>
                            </div>
                            <div className="new-finetune-faq-btn-sec">
                              <div className={getStatusName(faq.training_status)}>
                                {faq.training_status_formatted}
                              </div>
                              <div className="new-finetune-faq-action-btn-sec">
                                {/* <div className="trained-badge">Trained</div> */}
                                {faq.training_status != 1 ? (
                                  <div className="recent-chat-menu-btn">
                                    {faqDelete.buttonDisable &&
                                    faqDelete.data.chat_message_faq_id ==
                                      faq.chat_message_faq_id ? (
                                      <Image
                                        className="btn-loader"
                                        src={
                                          window.location.origin +
                                          "/images/loader/btn-loader.gif"
                                        }
                                      />
                                    ) : (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                        >
                                          <div className="recent-chat-menu-icon-sec">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              x="0"
                                              y="0"
                                              enableBackground="new 0 0 512 512"
                                              version="1.1"
                                              viewBox="0 0 512 512"
                                              xmlSpace="preserve"
                                            >
                                              <circle
                                                cx="458.667"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                              <circle
                                                cx="256"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                              <circle
                                                cx="53.333"
                                                cy="256"
                                                r="53.333"
                                              ></circle>
                                            </svg>
                                          </div>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {faq.training_status != 2 && (
                                            <Dropdown.Item
                                              disabled={faqDelete.buttonDisable}
                                              onClick={() => handleAddFaq(faq)}
                                            >
                                              {/* <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                fill="#048A89"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1.379-15.621l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914c1.17-1.17 1.17-3.072 0-4.242s-3.072-1.17-4.242 0zm-3.086 8.742A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414-3.457 3.457zm5.914-5.914l-1.043 1.043-1.414-1.414 1.043-1.043a.999.999 0 111.414 1.414z"></path>
                                              </svg> */}
                                              <svg
                                                width="20"
                                                height="20"
                                                fill="#048A89"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M13.75 11.664l-3.457 3.457A3.02 3.02 0 018.172 16H8v-.172c0-.789.32-1.562.879-2.121l3.457-3.457 1.414 1.414zm1.043-3.871L13.75 8.836l1.414 1.414 1.043-1.043a1 1 0 00-1.414-1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-6.379-5.621a3.002 3.002 0 00-4.242 0l-5.914 5.914A4.968 4.968 0 006 15.828V17a1 1 0 001 1h1.172a4.968 4.968 0 003.535-1.465l5.914-5.914a3.002 3.002 0 000-4.242z"></path>
                                              </svg>
                                              Edit
                                            </Dropdown.Item>
                                          )}
                                          <Dropdown.Item
                                            disabled={faqDelete.buttonDisable}
                                            onClick={() => setDeleteFaq(faq.chat_message_faq_id)}
                                          >
                                            {/* <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              fill="#546594"
                                              height="20"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M21 4h-3.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H3a1 1 0 000 2h1v13a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5V6h1a1 1 0 000-2zM11 2h2a3.006 3.006 0 012.829 2H8.171A3.006 3.006 0 0111 2zm7 17a3 3 0 01-3 3H9a3 3 0 01-3-3V6h12z"></path>
                                              <path d="M10 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1zM14 18a1 1 0 001-1v-6a1 1 0 00-2 0v6a1 1 0 001 1z"></path>
                                            </svg> */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                fill="#546594"
                                                height="20"
                                                x="0"
                                                y="0"
                                                enableBackground="new 0 0 512 512"
                                                version="1.1"
                                                viewBox="0 0 512 512"
                                                xmlSpace="preserve"
                                              >
                                              <path d="M448 85.333h-66.133C371.66 35.703 328.002.064 277.333 0h-42.667c-50.669.064-94.327 35.703-104.533 85.333H64c-11.782 0-21.333 9.551-21.333 21.333S52.218 128 64 128h21.333v277.333C85.404 464.214 133.119 511.93 192 512h128c58.881-.07 106.596-47.786 106.667-106.667V128H448c11.782 0 21.333-9.551 21.333-21.333S459.782 85.333 448 85.333zM234.667 362.667c0 11.782-9.551 21.333-21.333 21.333-11.783 0-21.334-9.551-21.334-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zm85.333 0c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zM174.315 85.333c9.074-25.551 33.238-42.634 60.352-42.667h42.667c27.114.033 51.278 17.116 60.352 42.667H174.315z"></path>
                                            </svg>
                                            {faqDelete.buttonDisable
                                              ? "Loading"
                                              : "Delete"}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </tr>
                        )}
                      </tbody>
                    ))}
                  </Table>
                </div>
              </InfiniteScroll>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
          faq={faq}
          type={2}
        />
      )}
      {deleteFaq && (
        <DeleteFaqModal
          deleteFaq={deleteFaq}
          closeDeleteFaqModal={closeDeleteFaqModal}
          setDeleteBot={setDeleteFaq}
        />
      )}
    </>
  );
};

export default FaqIndex;
