import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import AddFaqModal from "../Faqs/AddFaqModal";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import ImportWebpageModal from "../../OnBoarding/ImportWebpageModal";
import ImportDocumentModal from "../../OnBoarding/ImportDocumentModal";
import Skeleton from "react-loading-skeleton";
import FaqIndex from "../Faqs/FaqIndex";
import FinetuneIndex from "./FinetuneIndex";
import ConversationsIndex from "../ConversationData/ConversationsIndex"
import ImportConversationsModal from "../ConversationData/ImportConversationsModal"
import CustomTrainingIndex from "./CustomTrainingIndex";
import {
  customTrainingListStart,
} from "../../../store/slices/FineTuneSlice";

const SettingsfineTune = (props) => {
  const dispatch = useDispatch();

  const t = useTranslation("settings.fine_tune");
  const [addFaq, setAddFaq] = useState(false);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const fineTuneList = useSelector((state) => state.fineTune.fineTuneList);
  const customTrainingList = useSelector((state) => state.fineTune.customTrainingList);
  const [importWebpage, setImportWebpage] = useState(false);
  const [importDocument, setImportDocument] = useState(false);
  const [importConversations, setImportConversations] = useState(false);
  const [tab, setTab] = useState("docs");

  const closeAddFaqModal = () => {
    setAddFaq(false);
  };

  const closeImportWebpageModal = () => {
    setImportWebpage(false);
  };

  const closeImportDocumentModal = () => {
    setImportDocument(false);
  };

  const closeImportConversationsModal = () => {
    setImportConversations(false);
  };

  useEffect(() => {
    dispatch(
      customTrainingListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 })
    );
  }, [selectedBot]);

  return (
    <>
      <div className="new-settings-finetune-sec">
        <div className="new-settings-finetune-header-sec">
          <div className="new-settings-finetune-info-sec">
            <h3>{t("heading")}</h3>
          </div>
          <div className="new-settings-finetune-header-box">
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportWebpage(true)}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                viewBox="0 0 24 24"
              >
                <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0zm8.647 7h-3.221a19.676 19.676 0 00-2.821-4.644A10.031 10.031 0 0120.647 7zM16.5 12a10.211 10.211 0 01-.476 3H7.976a10.211 10.211 0 01-.476-3 10.211 10.211 0 01.476-3h8.048a10.211 10.211 0 01.476 3zm-7.722 5h6.444A19.614 19.614 0 0112 21.588 19.57 19.57 0 018.778 17zm0-10A19.614 19.614 0 0112 2.412 19.57 19.57 0 0115.222 7zM9.4 2.356A19.676 19.676 0 006.574 7H3.353A10.031 10.031 0 019.4 2.356zM2.461 9H5.9a12.016 12.016 0 00-.4 3 12.016 12.016 0 00.4 3H2.461a9.992 9.992 0 010-6zm.892 8h3.221A19.676 19.676 0 009.4 21.644 10.031 10.031 0 013.353 17zm11.252 4.644A19.676 19.676 0 0017.426 17h3.221a10.031 10.031 0 01-6.042 4.644zM21.539 15H18.1a12.016 12.016 0 00.4-3 12.016 12.016 0 00-.4-3h3.437a9.992 9.992 0 010 6z"></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 26 26"
              >
                <path
                  fill="#03363D"
                  d="M17.55 7.93c-.89-4.141-2.792-6.761-4.55-6.761-1.76 0-3.66 2.62-4.552 6.76h9.103zM7.93 13c-.001 1.13.074 2.26.225 3.38h9.69a25.375 25.375 0 000-6.76h-9.69c-.15 1.12-.226 2.25-.226 3.38zM8.448 18.07c.891 4.141 2.793 6.761 4.551 6.761 1.759 0 3.66-2.62 4.552-6.76H8.448zM19.282 7.93h5.324a12.726 12.726 0 00-8.83-7.29c1.603 1.41 2.86 4.018 3.506 7.29zM25.213 9.62h-5.661a27.34 27.34 0 01-.001 6.76h5.662c.616-2.211.617-4.55 0-6.76zM15.776 25.36a12.726 12.726 0 008.831-7.29h-5.324c-.647 3.272-1.904 5.88-3.507 7.29zM6.717 18.07H1.394a12.725 12.725 0 008.831 7.29c-1.605-1.41-2.862-4.018-3.508-7.29zM10.224.64a12.725 12.725 0 00-8.832 7.29h5.324c.647-3.272 1.905-5.88 3.508-7.29zM6.24 13c0-1.13.07-2.26.209-3.38H.787a12.591 12.591 0 000 6.76H6.45c-.14-1.121-.21-2.25-.21-3.38z"
                ></path>
              </svg>
              <h4>{t("import.web")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportDocument(true)}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M17 14a1 1 0 01-1 1H8a1 1 0 010-2h8a1 1 0 011 1zm-4 3H8a1 1 0 000 2h5a1 1 0 000-2zm9-6.515V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515a6.958 6.958 0 014.95 2.05l3.484 3.486A6.951 6.951 0 0122 10.485zm-6.949-7.021A5.01 5.01 0 0014 2.684V7a1 1 0 001 1h4.316a4.983 4.983 0 00-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 01-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3z"></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 22 26"
              >
                <path
                  fill="#03363D"
                  d="M13.1 7.75V.883a7.325 7.325 0 012.588 1.67l3.658 3.66A7.302 7.302 0 0121.017 8.8H14.15a1.05 1.05 0 01-1.05-1.05zm8.4 3.66v8.94a5.256 5.256 0 01-5.25 5.25H5.75A5.256 5.256 0 01.5 20.35V5.65A5.256 5.256 0 015.75.4h4.74c.172 0 .34.014.51.025V7.75a3.15 3.15 0 003.15 3.15h7.325c.011.169.025.338.025.51zm-8.4 8.94a1.05 1.05 0 00-1.05-1.05H6.8a1.05 1.05 0 100 2.1h5.25a1.05 1.05 0 001.05-1.05zm3.15-4.2a1.05 1.05 0 00-1.05-1.05H6.8a1.05 1.05 0 100 2.1h8.4a1.05 1.05 0 001.05-1.05z"
                ></path>
              </svg>
              <h4>{t("import.doc")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setAddFaq(true)}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="#000"
                data-name="Layer 1"
                viewBox="0 0 24 24"
              >
                <path d="M11.904 16a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0111.904 16zm1-2c0-.561.408-1.225.928-1.512a4.021 4.021 0 002.009-4.223c-.283-1.613-1.607-2.938-3.221-3.221a4.034 4.034 0 00-3.289.874 3.995 3.995 0 00-1.428 3.063 1 1 0 102 0 2.002 2.002 0 012.371-1.967c.787.138 1.458.81 1.596 1.596a1.995 1.995 0 01-1.004 2.125c-1.156.637-1.963 1.979-1.963 3.264a1 1 0 102 0zM24 19v-6.66C24 5.861 19.096.454 12.836.028a12.047 12.047 0 00-9.327 3.493A12.024 12.024 0 00.03 12.854C.47 19.208 6.082 24 13.084 24h5.917c2.757 0 5-2.243 5-5zM12.701 2.024C17.916 2.378 22 6.909 22 12.339v6.66c0 1.654-1.346 3-3 3h-5.917c-6.035 0-10.686-3.904-11.059-9.284a10.02 10.02 0 012.899-7.781A10.016 10.016 0 0112.7 2.023z"></path>
              </svg> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 28 27"
              >
                <g clipPath="url(#clip0_1942_10174)">
                  <path
                    fill="#03363D"
                    d="M9.384 18.118a1.687 1.687 0 112.386-2.387c1.491 1.492 4.095 1.493 5.586 0l5.613-5.612a3.955 3.955 0 000-5.587 3.955 3.955 0 00-5.587 0 1.687 1.687 0 11-2.386-2.386c2.855-2.856 7.504-2.856 10.36 0s2.856 7.503 0 10.359l-5.613 5.613a7.301 7.301 0 01-5.18 2.142 7.304 7.304 0 01-5.18-2.142zm-1.557 8.88c1.875 0 3.75-.715 5.18-2.143a1.687 1.687 0 10-2.387-2.386 3.954 3.954 0 01-5.587 0 3.955 3.955 0 010-5.587l5.581-5.58a3.953 3.953 0 015.587 0 1.687 1.687 0 102.386-2.387c-2.856-2.856-7.502-2.856-10.359 0l-5.582 5.581C-.21 17.353-.21 22 2.646 24.856a7.304 7.304 0 005.18 2.141z"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1942_10174">
                    <path fill="#fff" d="M0 0H27V27H0z" transform="translate(.5)"></path>
                  </clipPath>
                </defs>
              </svg>
              <h4>{t("import.faq")}</h4>
            </div>
            <div
              className="new-settings-finetune-header-card"
              onClick={() => setImportConversations(true)}
            >
              {/* <svg
               xmlns="http://www.w3.org/2000/svg"
               width="28"
               height="28"
               fill="#000"
               viewBox="0 0 24 24"
             >
               <g fill="#000">
                 <path d="M21 11H7c-.6 0-1-.4-1-1s.4-1 1-1h11.6l-2.3-2.3c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l4 4c.3.3.4.7.2 1.1-.1.4-.5.6-.9.6zM7 19c-.3 0-.5-.1-.7-.3l-4-4c-.3-.3-.4-.7-.2-1.1s.5-.6.9-.6h14c.6 0 1 .4 1 1s-.4 1-1 1H5.4l2.3 2.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"></path>
               </g>
             </svg> */}
             <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="none"
                viewBox="0 0 24 25"
              >
                <path
                  fill="#03363D"
                  d="M3.868 4.03c4.494-4.679 11.768-4.68 16.263 0 3.614 3.763 4.297 9.358 2.16 13.813l1.185 5.425c.154.705-.453 1.337-1.13 1.177l-5.21-1.233C9.558 27.15.5 21.448.5 12.496c0-3.198 1.196-6.204 3.368-8.466zm3.334 11.448h5.006c.521 0 .944-.44.944-.983 0-.544-.423-.984-.944-.984H7.202c-.522 0-.945.44-.945.984 0 .543.423.983.945.983zm0-3.996h9.595c.522 0 .945-.44.945-.984 0-.543-.423-.983-.945-.983H7.202c-.522 0-.945.44-.945.983 0 .543.423.984.945.984z"
                ></path>
              </svg>
              <h4>Conversational Data</h4>
            </div>
          </div>
        </div>
        <div className="new-settings-finetune-body-sec">
          <Tab.Container id="left-tabs-example"
            defaultActiveKey="docs"
            onSelect={(key, ev) => (ev.type != "click") && setTab(key)}
            >
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  {/* <Nav.Item>
                    <Nav.Link eventKey="all" onClick={() => setTab("all")}>
                      {t("options.all")}
                      <span className="count">{!customTrainingList.loading && Object.keys(customTrainingList.data).length > 0 ? customTrainingList.data.total_custom_training_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                    <Nav.Link eventKey="docs" onClick={() => setTab("docs")}>
                      {t("options.docs")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.docs_fine_tune_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="web-page"
                      onClick={() => setTab("web-page")}
                    >
                      {t("options.web")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.links_fine_tune_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="faqs" onClick={() => setTab("faqs")}>
                      {t("options.faq")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.total_faq_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="conversations" onClick={() => setTab("conversations")}>
                      {t("options.conversations")}
                      <span className="count">{!fineTuneList.loading && Object.keys(fineTuneList.data).length > 0 ? fineTuneList.data.total_conversational_data_count : 0}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  {tab == "all" && (
                    <Tab.Pane eventKey="all">
                      <CustomTrainingIndex />
                    </Tab.Pane>
                  )}
                  {tab == "docs" && (
                    <Tab.Pane eventKey="docs">
                      <FinetuneIndex file_type="docs" />
                    </Tab.Pane>
                  )}
                  {tab == "web-page" && (
                    <Tab.Pane eventKey="web-page">
                      <FinetuneIndex file_type="link" />
                    </Tab.Pane>
                  )}
                  {tab == "faqs" && (
                    <Tab.Pane eventKey="faqs">
                      <FaqIndex />
                    </Tab.Pane>
                  )}
                  {tab == "conversations" && (
                    <Tab.Pane eventKey="conversations">
                      <ConversationsIndex />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </div>
      {addFaq && (
        <AddFaqModal
          addFaq={addFaq}
          closeAddFaqModal={closeAddFaqModal}
          setAddFaq={setAddFaq}
        />
      )}
      {importWebpage && (
        <ImportWebpageModal
          importWebpage={importWebpage}
          closeImportWebpageModal={closeImportWebpageModal}
          setImportWebpage={setImportWebpage}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importDocument && (
        <ImportDocumentModal
          importDocument={importDocument}
          closeImportDocumentModal={closeImportDocumentModal}
          setImportDocument={setImportDocument}
          onboardingBot={selectedBot}
          onboarding={0}
        />
      )}
      {importConversations && (
        <ImportConversationsModal
          importConversations={importConversations}
          closeImportConversationsModal={closeImportConversationsModal}
          setImportConversations={setImportConversations}
          onboardingBot={selectedBot}
        />
      )}
    </>
  );
};

export default withTranslation(SettingsfineTune);
