import React from "react";
import { Image } from "react-bootstrap";

const AuthenticationRightSection = () => {
  return (
    <div className="onboarding-right-sec">
      <div className="onboarding-installation-content">
        <div className="onboarding-ai-as-service-img-sec">
          <Image
            className="onboarding-ai-as-service-img"
            src={
              window.location.origin +
              "/images/onboarding/choose-solution/ai-as-register-img.png"
            }
          />
        </div>
        <h3>
          Leverage our custom services to revolutionize your industry experience
          with our dedicated platform.
        </h3>
        <ul className="list-unstyled onboarding-installation-list">
          <li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                fill="#03363d"
              />
              <path
                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                fill="white"
                stroke="white"
              />
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                stroke="#03363d"
              />
            </svg>
            <span>
              Benefit from smooth customer engagement across multiple
              touchpoints, ensuring consistency and information coherence.
            </span>
          </li>
          <li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                fill="#03363d"
              />
              <path
                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                fill="white"
                stroke="white"
              />
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                stroke="#03363d"
              />
            </svg>
            <span>
              Rely on HaiVE's bespoke, progressive infrastructure developed with
              your field in mind.
            </span>
          </li>
          <li>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                fill="#03363d"
              />
              <path
                d="M10.013 13.9198L7.99173 11.8985C7.76455 11.6713 7.39756 11.6713 7.17038 11.8985C6.94321 12.1256 6.94321 12.4926 7.17038 12.7198L9.60529 15.1547C9.83247 15.3819 10.1995 15.3819 10.4266 15.1547L16.5896 8.99173C16.8168 8.76455 16.8168 8.39756 16.5896 8.17038C16.3624 7.94321 15.9955 7.94321 15.7683 8.17038L10.013 13.9198Z"
                fill="white"
                stroke="white"
              />
              <rect
                x="0.5"
                y="0.5"
                width="22.76"
                height="22.3251"
                rx="11.1625"
                stroke="#03363d"
              />
            </svg>
            <span>
              Utilize HaiVE's smart data analysis to steer your strategic
              choices and strengthen your market position.{" "}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AuthenticationRightSection;
