import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  fineTuneList: getCommonInitialState(),
  fineTuneSave: getCommonInitialState(),
  fineTuneDelete: getCommonInitialState(),
  conversationsList: getCommonInitialState(),
  conversationsSave: getCommonInitialState(),
  conversationsDelete: getCommonInitialState(),
  customTrainingList: getCommonInitialState(),
  delayMessageList: getCommonInitialState(),
  delayMessageSave: getCommonInitialState(),
  delayMessageDelete: getCommonInitialState(),
  showAddModal: false,
};

const FineTuneSlice = createSlice({
  name: "finetune",
  initialState,
  reducers: {

    //Fine Tune list
    fineTuneListStart: (state, action) => { state.fineTuneList = getCommonStart() },
    fineTuneListSuccess: (state, action) => { state.fineTuneList = getCommonSuccess(action.payload) },
    fineTuneListFailure: (state, action) => { state.fineTuneList = getCommonFailure(action.payload) },
    moreFineTuneListStart: (state) => { state.fineTuneList = state.fineTuneList },

    //Fine Tune Save
    fineTuneSaveStart: (state, action) => { state.fineTuneSave = getCommonStart(action.payload) },
    fineTuneSaveSuccess: (state, action) => { state.fineTuneSave = getCommonSuccess(action.payload) },
    fineTuneSaveFailure: (state, action) => { state.fineTuneSave = getCommonFailure(action.payload) },

    //Fine Tune 
    fineTuneDeleteStart: (state, action) => { state.fineTuneDelete = getCommonStart(action.payload) },
    fineTuneDeleteSuccess: (state, action) => { state.fineTuneDelete = getCommonSuccess(action.payload) },
    fineTuneDeleteFailure: (state, action) => { state.fineTuneDelete = getCommonFailure(action.payload) },

    //Conversations list
    conversationsListStart: (state, action) => { state.conversationsList = getCommonStart() },
    conversationsListSuccess: (state, action) => { state.conversationsList = getCommonSuccess(action.payload) },
    conversationsListFailure: (state, action) => { state.conversationsList = getCommonFailure(action.payload) },
    moreConversationsListStart: (state) => { state.conversationsList = state.conversationsList },

    //Conversations Save
    conversationsSaveStart: (state, action) => { state.conversationsSave = getCommonStart(action.payload) },
    conversationsSaveSuccess: (state, action) => { state.conversationsSave = getCommonSuccess(action.payload) },
    conversationsSaveFailure: (state, action) => { state.conversationsSave = getCommonFailure(action.payload) },

    //Conversations Delete
    conversationsDeleteStart: (state, action) => { state.conversationsDelete = getCommonStart(action.payload) },
    conversationsDeleteSuccess: (state, action) => { state.conversationsDelete = getCommonSuccess(action.payload) },
    conversationsDeleteFailure: (state, action) => { state.conversationsDelete = getCommonFailure(action.payload) },

    // Custom Training List
    customTrainingListStart: (state, action) => { state.customTrainingList = getCommonInitialState(action.payload) },
    customTrainingListSuccess: (state, action) => { state.customTrainingList = getCommonSuccess(action.payload) },
    customTrainingListFailure: (state, action) => { state.customTrainingList = getCommonFailure(action.payload) },
    moreCustomTrainingListStart: (state) => { state.customTrainingList = state.customTrainingList },

    setShowAddModal: (state, action) => { state.showAddModal = action.payload },

    // Delay Messages
    delayMessageListStart: (state, action) => { state.delayMessageList = getCommonStart(action.payload) },
    delayMessageListSuccess: (state, action) => { state.delayMessageList = getCommonSuccess(action.payload) },
    delayMessageListFailure: (state, action) => { state.delayMessageList = getCommonFailure(action.payload) },
    moreDelayMessageListStart: (state) => { state.delayMessageList = state.delayMessageList },

    delayMessageSaveStart: (state, action) => { state.delayMessageSave = getCommonStart(action.payload) },
    delayMessageSaveSuccess: (state, action) => { state.delayMessageSave = getCommonSuccess(action.payload) },
    delayMessageSaveFailure: (state, action) => { state.delayMessageSave = getCommonFailure(action.payload) },

    delayMessageDeleteStart: (state, action) => { state.delayMessageDelete = getCommonStart(action.payload) },
    delayMessageDeleteSuccess: (state, action) => { state.delayMessageDelete = getCommonSuccess(action.payload) },
    delayMessageDeleteFailure: (state, action) => { state.delayMessageDelete = getCommonFailure(action.payload) },

  }
});


export const {
  fineTuneListStart,
  fineTuneListSuccess,
  fineTuneListFailure,
  moreFineTuneListStart,
  fineTuneSaveStart,
  fineTuneSaveSuccess,
  fineTuneSaveFailure,
  fineTuneDeleteStart,
  fineTuneDeleteSuccess,
  fineTuneDeleteFailure,
  setShowAddModal,
  conversationsDeleteFailure,
  conversationsDeleteStart,
  conversationsDeleteSuccess,
  conversationsListFailure,
  conversationsListStart,
  conversationsListSuccess,
  moreConversationsListStart,
  conversationsSaveFailure,
  conversationsSaveStart,
  conversationsSaveSuccess,
  customTrainingListFailure,
  customTrainingListStart,
  customTrainingListSuccess,
  moreCustomTrainingListStart,
  delayMessageDeleteFailure,
  delayMessageDeleteStart,
  delayMessageDeleteSuccess,
  delayMessageListFailure,
  delayMessageListStart,
  delayMessageListSuccess,
  moreDelayMessageListStart,
  delayMessageSaveFailure,
  delayMessageSaveStart,
  delayMessageSaveSuccess
  
} = FineTuneSlice.actions;

export default FineTuneSlice.reducer;