import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  Row,
  Col,
  Button,
  ProgressBar,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import DashBoardBotCard from "./DashBoardBotCard";
import DashBoardRecentChatIndex from "./DashBoardRecentChatIndex";
import DashboardTicketsChart from "./ChatsGraph";
import { dashboardGraphStart, dashboardGraphUpdate, dashboardRecentChatsStart, dashboardStart } from "../../store/slices/DashboardSlice";
import ChatsGraph from "./ChatsGraph";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation, withTranslation } from "react-multi-lang";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import TableLoader from "../Helper/TableLoader";
import DashboardLoader from "../Helper/DashboardLoader";

const DashBoardIndex = (props) => {
  
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "100%" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#048A8912!important",
      border: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "150px",
      cursor:"pointer",
      fontSize: "0.9em",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#048A89",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#4b4b4b!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#4b4b4b",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const dispatch = useDispatch();
  const t = useTranslation("dashboard");
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dashboardGraph = useSelector((state) => state.dashboard.dashboardGraph);
  const dashboardRecentChats = useSelector((state) => state.dashboard.dashboardRecentChats);
  const profile = useSelector((state) => state.admin.profile);
  const [fineTuningFilter, setFineTuningFilter] = useState(7);
  const [chatFilter, setChatFilter] = useState(7);

  const options = [
    { value: 7, label: t("last_7_days") },
    { value: 30, label: t("last_month") },
    // { value: 365, label: 'Last Year' },
  ];
  
  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      dispatch(dashboardGraphStart());
      dispatch(dashboardRecentChatsStart());
    }
  }, [profile]);

  const handleFineTuningFilter = (selectedOption) => {
    setFineTuningFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: selectedOption.value,
        chats_days_count: chatFilter,
      })
    );
  }

  const handleChatFilter = (selectedOption) => {
    setChatFilter(selectedOption.value);
    dispatch(
      dashboardGraphUpdate({
        fine_tuning_days_count: fineTuningFilter,
        chats_days_count: selectedOption.value,
      })
    );
  }
  
  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-dashboard-sec">
          <div className="admin-ui-dashboard-header-sec">
            <Row>
              {dashboard.loading ? <DashboardLoader /> : Object.keys(dashboard.data).length > 0 &&
                Object.keys(dashboard.data.current_plan).length > 0 ? (
                <>
                  <Col md={12} lg={12} xl={6} className="resp-mrg-btm-xl">
                    <div className="admin-ui-dashboard-header-left-sec">
                      <div className="admin-ui-dashboard-header-main-card">
                        <div className="admin-ui-dashboard-header-main-info">
                          <h6>{t("current_plan")}</h6>
                          <h3>
                            {dashboard.data.current_plan.amount}{" "}
                            <span>
                              ({dashboard.data.current_plan.type_formatted})
                            </span>
                          </h3>
                          <p>
                            <span>{t("expiry")}</span> :{" "}
                            {dashboard.data.current_plan.expiry_date}{" "}
                          </p>
                          <Link to="/subscription" className="white-btn">
                            {t("upgrade")}
                          </Link>
                        </div>
                        <div className="admin-ui-dashboard-header-main-icon-sec">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#03363D"
                              d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12h12V12c0-6.617-5.383-12-12-12zm-2 10c0 .378.271.698.644.76l3.041.507A2.761 2.761 0 0116 14c0 1.654-1.346 3-3 3v2h-2v-2c-1.654 0-3-1.346-3-3h2c0 .551.448 1 1 1h2c.552 0 1-.449 1-1a.768.768 0 00-.644-.76l-3.041-.507A2.761 2.761 0 018 10c0-1.654 1.346-3 3-3V5h2v2c1.654 0 3 1.346 3 3h-2c0-.551-.448-1-1-1h-2c-.552 0-1 .449-1 1z"
                            ></path>
                          </svg>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="none"
                            viewBox="0 0 25 24"
                          >
                            <path
                              fill="#03363D"
                              d="M12.201.002c-6.617 0-12 5.383-12 12s5.383 12 12 12h12v-12c0-6.617-5.383-12-12-12z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M11.375 14.333h-.547l3.825 3.54c.457.427.12 1.127-.54 1.127a.79.79 0 01-.54-.207L9.05 14.607a.879.879 0 01-.3-.654c0-.526.48-.953 1.072-.953h1.553c1.32 0 2.415-.867 2.595-2H8.75c-.412 0-.75-.3-.75-.667 0-.366.338-.666.75-.666h4.995c-.42-.787-1.32-1.334-2.37-1.334H8.75c-.412 0-.75-.3-.75-.666C8 7.3 8.338 7 8.75 7h7.5c.413 0 .75.3.75.667 0 .366-.337.666-.75.666h-1.695c.36.387.63.84.787 1.334h.908c.413 0 .75.3.75.666 0 .367-.337.667-.75.667h-.765c-.195 1.867-1.965 3.333-4.11 3.333z"
                            ></path>
                          </svg> */}
                        </div>
                      </div>
                      <div className="admin-ui-dashboard-header-left-main-box">
                        <div className="admin-ui-dashboard-header-left-main-card">
                          <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                            <p>{t("today_chats")}</p>
                            <h3>{dashboard.data.today_chats}</h3>
                          </div>
                          {/* <div className="admin-ui-dashboard-header-left-main-box-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="none"
                              viewBox="0 0 25 24"
                            >
                              <g clipPath="url(#clip0_993_8274)">
                                <path
                                  fill="#fff"
                                  d="M21.75 7.094v1.812a.906.906 0 11-1.813 0V7.094a2.722 2.722 0 00-2.718-2.719H4.53a2.722 2.722 0 00-2.718 2.719V8h8.156a.907.907 0 010 1.813H1.813v8.156a2.722 2.722 0 002.718 2.718h3.625a.907.907 0 010 1.813H4.531A4.536 4.536 0 010 17.969V7.094a4.536 4.536 0 014.531-4.532h.907v-.906a.907.907 0 011.812 0v.907h7.25v-.907a.906.906 0 111.813 0v.907h.906a4.536 4.536 0 014.531 4.53z"
                                ></path>
                              </g>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                d="M17.595 10.5c.004.003.01.003.021.003 3.52 0 6.384 2.812 6.384 6.269 0 1.41-.49 2.78-1.388 3.884l.929 2.118a.338.338 0 01-.181.448.36.36 0 01-.198.023l-3.406-.587a6.24 6.24 0 01-2.122.362c-3.52 0-6.384-2.811-6.384-6.268-.007-3.447 2.835-6.249 6.345-6.252zm.035 11.84c.672 0 1.336-.12 1.966-.352a.338.338 0 01.184-.017l2.867.492-.759-1.732a.339.339 0 01.056-.359 5.465 5.465 0 001.357-3.604c0-3.08-2.554-5.585-5.688-5.585-3.128-.007-5.667 2.477-5.67 5.548v.02c0 3.085 2.553 5.59 5.687 5.59z"
                              ></path>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                d="M17.655 15h2.19c.223 0 .405.169.405.375s-.182.375-.405.375h-2.19c-.223 0-.405-.169-.405-.375s.182-.375.405-.375zM15.365 18h4.52c.2 0 .365.169.365.375a.371.371 0 01-.365.375h-4.52a.371.371 0 01-.365-.375c0-.206.164-.375.365-.375z"
                              ></path>
                              <defs>
                                <clipPath id="clip0_993_8274">
                                  <path
                                    fill="#fff"
                                    d="M0 0H21.75V21.75H0z"
                                    transform="translate(0 .75)"
                                  ></path>
                                </clipPath>
                              </defs>
                            </svg>
                          </div> */}
                        </div>
                        <div className="admin-ui-dashboard-header-left-main-card">
                          <div className="admin-ui-dashboard-header-left-main-card-info-sec">
                            <p>{t("total_chats")}</p>
                            <h3>{dashboard.data.total_chats}</h3>
                          </div>
                          {/* <div className="admin-ui-dashboard-header-left-main-box-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.612"
                                d="M11.62.78c-.005.006-.018.006-.036.006C5.367.786.312 5.734.312 11.82c0 2.483.866 4.893 2.45 6.835l-1.64 3.728a.594.594 0 00.32.787c.11.049.234.06.35.043l6.014-1.035a11.05 11.05 0 003.747.638c6.217 0 11.272-4.948 11.272-11.032C22.837 5.716 17.82.786 11.621.78zm-.06 20.838c-1.186 0-2.36-.21-3.471-.619a.601.601 0 00-.326-.03l-5.062.866 1.34-3.048a.594.594 0 00-.099-.632 9.593 9.593 0 01-2.396-6.343c0-5.422 4.51-9.83 10.044-9.83 5.522-.011 10.007 4.36 10.013 9.764v.037c0 5.429-4.51 9.835-10.044 9.835z"
                              ></path>
                              <path
                                fill="#fff"
                                stroke="#fff"
                                strokeWidth="0.245"
                                d="M11.08 9.6H7.763a.61.61 0 00-.614.601c0 .331.276.602.614.602h3.317a.61.61 0 00.614-.602.61.61 0 00-.614-.6zM15.38 12.606H7.763a.61.61 0 00-.614.602c0 .33.276.6.614.6h7.617a.61.61 0 00.614-.6.61.61 0 00-.614-.602z"
                              ></path>
                            </svg>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={12} xl={6}>
                    <div className="admin-ui-dashboard-analytics-sec">
                      <div className="new-setting-analytics-box">
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#03363d"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M21 23a1 1 0 11-2 0c0-2.206-1.794-4-4-4H9c-2.206 0-4 1.794-4 4a1 1 0 11-2 0c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6zm1-15.5v2c0 .827-.673 1.5-1.5 1.5H20c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4h-.5C2.673 11 2 10.327 2 9.5v-2C2 6.673 2.673 6 3.5 6H4c0-2.206 1.794-4 4-4h3V1a1 1 0 112 0v1h3c2.206 0 4 1.794 4 4h.5c.827 0 1.5.673 1.5 1.5zM18 6c0-1.103-.897-2-2-2H8c-1.103 0-2 .897-2 2v5c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V6zM9.5 7a1.5 1.5 0 10.001 3.001A1.5 1.5 0 009.5 7zm5 0a1.5 1.5 0 10.001 3.001A1.5 1.5 0 0014.5 7z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 19 24"
                              >
                                <path
                                  fill="#03363D"
                                  d="M17.1 23.611H1.9c-.525 0-.95-.454-.95-1.014 0-3.354 2.556-6.082 5.7-6.082h5.7c3.144 0 5.7 2.728 5.7 6.082 0 .56-.425 1.014-.95 1.014zm.475-17.14H17.1c0-2.236-1.704-4.055-3.8-4.055h-2.85V1.403c0-.56-.425-1.014-.95-1.014-.525 0-.95.454-.95 1.014v1.013H5.7c-2.096 0-3.8 1.819-3.8 4.055h-.475C.639 6.47 0 7.153 0 7.99v2.028c0 .838.64 1.52 1.425 1.52H1.9c0 2.236 1.704 4.055 3.8 4.055h7.6c2.096 0 3.8-1.819 3.8-4.055h.475c.786 0 1.425-.682 1.425-1.52V7.99c0-.838-.64-1.52-1.425-1.52zm-9.54 3.085a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.907.907 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55zm6.65 0a1.061 1.061 0 010 1.433.918.918 0 01-.673.297.918.918 0 01-.671-.297l-.516-.55-.516.55a.918.918 0 01-.671.297.918.918 0 01-.672-.297 1.061 1.061 0 010-1.433l.516-.55-.516-.551a1.061 1.061 0 010-1.434.907.907 0 011.343 0l.516.55.516-.55a.908.908 0 011.343 0 1.061 1.061 0 010 1.434l-.516.55.516.55z"
                                ></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("bots")}</h6>
                              <h4>
                                {dashboard.data.bots.used} /{" "}
                                {dashboard.data.bots.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec bot-progress-bar">
                            <ProgressBar
                              now={
                                (dashboard.data.bots.used /
                                  dashboard.data.bots.total) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>{dashboard.data.bots.remaining} {t("remaining")}</p>
                          </div>
                        </div>
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#03363d"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 23 23"
                              >
                                <path
                                  fill="#03363D"
                                  fillRule="evenodd"
                                  d="M2.83 11.018h6.278c.855 0 1.55-.687 1.55-1.53V5.572c0-.974-.63-2.115-1.796-2.311a.483.483 0 01-.4-.557.49.49 0 01.565-.395 2.938 2.938 0 011.631.86V1.093a2.705 2.705 0 00-2.84-.336 2.635 2.635 0 00-1.497 2.6 2.65 2.65 0 001.938 2.298c.259.072.412.337.339.594a.49.49 0 01-.602.336C6.545 6.186 5.48 4.923 5.347 3.442a3.516 3.516 0 01.007-.692 3.378 3.378 0 00-1.79 1.565 3.344 3.344 0 00-.066 3.034.48.48 0 01-.23.644.493.493 0 01-.653-.227 4.28 4.28 0 01-.392-1.34A2.404 2.404 0 00.801 7.872c-.298.85-.09 1.78.54 2.428.395.404.923.659 1.488.718zm3.067-4.113a.493.493 0 01.683-.11c.22.154.27.456.111.673a1.257 1.257 0 00-.096 1.335 1.29 1.29 0 001.148.688h.023c.266 0 .485.211.488.476a.486.486 0 01-.482.489h-.03c-.85 0-1.62-.46-2.014-1.207a2.21 2.21 0 01.17-2.344zm-3.674 9.67c.057-.46.187-.913.393-1.34a.493.493 0 01.652-.228.48.48 0 01.23.644c-.465.96-.44 2.095.066 3.035a3.38 3.38 0 001.79 1.564 3.521 3.521 0 01-.007-.692c.133-1.48 1.198-2.743 2.649-3.142a.49.49 0 01.602.335.482.482 0 01-.34.594 2.65 2.65 0 00-1.937 2.299 2.634 2.634 0 001.497 2.599 2.706 2.706 0 002.84-.336v-2.076a2.938 2.938 0 01-1.631.86.487.487 0 01-.564-.395.483.483 0 01.399-.557c1.166-.197 1.796-1.338 1.796-2.312v-3.914c0-.844-.695-1.53-1.55-1.53H2.832a2.423 2.423 0 00-1.49.717A2.348 2.348 0 00.8 15.128a2.41 2.41 0 001.422 1.447zm3.505-2.823a2.265 2.265 0 012.044-1.207c.27.003.485.223.482.488a.486.486 0 01-.489.477h-.022a1.29 1.29 0 00-1.148.687c-.227.43-.19.942.096 1.336a.478.478 0 01-.111.673.49.49 0 01-.683-.11 2.21 2.21 0 01-.17-2.344zm8.067 8.256h-2.159v-5.132c.128.01.257.014.387.014 3.014 0 5.458-2.413 5.458-5.388 0-2.976-2.444-5.388-5.458-5.388-.13 0-.26.004-.387.013V.995h2.159l.422 2.113 2.266.928 1.813-1.2 2.506 2.474-1.215 1.79.94 2.236 2.14.416v3.499l-2.14.417-.94 2.236 1.215 1.79-2.506 2.474-1.813-1.2-2.266.927-.422 2.113z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("custom_trainings")}</h6>
                              <h4>
                                {dashboard.data.fine_tunings.used} /{" "}
                                {dashboard.data.fine_tunings.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec fine-tune-progress-bar">
                            <ProgressBar
                              now={
                                (dashboard.data.fine_tunings.used /
                                  dashboard.data.fine_tunings.total) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>
                              {dashboard.data.fine_tunings.remaining} {t("remaining")}
                            </p>
                          </div>
                        </div>
                        <div className="new-setting-analytics-card">
                          <div className="new-setting-analytics-top-sec">
                            <div className="new-setting-analytics-icon-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#03363d"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 21 21"
                              >
                                <path
                                  fill="#03363D"
                                  d="M3.26 3.43a9.999 9.999 0 0114.143 0c3.143 3.14 3.737 7.811 1.879 11.53l1.03 4.529a.822.822 0 01-.983.982l-4.531-1.03C8.21 22.73.33 17.97.33 10.498c0-2.67 1.04-5.18 2.93-7.067zm2.9 9.556h4.353a.821.821 0 100-1.642H6.159a.821.821 0 100 1.642zm0-3.336h8.344a.821.821 0 100-1.642H6.16a.821.821 0 100 1.642z"
                                ></path>
                              </svg>
                            </div>
                            <div className="new-setting-analytics-info-sec">
                              <h6>{t("chat_messages")}</h6>
                              <h4>
                                {dashboard.data.messages.used}{" "}
                                / {dashboard.data.messages.total}
                              </h4>
                            </div>
                          </div>
                          <div className="new-setting-analytics-progress-bar-sec setting-analytics-progress-bar">
                            <ProgressBar
                              now={
                                ((dashboard.data.messages.used) /
                                  dashboard.data.messages.total) *
                                100
                              }
                            />
                          </div>
                          <div className="new-setting-analytics-bottom-sec">
                            <p>
                              {dashboard.data.messages.remaining}{" "}
                              {t("remaining")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ) : null}
            </Row>
          </div>
          <div className="admin-ui-dashboard-body-sec">
            <Row>
              <Col md={6} className="resp-mrg-btm-xs">
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("fine_tunings")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value:7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleFineTuningFilter(selectedOption)
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.fine_tuning_graph).length >
                          0 ? (
                        <ChatsGraph
                          fineTuningFilter={fineTuningFilter}
                          tickets={dashboardGraph.data.fine_tuning_graph}
                          name="Custom Training"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="admin-ui-dashboard-graph-card">
                  <div className="admin-ui-dashboard-graph-header-sec">
                    <h3>{t("chats")}</h3>
                    <div className="admin-ui-dashboard-graph-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        isSearchable={false}
                        defaultValue={{ value:7, label: "Last 7 Days" }}
                        onChange={(selectedOption) => {
                          handleChatFilter(selectedOption)
                        }}
                      />
                    </div>
                  </div>
                  <div className="admin-ui-dashboard-graph-body-sec">
                    <div className="admin-ui-dashboard-graph-img-sec">
                      {dashboardGraph.loading ? (
                        <div className="onboarding-form">
                          <div className="onboarding-body-sec">
                            <Row className="justify-content-md-center">
                              <Col md={12}>
                                <Skeleton count={1} height={250} />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : Object.keys(dashboardGraph.data).length > 0 &&
                        Object.keys(dashboardGraph.data.chats_graph).length > 0 ? (
                        <ChatsGraph
                          chatFilter={chatFilter}
                          tickets={dashboardGraph.data.chats_graph}
                          name="Chats"
                        />
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {dashboardRecentChats.loading ? (
                  <TableLoader lines={6} />
                ) : Object.keys(dashboardRecentChats.data).length > 0 &&
                  dashboardRecentChats.data.recent_chats ? (
                  <DashBoardRecentChatIndex
                    chats={dashboardRecentChats.data.recent_chats}
                  />
                ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation(DashBoardIndex);
