import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import {
  botsStoreStart,
  botsListStart
} from "../../store/slices/BotSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";
import useBot from "../../hooks/useBot";

const AddBotModal = (props) => {

  const { widgets } = useBot();

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "0px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: 'auto' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: 'transparent!important',
      border: '1px solid #00337D57!important',
      borderRadius:'0!important',
      boxShadow: 'none!important',
      cursor:"pointer",
      height: '45px',
      minWidth: "150px",
      fontSize: "0.9em"
    }),
    placeholder: provided => ({ ...provided, color: '#999', fontSize:'1em', fontWeight:'400' }),
    singleValue: provided => ({
      ...provided, color: '#000', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "0.9em", fontWeight: "400"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#048A89!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#048A89"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    }
  }

  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const botsStore = useSelector((state) => state.bots.botsStore);
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.required"))
      .matches(/^[^<>]*$/, t('name.invalid'))
      .trim().notOneOf(['']),
    website: Yup.string().required(t("website.required"))
      .matches(
        /^(?!https?|http:\/\/|https:\/\/).*/,t("website.http"))
      .matches(/^(?=\S*$)/, t("website.white_space"))
      .matches(
        /^(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zAZ0-9-%]+&?)?$/,
        t("website.invalid")
      ),
    widget_type: Yup.string().required('Please select an option'),
    description: Yup.string().matches(/^[^<>]*$/, t('about.invalid'))
  });

  const handleBotsStore = (values) => {
    dispatch(
      botsStoreStart({
        ...values,
        website: "https://" + values.website,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !botsStore.loading &&
      Object.keys(botsStore.data).length > 0
    ) {
      // dispatch(botsListStart());
      props.closeAddBotModal();
    }
    setSkipRender(false);
  }, [botsStore]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.addBot}
        onHide={props.closeAddBotModal}
      >
        <Modal.Body>
          <h4>{t("add_bot")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeAddBotModal()}
          >
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            fill="none"
            viewBox="0 0 11 11"
          >
            <path
              fill="#979BA2"
              d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
            ></path>
          </svg>
          </Button>
          <Formik
            initialValues={{
              name:"",
              website:"",
              widget_type:"",
              description:""
            }}
            validationSchema={validationSchema}
            onSubmit={handleBotsStore}
          >
            {({ setFieldValue, values }) => (
              <FORM noValidate className="onboarding-form">
                <div className="onboarding-body-sec">
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                      {/* <Form className="onboarding-form"> */}
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                        {t("name.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("name.placeholder")}
                          name="name"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="name"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Label>
                      {t("website.label")} <span>*</span>
                      </Form.Label>
                      <div className="mb-3">
                        <InputGroup>
                          <InputGroup.Text id="basic-addon1">
                            https://
                          </InputGroup.Text>
                          <Field
                            className="form-control"
                            placeholder={t("website.placeholder")}
                            aria-label="Website"
                            aria-describedby="basic-addon1"
                            name="website"
                          />
                        </InputGroup>
                        <ErrorMessage
                          component={"div"}
                          name="website"
                          className="errorMsg"
                        />
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                        {t("solution.label")} <span>*</span>
                        </Form.Label>
                        <Select
                          options={widgets}
                          styles={customStyles}
                          placeholder="Select an option"
                          onChange={(selectOption)=>{
                            setFieldValue("widget_type", selectOption.value)
                          }}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="widget_type"
                          className="errorMsg"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>{t("about.label")}</Form.Label>
                        <Field
                          className="form-control"
                          as="textarea"
                          rows={3}
                          placeholder={t("about.placeholder")}
                          name="description"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="description"
                          className="errorMsg"
                        />
                      </Form.Group>
                      {/* </Form> */}
                    </Col>
                  </Row>
                </div>
                <div className="onboarding-footer-sec">
                  <Row className="justify-content-md-center">
                    <Col md={12}>
                      <div className="onboarding-on-premise-btn-sec">
                        <Button
                          className="onboarding-btn"
                          type="submit"
                          disabled={botsStore.buttonDisable}
                        >
                          {botsStore.buttonDisable ? t("loading") : t("add_bot")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(AddBotModal);
