import React, { useEffect, useState } from "react";
import { Image, Button, Row, Col } from "react-bootstrap";
// import "../Layout/NewHeader/NewUI.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddBotModal from "./AddBotModal";
import { botsListStart, botDeleteStart } from "../../store/slices/BotSlice";
import { selectedBotSuccess } from "../../store/slices/AdminSlice";
import Skeleton from "react-loading-skeleton";
import useDesign from "../../hooks/useDesign";
import NoBotExists from "../Helper/NoBotExists";
import { useTranslation, withTranslation } from "react-multi-lang";
import { selecteInboxBot } from "../../store/slices/ChatSlice";
import DeleteBotModal from "./DeleteBotModal";

const YourBotIndex = (props) => {

  const t = useTranslation("bot_creation");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const botsList = useSelector((state) => state.bots.botsList);
  const botDelete = useSelector((state) => state.bots.botDelete);
  const [addBot, setAddBot] = useState(false);
  const [deleteBot, setDeleteBot] = useState(false);

  const { getChatTypeName } = useDesign();

  useEffect(() => {
    dispatch(botsListStart());
  }, []);

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  const closeDeleteBotModal = () => {
    setDeleteBot(false);
  };

  const handleRedirect = (bot, url) => {
    dispatch(selectedBotSuccess(bot));
    dispatch(selecteInboxBot({
      label: bot.name,
      value: bot.bot_id,
    }));
    navigate(url);
  };

  return (
    <>
      <div className="admin-ui-body-sec">
        <div className="admin-ui-your-bot-list-sec">
          {botsList.loading ? (
            <div className="admin-your-bot-list-box">
              {[...Array(3)].map((i, key) => (
                <div className="onboarding-form" key={i}>
                  <div className="onboarding-body-sec">
                    <Row className="justify-content-md-center">
                      <Col md={10}>
                        <div className="mb-3">
                          <Skeleton count={1} height={100} />
                        </div>
                        <div className="mb-3">
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                          <Skeleton count={1} height={10} />
                        </div>
                        <div className="mb-3">
                          <Skeleton count={1} height={50} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ) : Object.keys(botsList.data).length > 0 &&
            botsList.data.bots.length > 0 ? (
            <>
              <div className="onboarding-fine-tunings-files-header-sec">
                <Button
                  className="onboarding-add-fine-tuning-btn"
                  onClick={() => setAddBot(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="#fff"
                    x="0"
                    y="0"
                    enableBackground="new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                  >
                    <path d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"></path>
                  </svg>
                  <span>{t("add_bot")}</span>
                </Button>
              </div>
              <div className="admin-your-bot-list-box">
                {botsList.data.bots.map((bot) => (
                  <div className="admin-your-bot-list-card">
                    <div className="admin-your-bot-list-header-sec">
                      <div className="admin-your-bot-list-header-left-sec">
                        <div className="admin-your-bot-list-user-img-sec bg-yellow">
                          <Image
                            className="admin-your-bot-list-user-img"
                            src={
                              window.location.origin +
                              "/images/your-bot/your-bot-img.svg"
                            }
                          />
                        </div>
                        <div className="admin-your-bot-list-user-info">
                          <h4>{bot.name}</h4>
                          <Link
                            to={bot.website}
                            target="_blank"
                            className="admin-your-bot-list-user-url-link"
                          >
                            {bot.website}
                          </Link>
                        </div>
                      </div>
                      {/* <div className="admin-your-bot-list-header-right-sec">
                                    <h6>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="#62CE6E"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                        >
                                        <path d="M12 0c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3s3-1.35 3-3V3c0-1.65-1.35-3-3-3zm1 21c0 .55-.45 1-1 1s-1-.45-1-1V3c0-.55.45-1 1-1s1 .45 1 1v18zm8-15c-1.65 0-3 1.35-3 3v12c0 1.65 1.35 3 3 3s3-1.35 3-3V9c0-1.65-1.35-3-3-3zm1 15c0 .55-.45 1-1 1s-1-.45-1-1V9c0-.55.45-1 1-1s1 .45 1 1v12zM3 12c-1.65 0-3 1.35-3 3v6c0 1.65 1.35 3 3 3s3-1.35 3-3v-6c0-1.65-1.35-3-3-3zm1 9c0 .55-.45 1-1 1s-1-.45-1-1v-6c0-.55.45-1 1-1s1 .45 1 1v6z"></path>
                                        </svg>
                                    </h6>
                                    <p>vs last 30 day</p>
                                </div> */}
                    </div>
                    <div className="admin-your-bot-list-body-sec">
                      {Object.keys(botsList.data.subscription).length > 0 && (
                        <>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#54659499"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                                <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <path
                                  fill="#9FB2B5"
                                  d="M2.636 2.637a8.998 8.998 0 0112.727 0 8.99 8.99 0 011.691 10.377l.927 4.076a.74.74 0 01-.885.884l-4.077-.927C7.09 20.008 0 15.723 0 8.997c0-2.403.936-4.661 2.636-6.36zm2.609 8.6h3.918a.739.739 0 100-1.478H5.245a.739.739 0 100 1.478zm0-3.002h7.51a.74.74 0 100-1.478h-7.51a.739.739 0 100 1.478z"
                                ></path>
                              </svg>
                              <span>Message</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.chat_messages_count} Used
                                {/* {botsList.data.subscription.total_tokens} */}
                              </div>
                            </div>
                          </div>
                          <div className="admin-your-bot-list-item">
                            <div className="admin-your-bot-list-item-left-sec">
                              {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="#54659499"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                              >
                                <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                              </svg> */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="none"
                                viewBox="0 0 19 19"
                              >
                                <path
                                  fill="#9FB2B5"
                                  fillRule="evenodd"
                                  d="M1.867 9.084l5.423-.001c.738 0 1.339-.593 1.339-1.322v-3.38c0-.84-.544-1.826-1.552-1.996a.417.417 0 01-.345-.48.423.423 0 01.488-.342 2.537 2.537 0 011.409.743V.512a2.336 2.336 0 00-2.453-.29 2.276 2.276 0 00-1.293 2.245 2.289 2.289 0 001.673 1.985.416.416 0 01.293.513.423.423 0 01-.52.29A3.128 3.128 0 014.043 2.54a3.037 3.037 0 01.006-.597 2.918 2.918 0 00-1.546 1.351 2.888 2.888 0 00-.057 2.621c.1.208.012.457-.199.556a.426.426 0 01-.563-.196 3.696 3.696 0 01-.34-1.158 2.077 2.077 0 00-1.228 1.25 2.027 2.027 0 00.468 2.096c.34.35.796.57 1.284.62zm2.65-3.553c.137-.187.4-.23.59-.095.189.134.232.395.096.581-.247.34-.28.782-.083 1.153.193.368.572.595.99.595h.02c.231 0 .42.182.423.41a.42.42 0 01-.417.423h-.025c-.736 0-1.4-.398-1.74-1.043a1.908 1.908 0 01.146-2.024zm-3.173 8.351c.049-.397.16-.788.339-1.157a.426.426 0 01.563-.196c.21.1.3.348.2.556-.402.829-.381 1.809.056 2.62.339.63.886 1.103 1.546 1.352a3.042 3.042 0 01-.006-.598 3.128 3.128 0 012.288-2.714.423.423 0 01.52.29.416.416 0 01-.294.513 2.288 2.288 0 00-1.673 1.985 2.275 2.275 0 001.293 2.245c.804.381 1.769.262 2.453-.29v-1.793c-.36.369-.832.644-1.41.742a.42.42 0 01-.488-.341.417.417 0 01.346-.48c1.008-.171 1.552-1.156 1.552-1.997v-3.38c0-.73-.6-1.322-1.34-1.322H1.87c-.49.05-.946.269-1.287.62a2.028 2.028 0 00-.468 2.096c.202.578.66 1.034 1.229 1.25zm3.027-2.438a1.956 1.956 0 011.765-1.041c.233.002.42.191.417.42a.42.42 0 01-.423.412h-.02c-.418 0-.797.227-.99.594-.196.371-.164.814.083 1.153a.413.413 0 01-.097.582.423.423 0 01-.589-.095 1.908 1.908 0 01-.146-2.025zm6.967 7.13H9.474v-4.431c.11.008.221.011.333.011 2.604 0 4.714-2.083 4.714-4.653 0-2.57-2.11-4.653-4.714-4.653-.112 0-.223.004-.334.011V.428h1.865l.364 1.824 1.957.801 1.566-1.036 2.165 2.136L16.34 5.7l.811 1.932L19 7.99v3.02l-1.849.36-.811 1.932 1.05 1.546-2.165 2.137-1.566-1.037-1.957.801-.364 1.825z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                              <span>Custom Training</span>
                            </div>
                            <div className="admin-your-bot-list-item-right-sec">
                              <div className="admin-your-bot-list-item-message-count">
                                {bot.fine_tuning_models_count ?? 0} Used
                                {/* {botsList.data.subscription.total_fine_tunings} */}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V6a5.006 5.006 0 00-5-5zM5 3h14a3 3 0 013 3v1H2V6a3 3 0 013-3zm14 18H5a3 3 0 01-3-3V9h20v9a3 3 0 01-3 3zm0-8a1 1 0 01-1 1H6a1 1 0 010-2h12a1 1 0 011 1zm-4 4a1 1 0 01-1 1H6a1 1 0 010-2h8a1 1 0 011 1zM3 5a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1zm3 0a1 1 0 111 1 1 1 0 01-1-1z"></path>
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 17 18"
                          >
                            <path
                              fill="#9FB2B5"
                              d="M17 5.909V4.363C17 2.231 15.413.5 13.458.5H3.542C1.587.5 0 2.23 0 4.363V5.91h17zm-3.188-3.864c.588 0 1.063.518 1.063 1.16 0 .64-.475 1.158-1.063 1.158-.587 0-1.062-.517-1.062-1.159 0-.641.475-1.159 1.063-1.159zm-2.833 0c.588 0 1.063.518 1.063 1.16 0 .64-.475 1.158-1.063 1.158s-1.062-.517-1.062-1.159c0-.641.474-1.159 1.062-1.159zm-2.833 0c.588 0 1.062.518 1.062 1.16 0 .64-.474 1.158-1.062 1.158-.588 0-1.063-.517-1.063-1.159 0-.641.475-1.159 1.063-1.159zM17 7.455v6.181c0 2.133-1.587 3.864-3.542 3.864H3.542C1.587 17.5 0 15.769 0 13.636V7.454h17z"
                            ></path>
                          </svg>
                          <span>Source</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className={getChatTypeName(bot.widget_type)}>
                            {bot.widget_type_formatted}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 2h-1V1a1 1 0 00-2 0v1H8V1a1 1 0 00-2 0v1H5a5.006 5.006 0 00-5 5v12a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V7a5.006 5.006 0 00-5-5zM2 7a3 3 0 013-3h14a3 3 0 013 3v1H2zm17 15H5a3 3 0 01-3-3v-9h20v9a3 3 0 01-3 3z"></path>
                            <circle cx="12" cy="15" r="1.5"></circle>
                            <circle cx="7" cy="15" r="1.5"></circle>
                            <circle cx="17" cy="15" r="1.5"></circle>
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#9FB2B5"
                              d="M0 5.833v-.667a3.337 3.337 0 013.333-3.333H4v-.667a.666.666 0 111.333 0v.667h5.334v-.667a.666.666 0 111.333 0v.667h.667A3.337 3.337 0 0116 5.166v.667H0zm16 1.333v6a3.337 3.337 0 01-3.333 3.334H3.333A3.337 3.337 0 010 13.166v-6h16zm-8 6a.666.666 0 00-.667-.666H4a.666.666 0 100 1.333h3.333A.667.667 0 008 13.166zm4.667-2.666A.667.667 0 0012 9.833H4a.667.667 0 100 1.333h8a.666.666 0 00.667-.666z"
                            ></path>
                          </svg>
                          <span>Created Date:</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.created_at}
                          </div>
                        </div>
                      </div>
                      <div className="admin-your-bot-list-item">
                        <div className="admin-your-bot-list-item-left-sec">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#54659499"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                          >
                            <path d="M19 2h-1V1a1 1 0 10-2 0v1H8V1a1 1 0 10-2 0v1H5C2.243 2 0 4.243 0 7v12c0 2.757 2.243 5 5 5h4a1 1 0 100-2H5c-1.654 0-3-1.346-3-3v-9h21a1 1 0 001-1V7c0-2.757-2.243-5-5-5zm3 6H2V7c0-1.654 1.346-3 3-3h14c1.654 0 3 1.346 3 3v1zm-3.121 4.879l-5.707 5.707A3.971 3.971 0 0012 21.414V23a1 1 0 001 1h1.586a3.975 3.975 0 002.828-1.172l5.707-5.707c.566-.567.879-1.32.879-2.122s-.313-1.555-.879-2.121a3.002 3.002 0 00-4.242 0zm2.828 2.828L16 21.414c-.378.378-.88.586-1.414.586H14v-.586c0-.526.214-1.042.586-1.414l5.707-5.707a1 1 0 011.414 1.414z"></path>
                          </svg> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              fill="#9FB2B5"
                              d="M17 5.666H0v-.708a3.546 3.546 0 013.542-3.542h.708V.708a.708.708 0 111.417 0v.708h5.666V.708a.708.708 0 111.417 0v.708h.708A3.546 3.546 0 0117 4.958v.708zM7.083 15.97c0-.632.133-1.242.38-1.803H4.25a.708.708 0 110-1.416h4.193l1.418-1.417H4.25a.708.708 0 110-1.417h7.028l1.942-1.941a3.024 3.024 0 012.152-.892H0v6.375A3.546 3.546 0 003.542 17h3.541v-1.03zm7.138-6.993a1.628 1.628 0 012.302 2.302L11.702 16.1a3.07 3.07 0 01-2.171.9H8.5v-1.03c0-.816.324-1.596.9-2.172l4.821-4.822z"
                            ></path>
                          </svg>
                          <span>Last Edited:</span>
                        </div>
                        <div className="admin-your-bot-list-item-right-sec">
                          <div className="admin-your-bot-list-item-created-date">
                            {bot.updated_at}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="admin-your-bot-list-footer-sec">
                      <div className="admin-your-bot-list-btn-sec">
                        <Button
                          className="admin-your-bot-list-btn"
                          onClick={() => setDeleteBot(bot.bot_id)}
                          disabled={botDelete.buttonDisable}
                        >
                          {botDelete.buttonDisable &&
                          botDelete.data.bot_id === bot.bot_id
                            ? t("loading")
                            : t("delete")}
                        </Button>
                        <Button className="admin-your-bot-list-btn" onClick={() => handleRedirect(bot, "/settings?tab=fine-tune")} disabled={botDelete.buttonDisable}>
                        {t("train")}
                        </Button>
                        <Button
                          className="admin-your-bot-list-btn"
                          onClick={() => handleRedirect(bot, "/settings")}
                          disabled={botDelete.buttonDisable}
                        >
                          {t("edit")}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NoBotExists setAddBot={setAddBot} />
          )}
        </div>
      </div>
      {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
      {deleteBot && (
        <DeleteBotModal
          deleteBot={deleteBot}
          closeDeleteBotModal={closeDeleteBotModal}
          setDeleteBot={setDeleteBot}
        />
      )}
    </>
  );
};

export default withTranslation(YourBotIndex);
