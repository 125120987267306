import React, { useState, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { faqImportStart, faqListStart } from "../../../store/slices/FaqSlice";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";


const ImportFaq = (props) => {

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 0,
    borderColor: '#03363d',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#03363d'
  };
  
  const acceptStyle = {
    borderColor: '#03363d'
  };
  
  const rejectStyle = {
    borderColor: '#03363d'
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/csv': [".csv"],
      'text/xls': [".xls"],
      'text/xlsx': [".xlsx"],
    },
    maxFiles: 1,
    useFsAccessApi: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      )
    },
    onDragEnter: () => {
      setShowMessage(true);
    },
    onDragLeave: () => {
      setShowMessage(false);
    },
  });

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("Please select a file")
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const [files, setFiles] = useState([]);
  const t = useTranslation("import_finetune_modal");
  const dispatch = useDispatch();
  const faqImport = useSelector((state) => state.faq.faqImport);
  const settingsJson = useSelector((state) => state.admin.settingsJson);
  const [skipRender, setSkipRender] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  const handleUpload = () => {
    if (files.length > 0 && files[0].size/1024 > settingsJson.data.data.max_file_size) {
      getErrorNotificationMessage(`Please select file ${files.length > 0 ? `with size less than ${settingsJson.data.data.max_file_size/1000} MB` : ''}`);
      return
    }
    dispatch(faqImportStart({
      import_excel_file: files[0],
      bot_id: props.botId,
    }))
  }

  useEffect(() => {
    if (
      !skipRender &&
      !faqImport.loading &&
      Object.keys(faqImport.data).length > 0
    ) {
        props.closeAddFaqModal();
        setFiles([]);
    }
    setSkipRender(false);
  }, [faqImport]);

  return (
    <div className="modal-dialog-center import-webpage-modal my-3">
      <div className="divider"></div>
      <div className="import-webpage-header-sec">
        <h4>Import FAQs</h4>
        <a
          className="onboarding-save-btn"
          download={true}
          target="_blank"
          rel="noreferrer"
          href={window.location.origin + "/sample-data/haive-sample.xlsx"}
        >
          Dowload Sample
        </a>
      </div>
      <Formik
        initialValues={{
          file: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleUpload}
      >
        {({ setFieldValue, values, touched, errors }) => (
          <FORM noValidate className="onboarding-form">
            {files.length <= 0 && (
              <div className="onboarding-document-upload-card mt-3">
                <div
                  {...getRootProps({
                    style,
                    onDrop: (e) => {
                      setShowMessage(false);
                      let ext = e.dataTransfer.files[0].name.slice(
                        e.dataTransfer.files[0].name.lastIndexOf(".")
                      );
                      if (
                        e.dataTransfer.files.length == 1 && getInputProps().accept.includes(ext) && e.dataTransfer.files[0].type)
                        setFieldValue("file", e.dataTransfer.files);
                      else if (!getInputProps().accept.includes(ext))
                        getErrorNotificationMessage("Invalid File Type");
                      else
                        getErrorNotificationMessage(
                          "Please select a single file"
                        );
                    },
                    onChange: (e) => {
                      let ext = e.target.files[0].name.slice((e.target.files[0].name.lastIndexOf(".")));
                      if (e.target.files.length == 1 && getInputProps().accept.includes(ext))
                        setFieldValue("file", e.target.files)
                      else if (!getInputProps().accept.includes(ext))
                        getErrorNotificationMessage("Invalid File Type");
                      else
                        getErrorNotificationMessage("Please select a single file")
                    },
                  })}
                >
                  <input name="file" {...getInputProps()} />
                  <div className="onboarding-document-item">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      fill="none"
                      viewBox="0 0 56 56"
                    >
                      <path
                        fill="#048A89"
                        d="M28.344 25.102a.44.44 0 00-.689 0L21.53 32.85a.437.437 0 00.345.705h4.041v13.257c0 .24.197.437.438.437h3.281c.24 0 .438-.197.438-.438v-13.25h4.052a.436.436 0 00.344-.706l-6.125-7.754z"
                      ></path>
                      <path
                        fill="#048A89"
                        d="M44.373 20.054C41.87 13.448 35.487 8.75 28.011 8.75c-7.476 0-13.858 4.692-16.363 11.298C6.962 21.278 3.5 25.55 3.5 30.625c0 6.043 4.895 10.938 10.932 10.938h2.193c.24 0 .438-.197.438-.438v-3.281a.439.439 0 00-.438-.438h-2.193a6.742 6.742 0 01-4.867-2.062 6.778 6.778 0 01-1.909-4.954c.05-1.444.542-2.8 1.433-3.943a6.853 6.853 0 013.615-2.39l2.073-.541.76-2.002a13.427 13.427 0 014.818-6.196 13.24 13.24 0 017.656-2.417c2.762 0 5.409.837 7.656 2.417a13.434 13.434 0 014.818 6.196l.755 1.996 2.067.547a6.812 6.812 0 015.037 6.568c0 1.81-.706 3.516-1.985 4.796a6.731 6.731 0 01-4.791 1.985h-2.193a.439.439 0 00-.438.438v3.281c0 .24.197.438.438.438h2.193c6.038 0 10.932-4.895 10.932-10.938 0-5.07-3.45-9.335-8.127-10.571z"
                      ></path>
                    </svg>
                    <p>
                      {t("doc.file.placeholder")} <br></br>{" "}
                      {t("doc.file.faq_supported")} <br></br> {t("doc.file.or")}{" "}
                      <strong>{t("doc.file.browse")}</strong>
                    </p>
                  </div>
                  {showMessage && (
                    <div className="upload-overlay-preview-card">
                      <div className="upload-overlay-preview-sec">
                        <p className="file-upload-name">
                          Please upload document here
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <ErrorMessage component={"div"} name="file" className="errorMsg" />
            {files &&
              files.map((file, index) => (
                <div className="upload-preview-sec" key={file.name}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 18 22"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0297 5.76641C17.1703 5.90703 17.25 6.09687 17.25 6.29609V20.75C17.25 21.1648 16.9148 21.5 16.5 21.5H1.5C1.08516 21.5 0.75 21.1648 0.75 20.75V1.25C0.75 0.835156 1.08516 0.5 1.5 0.5H11.4539C11.6531 0.5 11.8453 0.579688 11.9859 0.720313L17.0297 5.76641ZM15.5203 6.64062L11.1094 2.22969V6.64062H15.5203ZM4.5 10.2969C4.45027 10.2969 4.40258 10.3166 4.36742 10.3518C4.33225 10.387 4.3125 10.4346 4.3125 10.4844V11.6094C4.3125 11.6591 4.33225 11.7068 4.36742 11.742C4.40258 11.7771 4.45027 11.7969 4.5 11.7969H13.5C13.5497 11.7969 13.5974 11.7771 13.6326 11.742C13.6677 11.7068 13.6875 11.6591 13.6875 11.6094V10.4844C13.6875 10.4346 13.6677 10.387 13.6326 10.3518C13.5974 10.3166 13.5497 10.2969 13.5 10.2969H4.5ZM4.5 13.4844C4.45027 13.4844 4.40258 13.5041 4.36742 13.5393C4.33225 13.5745 4.3125 13.6221 4.3125 13.6719V14.7969C4.3125 14.8466 4.33225 14.8943 4.36742 14.9295C4.40258 14.9646 4.45027 14.9844 4.5 14.9844H8.8125C8.86223 14.9844 8.90992 14.9646 8.94508 14.9295C8.98025 14.8943 9 14.8466 9 14.7969V13.6719C9 13.6221 8.98025 13.5745 8.94508 13.5393C8.90992 13.5041 8.86223 13.4844 8.8125 13.4844H4.5Z"
                      fill="#048A89"
                    />
                  </svg>
                  <div className="w-100">
                    <p className="file-upload-name">{file.name}</p>
                    <span className="file-upload-size">
                      {file.size >= 1024
                        ? (file.size / (1024 * 1024)).toFixed(2) + " MB"
                        : (file.size / 1024).toFixed(2) + " KB"}
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      removeFile(index);
                      setFieldValue("file", null);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 7.151L6.374 5.525C6.26142 5.41242 6.10872 5.34917 5.9495 5.34917C5.79028 5.34917 5.63758 5.41242 5.525 5.525C5.41242 5.63758 5.34917 5.79028 5.34917 5.9495C5.34917 6.10872 5.41242 6.26142 5.525 6.374L7.151 8L5.525 9.626C5.46925 9.68175 5.42503 9.74793 5.39486 9.82076C5.36469 9.8936 5.34917 9.97166 5.34917 10.0505C5.34917 10.1293 5.36469 10.2074 5.39486 10.2802C5.42503 10.3531 5.46925 10.4193 5.525 10.475C5.58075 10.5307 5.64693 10.575 5.71976 10.6051C5.7926 10.6353 5.87066 10.6508 5.9495 10.6508C6.02834 10.6508 6.1064 10.6353 6.17924 10.6051C6.25207 10.575 6.31825 10.5307 6.374 10.475L8 8.849L9.626 10.475C9.68175 10.5307 9.74793 10.575 9.82076 10.6051C9.8936 10.6353 9.97166 10.6508 10.0505 10.6508C10.1293 10.6508 10.2074 10.6353 10.2802 10.6051C10.3531 10.575 10.4193 10.5307 10.475 10.475C10.5307 10.4193 10.575 10.3531 10.6051 10.2802C10.6353 10.2074 10.6508 10.1293 10.6508 10.0505C10.6508 9.97166 10.6353 9.8936 10.6051 9.82076C10.575 9.74793 10.5307 9.68175 10.475 9.626L8.849 8L10.475 6.374C10.5307 6.31825 10.575 6.25207 10.6051 6.17924C10.6353 6.1064 10.6508 6.02834 10.6508 5.9495C10.6508 5.87066 10.6353 5.7926 10.6051 5.71976C10.575 5.64693 10.5307 5.58075 10.475 5.525C10.4193 5.46925 10.3531 5.42503 10.2802 5.39486C10.2074 5.36469 10.1293 5.34917 10.0505 5.34917C9.97166 5.34917 9.8936 5.36469 9.82076 5.39486C9.74793 5.42503 9.68175 5.46925 9.626 5.525L8 7.151Z"
                        fill="#048A89"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            <div className="onboarding-import-webpage-modal-btn-sec mt-3">
              <Button
                className="onboarding-cancel-btn"
                onClick={() => {
                  setFiles([])
                  props.closeAddFaqModal() 
                }}
                disabled={faqImport.buttonDisable}
              >
                {t("cancel")}
              </Button>
              <Button
                className="onboarding-save-btn"
                type="submit"
                // onClick={handleUpload}
                disabled={faqImport.buttonDisable}
              >
                {faqImport.buttonDisable ? t("loading") : "Upload"}
              </Button>
            </div>
          </FORM>
        )}
      </Formik>
    </div>
  );
}

export default withTranslation(ImportFaq);