import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../store/slices/AdminSlice";
import { setTranslations, setLanguage } from "react-multi-lang";
import { Helmet } from "react-helmet";
import en from "../translations/en.json";
import es from "../translations/es.json";
import { settingsJsonStart } from "../../store/slices/AdminSlice";
// Layouts
import {
  AuthLayout,
  EmptyLayout,
  AdminLayout,
  EmptyAdminLayout,
} from "../Layout";
// Pages
import Page404 from "../Helper/Page404";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import OnBoardingIndex from "../OnBoarding/OnBoardingIndex";
import OnPremiseIndex from "../OnBoarding/OnPremiseIndex";
import AIasRegisterIndex from "../OnBoarding/AIasRegisterIndex";
import OnBoardingSteps from "../OnBoarding/OnBoardingSteps";
import OnBoardingSubscriptionPlans from "../OnBoarding/OnBoardingSubscriptionPlans";
import OnBoardingSubscriptionPayment from "../OnBoarding/OnBoardingSubscriptionPayment";
import YourBotIndex from "../Bots/YourBotIndex";
import SubscriptionIndex from "../Subscription/SubscriptionIndex";
import AIasLoginIndex from "../OnBoarding/AIasLoginIndex";
import BillingsIndex from "../Subscription/BillingsIndex";
import SettingsIndex from "../Settings/SettingsIndex";
import ChatInbox from "../Chat/ChatInbox";
import PrivacyPolicyIndex from "../OnBoarding/PrivacyPolicyIndex";
import AIasForgotPassword from "../OnBoarding/AIasForgotPassword";
import AIasResetPassword from "../OnBoarding/AIasResetPassword";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import ProfileIndex from "../Profile/ProfileIndex";
import DashboardIndex from "../Dashboard/DashboardIndex";
import ScrollToTop from "../Helper/ScrollToTop"
import DelayMessage from "../Delaymessages/DelayMessage";
import FeedbackSuccess from "../Helper/FeedbackSuccess";
import UpcomingIndex from "../upcoming/UpcomingIndex";

setTranslations({ en, es });

const CommonRoute = ({
  component: Component,
  authLayout: AuthLayout,
  adminLayout: AdminLayout,
  ...rest
}) =>
  localStorage.getItem("token") || sessionStorage.getItem("token") ? (
    <AdminLayout {...rest}>
      <Component />
    </AdminLayout>
  ) : (
    <AuthLayout {...rest}>
      <Component />
    </AuthLayout>
  );

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Layout {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) =>
  localStorage.getItem("token") || sessionStorage.getItem("token") ? (
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/home" }} />
  );

const Base = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.admin.theme);
  const profile = useSelector((state) => state.admin.profile);
  const settingsJson = useSelector((state) => state.admin.settingsJson);

  const [, setState] = useState();

  useEffect(() => {
    if (localStorage.getItem("dark-mode"))
      dispatch(setTheme(localStorage.getItem("dark-mode")));
    dispatch(settingsJsonStart());
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("dark-mode", theme);
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.removeItem("dark-mode");
    }
  }, [theme]);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    setState(1);
  }, [profile]);

  return settingsJson.loading ? (
    <PageLoader />
  ) : Object.keys(settingsJson.data).length > 0 ? (
    <>
      <Helmet>
        <title>{settingsJson.data.data.site_name}</title>
        <link
          rel="icon"
          type="image/png"
          href={settingsJson.data.data.site_icon}
        />
        <script src={settingsJson.data.data.head_scripts}></script>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop/>
        <Routes>
          {/* Onboarding */}
          <Route path={"/onboarding"}>
            <Route
              index={true}
              element={
                <AppRoute component={OnBoardingIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/on-premise"}>
            <Route
              index={true}
              element={
                <AppRoute component={OnPremiseIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/ai-as-service"}>
            <Route
              index={true}
              element={
                <AppRoute component={AIasRegisterIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/onboarding-steps"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={OnBoardingSteps}
                  layout={EmptyAdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/onboarding-subscription-plans"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={OnBoardingSubscriptionPlans}
                  layout={EmptyAdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/subscription-payment"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={OnBoardingSubscriptionPayment}
                  layout={EmptyAdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/privacy-policy"}>
            <Route
              index={true}
              element={
                <AppRoute component={PrivacyPolicyIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/login"}>
            <Route
              index={true}
              element={
                <AppRoute component={AIasLoginIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/forgot-password"}>
            <Route
              index={true}
              element={
                <AppRoute component={AIasForgotPassword} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/reset-password/:token"}>
            <Route
              index={true}
              element={
                <AppRoute component={AIasResetPassword} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={DashboardIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/bots"}>
            <Route
              index={true}
              element={
                <PrivateRoute component={YourBotIndex} layout={AdminLayout} />
              }
            />
          </Route>
          <Route path={"/subscription"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={SubscriptionIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/billings"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={BillingsIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/page/:unique_id"}>
            <Route
              index={true}
              element={
                <AppRoute component={PrivacyPolicyIndex} layout={AuthLayout} />
              }
            />
          </Route>
          <Route path={"/settings"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={SettingsIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/inbox"}>
            <Route
              index={true}
              element={
                <PrivateRoute component={ChatInbox} layout={AdminLayout} />
              }
            />
          </Route>
          <Route path={"/profile"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={ProfileIndex}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route path={"/delay-message"}>
            <Route
              index={true}
              element={
                <PrivateRoute
                  component={DelayMessage}
                  layout={AdminLayout}
                />
              }
            />
          </Route>
          <Route
            path={"/home"}
            element={
              <AppRoute component={LandingPageIndex} layout={AuthLayout} />
            }
          />
          <Route
            path={"/feedback-success"}
            element={
              <CommonRoute
                component={FeedbackSuccess}
                authLayout={EmptyLayout}
                adminLayout={AdminLayout}
              />
            }
          />
             <Route
            path={"/upcoming/:type"}
            element={
              <PrivateRoute
                component={UpcomingIndex}
                layout={AdminLayout}
              />
            }
          />
          <Route
            path={"/*"}
            element={
              <CommonRoute
                component={Page404}
                authLayout={EmptyLayout}
                adminLayout={AdminLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(settingsJsonStart())}
    />
  );
};

export default Base;
