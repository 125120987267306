import React from "react";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllreadyDone = (props) => {
    const { widgetReminderData } = props;
  return (
    <>
      <div className="upcoming-img-frame">
        <Image
          className="coming-soon-image"
          src={window.location.origin + "/images/upcoming/notify-bell.png"}
          type="image/webp"
        />
      </div>
      <div className="upcoming-info-frame">
        <h4>You Have Notified for {widgetReminderData.widget_type_formatted} Reminder</h4>
        {/* <Link className="admin-your-bot-list-btn" to="/">
          Home
        </Link> */}
      </div>
    </>
  );
};

export default AllreadyDone;
