import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup, Row, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { faqDeleteStart } from "../../../store/slices/FaqSlice";
import { conversationsDeleteStart } from "../../../store/slices/FineTuneSlice";


const DeleteConversationsModal = (props) => {

  const t = useTranslation();
  const dispatch = useDispatch();
  const conversationsDelete = useSelector((state) => state.fineTune.conversationsDelete);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(()=> {
    if(!skipRender && !conversationsDelete.loading && Object.keys(conversationsDelete.data).length > 0)
    {
      props.closeDeleteConversationsModal();
    }
    setSkipRender(false);
  }, [conversationsDelete])

 
  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.deleteConversations}
        onHide={props.closeDeleteConversationsModal}
      >
        <Modal.Body>
          <h4>Delete Conversations</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeDeleteConversationsModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              data-name="Layer 1"
              viewBox="0 0 24 24"
            >
              <path d="M16.061 10.061L14.122 12l1.939 1.939a1.5 1.5 0 11-2.122 2.121L12 14.121l-1.939 1.939c-.293.293-.677.439-1.061.439s-.768-.146-1.061-.439a1.5 1.5 0 010-2.121L9.878 12l-1.939-1.939A1.5 1.5 0 1110.06 7.94l1.939 1.939 1.939-1.939a1.5 1.5 0 112.121 2.121zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-3 0c0-4.963-4.037-9-9-9s-9 4.037-9 9 4.038 9 9 9 9-4.037 9-9z"></path>
            </svg>
          </Button>
          <div className="delete-bot-card">
            <h4>Do you really want to delete this conversations?</h4>
            <p>All your data will be deleted and cannot be recovered.</p>
          </div>
          <div className="onboarding-import-webpage-modal-btn-sec mt-3">
            <Button
              className="onboarding-cancel-btn"
              onClick={() => props.closeDeleteConversationsModal()}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="onboarding-save-btn"
              type="submit"
              onClick={() =>
                dispatch(conversationsDeleteStart({ conversational_data_id: props.deleteConversations }))
            }
              disabled={conversationsDelete.buttonDisable}
            >
              {conversationsDelete.buttonDisable ? t("Loading") : t("Ok")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteConversationsModal);
