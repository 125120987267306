import React from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Page404 = () => {
  const theme = useSelector((state) => state.admin.theme);

  return (
    <div className="no-bot-exists-card mt-5">
      <div className="no-bot-exists-img-sec">
        <Image
          className="no-bot-exists-img"
          src={window.location.origin + "/images/illustrations/404_error.png"}
        />
      </div>
      <div className="no-bot-exists-info">
        <h4>This page you are looking not available</h4>
      </div>
      <Link to="/" className="settings-btn">
        Back To Home
      </Link>
    </div>
  );
};

export default Page404;