import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Tab,
  Row,
  Col,
  Nav,
  Table,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  conversationsDeleteStart,
  conversationsListStart,
  moreConversationsListStart,
  fineTuneListStart,
} from "../../../store/slices/FineTuneSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../../Helper/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import DeleteConversationsModal from "./DeleteConversationsModal";

const ConversationsIndex = (props) => {
  const dispatch = useDispatch();

  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const conversationsList = useSelector((state) => state.fineTune.conversationsList);
  const conversationsDelete = useSelector((state) => state.fineTune.conversationsDelete);
  const [deleteConversations, setDeleteConversations] = useState(false);

  const closeDeleteConversationsModal = () => {
    setDeleteConversations(false);
  };

  useEffect(() => {
    dispatch(
      conversationsListStart({ bot_id: selectedBot.bot_id, skip: 0, take: 12 })
    );
    dispatch(
      fineTuneListStart({ bot_id: selectedBot.bot_id })
    );
  }, [selectedBot]);

  const fetchMoreData = () => {
    dispatch(
      moreConversationsListStart({
        bot_id: selectedBot.bot_id,
        skip: conversationsList.data.conversational_data.length,
        take: 12,
      })
    );
  };

  const getStatusName = (status) => {
    switch (status) {
      case 1:
        return "progress-badge";
      case 2:
        return "trained-badge";
      case 3:
        return "failed-badge";
      default:
        return "uploaded-badge";
    }
  };

  return (
    <div className="new-settings-finetune-table-sec">
      <div className="new-finetune-faq-table-box-1">
      {conversationsList.loading ? (
        <div className="onboarding-fine-tunings-files-box">
          {[...Array(4)].map((i) => (
            <Skeleton height={50} key={i} />
          ))}
        </div>
      ) : Object.keys(conversationsList.data).length > 0 &&
        conversationsList.data.conversational_data &&
        conversationsList.data.conversational_data.length > 0 ? (
        <InfiniteScroll
          dataLength={conversationsList.data.conversational_data.length}
          next={fetchMoreData}
          hasMore={
            conversationsList.data.conversational_data.length <
            conversationsList.data.total_conversational_data
          }
          loader={[...Array(3)].map((i) => (
            <Skeleton height={80} className="mb-3" key={i} />
          ))}
        >
          
            <Table responsive="md" className="table-hover">
            <thead>
              <tr>
              <th>
                File Name
              </th>
              <th>
               Created Date
              </th>
              <th>
                Type
              </th>
              <th>
                Status
              </th>
              <th>
                Action
              </th>
              </tr>
            </thead>
              <tbody>
              {conversationsList.data.conversational_data.map((model, i) => (
                <tr key={i}>
                  <td>{model.name}</td>
                  <td>
                    <span>Created on:</span> {model.created_at}
                  </td>
                  <td>
                    <div className="conversation-data-status">
                      <div className="uploaded-badge">{model.platform_formatted}</div>
                    </div>
                  </td>
                  <td>
                    <div className="conversation-data-status">
                      <div className={getStatusName(model.training_status)}>
                        {model.training_status_formatted}
                      </div>
                    </div>
                  </td>
                  <td className="recent-chat-menu-btn">
                    {conversationsDelete.buttonDisable &&
                    conversationsDelete.data.conversational_data_id ==
                      model.id ? (
                      <Image
                        className="btn-loader"
                        src={
                          window.location.origin +
                          "/images/loader/btn-loader.gif"
                        }
                      />
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="recent-chat-menu-icon-sec">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              x="0"
                              y="0"
                              enableBackground="new 0 0 512 512"
                              version="1.1"
                              viewBox="0 0 512 512"
                              xmlSpace="preserve"
                            >
                              <circle cx="458.667" cy="256" r="53.333"></circle>
                              <circle cx="256" cy="256" r="53.333"></circle>
                              <circle cx="53.333" cy="256" r="53.333"></circle>
                            </svg>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href={model.file} target="_blank">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#546594"
                              x="0"
                              y="0"
                              enableBackground="new 0 0 512.19 512.19"
                              version="1.1"
                              viewBox="0 0 512.19 512.19"
                              xmlSpace="preserve"
                            >
                              <circle cx="256.095" cy="256.095" r="85.333"></circle>
                              <path d="M496.543 201.034C463.455 147.146 388.191 56.735 256.095 56.735S48.735 147.146 15.647 201.034c-20.862 33.743-20.862 76.379 0 110.123 33.088 53.888 108.352 144.299 240.448 144.299s207.36-90.411 240.448-144.299c20.862-33.744 20.862-76.38 0-110.123zM256.095 384.095c-70.692 0-128-57.308-128-128s57.308-128 128-128 128 57.308 128 128c-.071 70.663-57.337 127.929-128 128z"></path>
                          </svg>
                            View
                          </Dropdown.Item>
                          {model.training_status != 1 && <Dropdown.Item
                            disabled={conversationsDelete.buttonDisable}
                            onClick={() => setDeleteConversations(model.id)}
                          >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                fill="#546594"
                                height="20"
                                x="0"
                                y="0"
                                enableBackground="new 0 0 512 512"
                                version="1.1"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                              >
                              <path d="M448 85.333h-66.133C371.66 35.703 328.002.064 277.333 0h-42.667c-50.669.064-94.327 35.703-104.533 85.333H64c-11.782 0-21.333 9.551-21.333 21.333S52.218 128 64 128h21.333v277.333C85.404 464.214 133.119 511.93 192 512h128c58.881-.07 106.596-47.786 106.667-106.667V128H448c11.782 0 21.333-9.551 21.333-21.333S459.782 85.333 448 85.333zM234.667 362.667c0 11.782-9.551 21.333-21.333 21.333-11.783 0-21.334-9.551-21.334-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zm85.333 0c0 11.782-9.551 21.333-21.333 21.333-11.782 0-21.333-9.551-21.333-21.333v-128c0-11.782 9.551-21.333 21.333-21.333 11.782 0 21.333 9.551 21.333 21.333v128zM174.315 85.333c9.074-25.551 33.238-42.634 60.352-42.667h42.667c27.114.033 51.278 17.116 60.352 42.667H174.315z"></path>
                            </svg>
                            {conversationsDelete.buttonDisable
                              ? "Loading"
                              : "Delete"}
                          </Dropdown.Item>}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </td>
                </tr>
                ))}
              </tbody>
            </Table>
          
        </InfiniteScroll>
      ) : (
        <NoDataFound />
      )}
      </div>
      {deleteConversations && (
        <DeleteConversationsModal
          deleteConversations={deleteConversations}
          closeDeleteConversationsModal={closeDeleteConversationsModal}
          setDeleteConversations={setDeleteConversations}
        />
      )}
    </div>
  );
};

export default ConversationsIndex;
