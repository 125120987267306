import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import { fineTuneSaveStart } from "../../store/slices/FineTuneSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";

const ImportWebpageModal = (props) => {
  const t = useTranslation("import_finetune_modal");
  const dispatch = useDispatch();
  const fineTuneSave = useSelector((state) => state.fineTune.fineTuneSave);
  const [skipRender, setSkipRender] = useState(true);
  const [enteredUrls, setEnteredUrls] = useState([]);
  const settingsJson = useSelector((state) => state.admin.settingsJson);
  const max_urls = props.onboarding == 1 ? 1 : settingsJson.data.data.url_upload_limit;
  const formRef = useRef(null);
  const [duplicateError, setDuplicateError] = useState(false);

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required(t("web.name.required")).trim().notOneOf(['']),
    import_url: Yup.string()
      .required(t("web.website.required"))
      .matches(/^(?!https?)(?!http\.?)/, t("web.website.http"))
      .matches(/^(?=\S*$)/, t("web.website.white_space"))
      .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,7}(:[0-9]{1,7})?(\/.*)?$/, t("web.website.invalid")),
    description: Yup.string().matches(/^[^<>]*$/, t('web.about.invalid'))
  });

  const isValidUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  };

  const saveTune = (values) => {
    if (props.onboarding && (values.import_url == "" || values.import_url.includes(','))) {
      getErrorNotificationMessage("Please enter a valid URL");
      return
    }
    isValidUrl("https://"+values.import_url) ? dispatch(fineTuneSaveStart({
      ...values,
      import_url: props.onboarding ? "https://"+values.import_url : enteredUrls,
      onboarding: props.onboarding,
      bot_id: props.onboardingBot.bot_id ?? 0
    })) : getErrorNotificationMessage("Invalid URL");
  };

  useEffect(() => {
    if (
      !skipRender &&
      !fineTuneSave.loading &&
      Object.keys(fineTuneSave.data).length > 0
    ) {
      props.closeImportWebpageModal()
      if(props.onboarding===1 && props.step && props.step!==4)
      {
        props.setStep(4);
      }
    }
    setSkipRender(false);
  }, [fineTuneSave]);

  // Function to handle the input change for URLs
  const handleUrlInputChange = async (inputValue, setFieldValue, type) => {
    setDuplicateError(false);
    async function processFirstIfBlock() {
      if (inputValue.endsWith(',') || inputValue.endsWith(' ')) {
        const inputText = inputValue.replace(/%20/g, ' ');
        const all_urls = inputText.split(/[,\s]+/).map((url) => url.replace(/\/+$/, '').trim());
        const urls = all_urls.filter((item, index) => all_urls.map(url => url.replace(/\/+$/, '')).indexOf(item) === index);
        if (urls.length <= all_urls.length - 1 && urls[0] !== "")
        {
          setDuplicateError(true);
          getErrorNotificationMessage("Duplicate URLs found. Only unique URLs are permitted. Duplicate entries have been eliminated.");
        }
        const validUrls = [];
        const remainingText = [];
        var invalid_urls = 0;
        var duplicate_urls = 0;
        for (const url of urls) {
          if (url && enteredUrls.length < max_urls) {
            if (isValidUrl(url)) {
              if (!enteredUrls.includes(url)) {
                validUrls.push(url);
                setFieldValue("single_url", "");
              } else {
                remainingText.push(url);
                duplicate_urls++;
              }
            } else {
              invalid_urls++;
              remainingText.push(url);
            }
          } else {
            remainingText.push(url);
          }
        }
        
        if (invalid_urls) {
          getErrorNotificationMessage(invalid_urls === 1 ? "Sorry, the URL you entered is not valid. Please provide a valid URL." : "Invalid URLs detected. Check your entries and provide valid URLs.");
        }

        if (duplicate_urls && !duplicateError) {
          getErrorNotificationMessage("The URL you provided is already in the list. Please enter a different URL.");
        }

        setFieldValue("single_url", remainingText.join(' '));
  
        if (validUrls.length > 0) {
          if (enteredUrls.length + validUrls.length > max_urls) {
            getErrorNotificationMessage(`Maximum limit reached! You can only have up to ${max_urls} URLs. (${max_urls - enteredUrls.length} remaining)`);
            return  {validUrls, urls};
          } else {
            setEnteredUrls([...enteredUrls, ...validUrls]);
            return  {validUrls, urls};
          }
        } else if (enteredUrls.length > max_urls || (enteredUrls.length >= max_urls && formRef.current.values.single_url != "")) {
          getErrorNotificationMessage(`Maximum limit reached! You can only have up to ${max_urls} URLs.`);
          return  {validUrls, urls};
        }
      }
    }
  
    let data = await processFirstIfBlock();
    if(data)
    {
      if (type === "submit" && data.validUrls.length > 0 && (data.validUrls.length === data.urls.length - 1)) {
        dispatch(fineTuneSaveStart({
          import_url: [...enteredUrls, data.validUrls],
          onboarding: props.onboarding,
          bot_id: props.onboardingBot.bot_id ?? 0
        }));
      }
    }
    else {
      type === "submit" && enteredUrls.length > 0 && formRef.current.values.single_url == "" && dispatch(fineTuneSaveStart({
        import_url: enteredUrls,
        onboarding: props.onboarding,
        bot_id: props.onboardingBot.bot_id ?? 0
      }));
    }
  };
  
  const removeUrl = (urlToRemove) => {
    const updatedUrls = enteredUrls.filter((url) => url !== urlToRemove);
    setEnteredUrls(updatedUrls);
  };

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.importWebpage}
        onHide={props.closeImportWebpageModal}
      >
        <Modal.Body>
          <h4>{t("web.heading")}</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeImportWebpageModal()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 11 11"
            >
              <path
                fill="#979BA2"
                d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
              ></path>
            </svg>
          </Button>
          <Formik
            initialValues={{
              // name: "",
              description: "",
              import_url: "",
              single_url: "",
            }}
            validationSchema={!props.onboarding ? Yup.object().shape({
              description: Yup.string().matches(/^[^<>]*$/, t('web.about.invalid'))
            }) : validationSchema}
            innerRef={formRef}
            // onSubmit={saveTune}
          >
            {({ setFieldValue, values, isValid }) => (
              <FORM noValidate className="onboarding-form">
                {/* <Form className="onboarding-form"> */}
                {/* <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    {t("web.name.label")} <span>*</span>
                  </Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder={t("web.name.placeholder")}
                    name="name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="errorMsg"
                  />
                </Form.Group> */}
                <Form.Label>
                  {t("web.website.label")} <span>*</span>
                </Form.Label>
                <div className="mb-3">
                  <Form.Group>
                    {props.onboarding ? (
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          https://
                        </InputGroup.Text>
                        <Field
                          className="form-control"
                          placeholder={t("web.website.placeholder")}
                          aria-label="Website"
                          aria-describedby="basic-addon1"
                          name="import_url"
                        />
                      </InputGroup>
                    ) : (
                      <InputGroup className="url-tabs form-control">
                        {enteredUrls.map((url, index) => (
                          <div key={index} className="url-tab">
                            {url}
                            <span
                              className="remove-url"
                              onClick={() => removeUrl(url)}
                            >
                              &times;
                            </span>
                          </div>
                        ))}
                        <Field
                          type="textarea"
                          className=""
                          placeholder={t("web.website.placeholder")}
                          aria-label="Website"
                          aria-describedby="basic-addon1"
                          name="single_url"
                          onChange={(e) => {
                            setFieldValue("single_url", e.target.value)
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === "," || e.key === " ") {
                              e.preventDefault();
                              enteredUrls.length <= max_urls &&
                                e.target.value.length &&
                                handleUrlInputChange(
                                  e.target.value + " ",
                                  setFieldValue
                                );
                              }
                            }}
                          onPaste={(e) => {
                            e.preventDefault();
                            enteredUrls.length < max_urls ?
                              handleUrlInputChange(
                                e.clipboardData.getData("text") + " ",
                                setFieldValue
                              ) : 
                              getErrorNotificationMessage(`Maximum limit reached! You can only have up to ${max_urls} URLs.`);
                          }}
                          value={values.single_url}
                        />
                      </InputGroup>
                    )}
                    {!props.onboarding && <Form.Text className="text-note">
                      Note : Please make sure each URL you add is both unique and in valid format to avoid any hiccups. We have a limit of {max_urls} URLs & comma/space separated values are treated as different URLs.
                    </Form.Text>}
                  </Form.Group>
                  <ErrorMessage
                    component={"div"}
                    name="import_url"
                    className="errorMsg"
                  />
                </div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("web.about.label")}</Form.Label>
                  <Field
                    className="form-control"
                    as="textarea"
                    rows={3}
                    placeholder={t("web.about.placeholder")}
                    name="description"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="description"
                    className="errorMsg"
                  />
                </Form.Group>
                <div className="onboarding-import-webpage-modal-btn-sec">
                  <Button
                    className="onboarding-cancel-btn"
                    onClick={() => props.closeImportWebpageModal()}
                    disabled={fineTuneSave.buttonDisable}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    onClick={()=> {
                      props.onboarding ? isValid && saveTune(values) : (enteredUrls.length > 0 || values.single_url) ? handleUrlInputChange(values.single_url+" ", setFieldValue, "submit") : getErrorNotificationMessage("Upload atleast one URL")
                    }}
                    className="onboarding-save-btn"
                    type="submit"
                    disabled={fineTuneSave.buttonDisable}
                  >
                    {fineTuneSave.buttonDisable ? t("loading") : t("add")}
                  </Button>
                </div>
                {/* </Form> */}
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ImportWebpageModal;

