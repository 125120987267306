import React, { useEffect, useState, useRef } from "react";
import { Button, Image, Modal, InputGroup, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { changePasswordStart } from "../../store/slices/AdminSlice";
import useLogout from "../../hooks/useLogout";

const ChangePasswordModal = (props) => {
  const t = useTranslation("change_password");
  const dispatch = useDispatch();
  const changePassword = useSelector((state) => state.admin.changePassword);
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef();
  const { logout } = useLogout();
  const [showPassword, setShowPassword] = useState({
    old: false,
    password: false,
    confirm: false,
  });

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password")
      .required("Required"),
    password: Yup.string()
      .required("Required")
      .matches(/^(?=\S*$)/, "Whitespaces not allowed")
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password")
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          "New password should not be same as old password"
        ),
      }),
    password_confirmation: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, "Invalid password")
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Confirm password should be same as new pasword"
        ),
      }),
  });

  const handleSubmit = (values) => dispatch(changePasswordStart(values));

  const onCancel = () => {
    if (formRef.current) formRef.current.resetForm();
  };

  useEffect(() => {
    if (
      !skipRender &&
      !changePassword.loading &&
      Object.keys(changePassword.data).length > 0
    ) {
      logout();
    }
    setSkipRender(false);
  }, [changePassword]);

  return (
    <>
      <Modal
        className="modal-dialog-center import-webpage-modal"
        size="md"
        centered
        show={props.newChangePassword}
        onHide={props.closeNewChangePasswordModal}
      >
        <Modal.Body>
          <h4>Change Password</h4>
          <Button
            className="modal-close"
            onClick={() => props.closeNewChangePasswordModal()}
          >
            <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="none"
      viewBox="0 0 11 11"
    >
      <path
        fill="#979BA2"
        d="M10.756.252a.83.83 0 00-1.176 0L5.5 4.324 1.42.244A.83.83 0 10.244 1.42l4.08 4.08-4.08 4.08a.83.83 0 101.176 1.176l4.08-4.08 4.08 4.08a.831.831 0 101.176-1.176L6.676 5.5l4.08-4.08a.836.836 0 000-1.168z"
      ></path>
    </svg>
          </Button>
          <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            <FORM className="onboarding-form">
              <Form.Label>Current Password <span>*</span></Form.Label>
              <div className="mb-3">
                <InputGroup className="register-input-group">
                  <Field
                    type={showPassword.old ? "text" : "password"}
                    className="form-control"
                    placeholder="****************"
                    name="old_password"
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        old: !showPassword.old,
                      })
                    }
                  >
                    <Image
                      className="register-input-group-eye-icon"
                      src={
                        showPassword.old
                          ? window.location.origin +
                            "/images/onboarding/eye-visible-off-icon.svg"
                          : window.location.origin +
                            "/images/onboarding/eye-visible-on-icon.svg"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  component={"div"}
                  name="old_password"
                  className="errorMsg"
                />
              </div>
              <Form.Label>
                New Password <span>*</span>
              </Form.Label>
              <div className="mb-3">
                <InputGroup className="register-input-group">
                  <Field
                    type={showPassword.password ? "text" : "password"}
                    className="form-control"
                    placeholder="****************"
                    name="password"
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password,
                      })
                    }
                  >
                    <Image
                      className="register-input-group-eye-icon"
                      src={
                        showPassword.password
                          ? window.location.origin +
                            "/images/onboarding/eye-visible-off-icon.svg"
                          : window.location.origin +
                            "/images/onboarding/eye-visible-on-icon.svg"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  component={"div"}
                  name="password"
                  className="errorMsg"
                />
              </div>
              <Form.Label>
                Confirm Password <span>*</span>
              </Form.Label>
              <div className="mb-3">
                <InputGroup className="register-input-group">
                  <Field
                    type={showPassword.confirm ? "text" : "password"}
                    className="form-control"
                    placeholder="****************"
                    name="password_confirmation"
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirm: !showPassword.confirm,
                      })
                    }
                  >
                    <Image
                      className="register-input-group-eye-icon"
                      src={
                        showPassword.confirm
                          ? window.location.origin +
                            "/images/onboarding/eye-visible-off-icon.svg"
                          : window.location.origin +
                            "/images/onboarding/eye-visible-on-icon.svg"
                      }
                    />
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  component={"div"}
                  name="password_confirmation"
                  className="errorMsg"
                />
              </div>
              <div className="admin-ui-profile-btn-sec">
                <Button
                  className="onboarding-cancel-btn"
                  onClick={() => props.closeNewChangePasswordModal()}
                >
                  Cancel
                </Button>
                <Button className="onboarding-save-btn" type="submit" disabled={changePassword.buttonDisable}>
                  {changePassword.buttonDisable ? "Loading" : "Submit"}
                </Button>
              </div>
            </FORM>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
