import React from "react";

const useDesign = () => {

  const getChatTypeName = (type) => {
    switch (type) {
      case 1:
        return "admin-reacent-message-fresh-chat-badge";
      case 3:
        return "admin-reacent-message-zen-desk-badge";
      case 4:
        return "admin-reacent-message-live-chat-badge";
      case 5:
        return "admin-reacent-message-sales-force-badge";
      case 6:
        return "admin-reacent-message-fresh-desk-badge";
      default:
        return "admin-reacent-message-fresh-chat-badge";
    }
  };

  const getChatTypeBg = (type) => {
    switch (type) {
      case 1:
        return "bg-fresh-chat";
      case 3:
        return "bg-zen-desk";
      case 4:
        return "bg-live-chat";
      case 5:
        return "bg-sales-force";
      case 6:
        return "bg-fresh-desk";
      default:
        return "bg-fresh-chat";
    }
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #00337D24!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
      cursor:"pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#048A89",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#048A89",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#048A89!important",
      display: "none",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#048A89!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#048A89",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  return {
    getChatTypeName,
    getChatTypeBg,
    customStyles,
  };
};

export default useDesign;
