import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import {
  singleBotViewStart,
} from "../../../store/slices/BotSlice";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation, withTranslation } from "react-multi-lang";
// import { DateRangePicker } from "materialui-daterange-picker";


const SettingAnalyticsIndex = (props) => {

  const t = useTranslation("settings.analytics")
  const dispatch = useDispatch();
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const singleBotView = useSelector((state) => state.bots.singleBotView);

  // const [open, setOpen] = React.useState(true);
  // const [dateRange, setDateRange] = React.useState({});

  useEffect(()=>{
    if(Object.keys(selectedBot).length>0)
      dispatch(singleBotViewStart({bot_id : selectedBot.bot_id}))
  }, [selectedBot]);

  return (
    <>
      <div className="new-setting-analytics-sec analytcs-seeting-page-sec">
        <div className="new-setting-analytics-header-sec">
          <h3>{t("heading")}</h3>
          {/* <div className="new-setting-analytics-calander-sec">
            <DateRangePicker
              open={open}
              onChange={(range) => setDateRange(range)}
            />

          </div> */}
        </div>
        <div className="new-setting-analytics-box">
        {singleBotView.loading ? (
          [...Array(5)].map((i) => (
            <div className="mb-3">
              <Skeleton height={10} width="100%" />
              <Skeleton height={50} width="100%" />
            </div>
          ))
        ) : 
        Object.keys(singleBotView.data).length > 0 && Object.keys(singleBotView.data.subscription).length > 0 ? (
          <>
          <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#03363d"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 11.247A12.012 12.012 0 1012.017 24H19a5.005 5.005 0 005-5v-7.753zM22 19a3 3 0 01-3 3h-6.983a10.041 10.041 0 01-7.476-3.343 9.917 9.917 0 01-2.476-7.814 10.043 10.043 0 018.656-8.761 10.564 10.564 0 011.3-.082A9.921 9.921 0 0118.4 4.3a10.041 10.041 0 013.6 7.042z"></path>
                  <path d="M8 9h4a1 1 0 000-2H8a1 1 0 000 2zM16 11H8a1 1 0 000 2h8a1 1 0 000-2zM16 15H8a1 1 0 000 2h8a1 1 0 000-2z"></path>
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 22 22"
                >
                  <path
                    fill="#03363D"
                    d="M3.22 3.223a10.999 10.999 0 0115.557 0c3.457 3.455 4.11 8.592 2.067 12.684l1.133 4.98a.904.904 0 01-1.082 1.081l-4.984-1.132c-7.247 3.616-15.913-1.62-15.913-9.84 0-2.936 1.144-5.697 3.222-7.773zm3.19 10.512h4.788a.903.903 0 100-1.807H6.409a.903.903 0 100 1.806zm0-3.67h9.178a.903.903 0 100-1.806H6.41a.903.903 0 100 1.806z"
                  ></path>
                </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>{t("message")}</h6>
                <h4>{singleBotView.data.bot.chat_messages_count} / {singleBotView.data.total_chat_messages_count}</h4>
              </div>
            </div>
            {/* <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed={singleBotView.data.bot.chat_messages_count} maxCompleted={singleBotView.data.subscription.total_tokens} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>{singleBotView.data.subscription.total_tokens - singleBotView.data.bot.chat_messages_count} {t("remaining")}</p>
            </div> */}
          </div>
          <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#03363d"
                    data-name="Layer 1"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 10H7V7h1.5C10.43 7 12 5.43 12 3.5S10.43 0 8.5 0h-5C1.57 0 0 1.57 0 3.5S1.57 7 3.5 7H5v3c-2.757 0-5 2.243-5 5v4c0 2.757 2.243 5 5 5h14c2.757 0 5-2.243 5-5v-4c0-2.757-2.243-5-5-5zM2 3.5C2 2.673 2.673 2 3.5 2h5c.827 0 1.5.673 1.5 1.5S9.327 5 8.5 5h-5C2.673 5 2 4.327 2 3.5zM5 12h14c1.654 0 3 1.346 3 3v3H2v-3c0-1.654 1.346-3 3-3zm14 10H5a3.004 3.004 0 01-2.828-2h19.656A3.004 3.004 0 0119 22zM9 15a1 1 0 112 0 1 1 0 01-2 0zm-4 0a1 1 0 112 0 1 1 0 01-2 0zm8 0a1 1 0 112 0 1 1 0 01-2 0zm4 0a1 1 0 112 0 1 1 0 01-2 0z"></path>
                  </svg> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#03363D"
                      fillRule="evenodd"
                      d="M2.741 11.495H9.32c.896 0 1.624-.72 1.624-1.604v-4.1c0-1.02-.66-2.216-1.882-2.422a.506.506 0 01-.418-.583.513.513 0 01.59-.413c.7.118 1.273.452 1.71.9V1.097c-.83-.67-1.999-.815-2.976-.351A2.76 2.76 0 006.4 3.469a2.776 2.776 0 002.03 2.408.504.504 0 01.356.622.513.513 0 01-.63.352C6.633 6.433 5.518 5.11 5.378 3.558a3.684 3.684 0 01.007-.725 3.54 3.54 0 00-1.875 1.64 3.503 3.503 0 00-.069 3.179.503.503 0 01-.241.674.516.516 0 01-.684-.238 4.483 4.483 0 01-.41-1.404A2.519 2.519 0 00.616 8.2a2.459 2.459 0 00.566 2.543c.413.424.966.691 1.558.753zm3.215-4.31a.517.517 0 01.714-.115.5.5 0 01.118.706c-.3.412-.34.948-.101 1.398.235.446.694.721 1.202.721h.024c.28 0 .508.222.512.499a.51.51 0 01-.506.512h-.03a2.372 2.372 0 01-2.11-1.265 2.315 2.315 0 01.177-2.455zm-3.85 10.131c.06-.482.196-.956.412-1.403a.516.516 0 01.683-.239c.255.12.363.423.241.675a3.503 3.503 0 00.07 3.179 3.54 3.54 0 001.874 1.639 3.689 3.689 0 01-.007-.725c.14-1.552 1.255-2.875 2.775-3.293a.513.513 0 01.63.352.505.505 0 01-.355.623A2.776 2.776 0 006.4 20.53a2.76 2.76 0 001.568 2.723 2.835 2.835 0 002.976-.351v-2.176a3.077 3.077 0 01-1.71.9.51.51 0 01-.591-.414.506.506 0 01.419-.582c1.222-.207 1.882-1.401 1.882-2.421v-4.1c0-.885-.728-1.604-1.624-1.604l-.003-.001-6.572.001a2.538 2.538 0 00-1.56.752A2.46 2.46 0 00.615 15.8c.245.7.8 1.254 1.49 1.515zm3.672-2.957a2.373 2.373 0 012.141-1.264.508.508 0 01.506.511.509.509 0 01-.512.5h-.024c-.508 0-.967.275-1.202.72-.238.45-.2.986.1 1.398a.5.5 0 01-.117.706.513.513 0 01-.714-.116 2.314 2.314 0 01-.178-2.455zm8.451 8.65h-2.261v-5.377c.134.01.269.014.405.014 3.158 0 5.718-2.527 5.718-5.644 0-3.118-2.56-5.645-5.718-5.645-.136 0-.271.005-.405.014V.995h2.261l.443 2.213 2.373.972 1.9-1.257 2.625 2.592-1.273 1.874.984 2.344 2.243.436v3.665l-2.243.437-.984 2.343 1.273 1.875-2.625 2.591-1.9-1.257-2.374.972-.442 2.213z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>{t("fine_tuning")}</h6>
                <h4>{singleBotView.data.bot.fine_tuning_models_count ?? 0} / {singleBotView.data.total_fine_tuning_models_count}</h4>
              </div>
            </div>
            {/* <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed={singleBotView.data.bot.fine_tuning_models_count ?? 0} maxCompleted={singleBotView.data.subscription.total_fine_tunings} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>{singleBotView.data.subscription.total_fine_tunings - (singleBotView.data.bot.fine_tuning_models_count ?? 0)} {t("remaining")}</p>
            </div> */}
          </div>
          {/* <div className="new-setting-analytics-card">
            <div className="new-setting-analytics-top-sec">
              <div className="new-setting-analytics-icon-sec">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 22 22"
                >
                  <path
                    fill="#03363D"
                    d="M3.22 3.223a10.999 10.999 0 0115.557 0c3.457 3.455 4.11 8.592 2.067 12.684l1.133 4.98a.904.904 0 01-1.082 1.081l-4.984-1.132c-7.247 3.616-15.913-1.62-15.913-9.84 0-2.936 1.144-5.697 3.222-7.773zm3.19 10.512h4.788a.903.903 0 100-1.807H6.409a.903.903 0 100 1.806zm0-3.67h9.178a.903.903 0 100-1.806H6.41a.903.903 0 100 1.806z"
                  ></path>
                </svg>
              </div>
              <div className="new-setting-analytics-info-sec">
                <h6>Chat</h6>
                <h4>231 / 3,000</h4>
              </div>
            </div>
            <div className="new-setting-analytics-progress-bar-sec">
              <ProgressBar completed="231" maxCompleted={3000} height="10px" isLabelVisible={false}/>
            </div>
            <div className="new-setting-analytics-bottom-sec">
                <p>2,389 Remaining</p>
            </div>
          </div> */}
          </>
        ) : (
          <NoDataFound />
        )}
        </div>
      </div>
    </>
  );
};

export default withTranslation(SettingAnalyticsIndex);
