import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Image, Nav, Row, Col, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import Toggle from "react-styled-toggle";
import InboxChat from "./InboxChat";
import {
  chatListStart,
  disableChatNotify,
  setHighlightChat,
  setSelectedChats,
} from "../../store/slices/ChatSlice";
import PageLoader from "../Helper/PageLoader";
import SomethingWentWrong from "../Helper/SomethingWentWrong";
import Skeleton from "react-loading-skeleton";
import { profileStart, selectedBotSuccess } from "../../store/slices/AdminSlice";
import Pagination from "../Helper/Pagination";
import { Field, Form as FORM, Formik } from "formik";
import NoDataFound from "../Helper/NoDataFound";
import useDesign from "../../hooks/useDesign";
import NotChatFound from "../Helper/NoChatFound";
import { botsListStart } from "../../store/slices/BotSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import { useTranslation } from "react-multi-lang";
import NoBotExists from "../Helper/NoBotExists";
import AddBotModal from "../Bots/AddBotModal";
import ChatPreviewLoader from "../Helper/ChatPreviewLoader";
import ChatLoader from "./ChatLoader";

const NewChatInbox = (props) => {
  const { getChatTypeName, getChatTypeBg } = useDesign();
  const t = useTranslation("chats.filter");

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "max-content",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #00337D24!important",
      borderRadius: "0!important",
      boxShadow: "none!important",
      cursor:"pointer",
      height: "45px",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#048A89",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#048A89",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#048A89!important",
      display: "none",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#048A89!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#048A89",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
        '&:active': { backgroundColor: isSelected ? "#f7f7f7" : "#fff" }
      };
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const profile = useSelector((state) => state.admin.profile);
  const chatList = useSelector((state) => state.chat.chatList);
  const selectedChats = useSelector((state) => state.chat.selectedChats);
  const botsList = useSelector((state) => state.bots.botsList);
  const selectedBot = useSelector((state) => state.admin.selectedBot);
  const selectedInboxBot = useSelector((state) => state.chat.selectedInboxBot);
  const botsStore = useSelector((state) => state.bots.botsStore);
  const [skipRender, setSkipRender] = useState(true);
  const [unreadChat, setUnreadChat] = useState(0);
  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );
  const [initialChat, setInitialChat] = useState(true);
  const [chatId, setChatId] = useState("");
  const [bots, setBots] = useState([]);
  const [selectedBotDetail, setSelectedBotDetail] = useState({
    label: "All Chats",
    value: "",
  });
  const [selectedFilter, setSelectedFilter] = useState();
  const [addBot, setAddBot] = useState(false);

  const closeAddBotModal = () => {
    setAddBot(false);
  };

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
    order_by: searchParams.get("order_by") ? searchParams.get("order_by") : "",
  });

  const options = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];

  useEffect(() => {
    if(!Object.keys(botsList.data).length)
    dispatch(botsListStart());
  }, [])

  useEffect(() => {
    let newValues = {...filter};
    if (!profile.loading && Object.keys(profile.data).length > 0 && !skipRender) {
      if(Object.keys(selectedInboxBot).length > 0)
        newValues = {...newValues, bot_id: selectedInboxBot.value,}
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
      (searchParams.get("order_by") && searchParams.get("order_by") != 'null') && setSelectedFilter({
        label: t(`${searchParams.get("order_by")}`),
        value: searchParams.get("order_by"),
      });
    }
  }, [profile, page, filter]);

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
    }
  }, [searchParams.get("page")]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    params += searchParams.get("status")
      ? `status=${searchParams.get("status")}&`
      : "";
    navigate(`/inbox?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender && !chatList.loading && Object.keys(chatList.data).length > 0) {
      setUnreadChat(chatList.data.un_read_chats_count ?? 0);
      if (chatList.data.chats.length <= 0)
        setChatId("");
      else if(!chatId){
        setChatId(chatList.data.chats[0].chat_id ?? 0);
      }
    }
  }, [chatList]);

  useEffect(() => {
    if (initialChat && chatId) {
      dispatch(disableChatNotify(chatId));
      setInitialChat(false)
    }
  }, [chatId]);

  useEffect(() => {
    let newValues = {...filter};
    if (
      !botsList.loading &&
      Object.keys(botsList.data).length > 0 &&
      botsList.data.bots.length > 0
    ) {
      if(Object.keys(selectedInboxBot).length > 0)
        newValues = {...newValues, bot_id: selectedInboxBot.value,}
      dispatch(
        chatListStart({
          skip: 5 * (page - 1),
          take: 5,
          ...newValues,
        })
      );
    }
  }, [botsList]);

  useEffect(() => {
    if (
      !skipRender &&
      Object.keys(selectedInboxBot).length > 0
    ) {
      dispatch(
        chatListStart({
          skip: 0,
          take: 5,
          bot_id: selectedInboxBot.value,
          ...filter,
        })
      );
      setChatId("");
    }
    setSkipRender(false);
  }, [selectedInboxBot]);

  useEffect(()=> {
    if(!skipRender && !botsStore.loading && Object.keys(botsStore.data).length > 0 && Object.keys(botsStore.data.bot).length > 0) {
      dispatch(selectedBotSuccess(botsStore.data.bot));
    }
    setSkipRender(false);
  }, [botsStore])

  return profile.loading || chatList.buttonDisable ? (
    <ChatLoader />
  ) : Object.keys(profile.data).length > 0 ? (
    <>
    {Object.keys(selectedBot).length > 0 ? (
      <div className="admin-ui-setting-body-sec">
        <div className="new-settings-sec">
          <div className="new-settings-tab-sec">
            <Tab.Container id="left-tabs-example" defaultActiveKey="inbox">
              <Row>
                <Col sm={12}>
                  <div className="new-settings-tab-nav-link-sec">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=customize")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 18 19"
                          >
                            <path
                              d="M15.81 9.5c0-.215-.008-.42-.027-.636l1.708-1.318a.952.952 0 00.238-1.216l-1.716-3.02a.91.91 0 00-.5-.42.89.89 0 00-.648.027l-1.973.851a6.931 6.931 0 00-1.074-.636L11.55.972a.927.927 0 00-.909-.823H7.219A.929.929 0 006.3.972l-.266 2.16a6.936 6.936 0 00-1.074.636l-1.974-.851a.898.898 0 00-1.147.393L.122 6.34a.954.954 0 00.239 1.215l1.707 1.319c-.038.42-.038.842 0 1.262L.361 11.454a.952.952 0 00-.239 1.216l1.717 3.02c.23.412.725.58 1.147.393l1.974-.85c.34.242.698.457 1.074.635l.266 2.16c.055.468.45.823.91.823h3.423a.927.927 0 00.91-.823l.265-2.16a6.94 6.94 0 001.074-.636l1.974.851a.898.898 0 001.147-.393l1.717-3.02a.954.954 0 00-.238-1.216l-1.708-1.318c.028-.215.037-.42.037-.636zm-6.847 3.273c-1.772 0-3.213-1.468-3.213-3.273 0-1.805 1.44-3.273 3.213-3.273 1.771 0 3.213 1.468 3.213 3.273 0 1.805-1.442 3.273-3.213 3.273z"
                            ></path>
                          </svg>
                          Customize
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=fine-tune")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 19 19"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.867 9.084H7.29c.738 0 1.339-.594 1.339-1.322v-3.38c0-.842-.544-1.827-1.552-1.997a.417.417 0 01-.345-.48.423.423 0 01.488-.342 2.537 2.537 0 011.409.743V.512a2.336 2.336 0 00-2.453-.29 2.276 2.276 0 00-1.293 2.245 2.289 2.289 0 001.673 1.985.416.416 0 01.293.513.423.423 0 01-.52.29 3.128 3.128 0 01-2.287-2.714 3.037 3.037 0 01.006-.598c-.66.25-1.207.722-1.546 1.351a2.888 2.888 0 00-.057 2.621c.1.208.012.457-.199.556a.426.426 0 01-.563-.196 3.696 3.696 0 01-.34-1.157A2.077 2.077 0 00.116 6.367a2.027 2.027 0 00.468 2.096c.34.35.796.57 1.284.62zm2.65-3.553c.137-.187.4-.23.59-.095.189.134.232.395.096.582-.247.34-.28.782-.083 1.153.193.367.572.594.99.594h.02c.231 0 .42.182.423.411a.42.42 0 01-.417.422h-.025c-.736 0-1.4-.398-1.74-1.042a1.908 1.908 0 01.146-2.025zm-3.173 8.352c.049-.398.16-.789.339-1.157a.426.426 0 01.563-.197c.21.1.3.348.2.556-.402.83-.381 1.809.056 2.621.339.63.886 1.102 1.546 1.351a3.04 3.04 0 01-.006-.598 3.128 3.128 0 012.288-2.714.423.423 0 01.52.29.416.416 0 01-.294.513 2.288 2.288 0 00-1.673 1.985 2.275 2.275 0 001.293 2.245c.804.382 1.769.263 2.453-.29v-1.793c-.36.37-.832.644-1.41.742a.42.42 0 01-.488-.341.417.417 0 01.346-.48c1.008-.17 1.552-1.156 1.552-1.997v-3.38c0-.729-.6-1.322-1.34-1.322H1.87c-.49.05-.946.27-1.287.62a2.028 2.028 0 00-.468 2.096c.202.578.66 1.034 1.229 1.25zm3.027-2.438a1.956 1.956 0 011.765-1.042c.233.002.42.192.417.421a.42.42 0 01-.423.412h-.02c-.418 0-.797.227-.99.593-.196.371-.164.814.083 1.154a.413.413 0 01-.097.581.423.423 0 01-.589-.095 1.908 1.908 0 01-.146-2.024zm6.967 7.13H9.474v-4.432c.11.008.221.012.333.012 2.604 0 4.714-2.084 4.714-4.654s-2.11-4.653-4.714-4.653c-.112 0-.223.004-.334.012V.428h1.865l.364 1.824 1.957.802 1.566-1.037 2.165 2.137-1.05 1.545.811 1.932 1.849.36v3.021l-1.849.36-.811 1.932 1.05 1.545-2.165 2.137-1.566-1.037-1.957.802-.364 1.824z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          Custom Training
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="inbox"
                          onClick={() => navigate("/inbox")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              d="M2.488 2.49a8.5 8.5 0 0112.022 0 8.49 8.49 0 011.597 9.801l.875 3.85a.698.698 0 01-.835.835l-3.852-.875C6.695 18.895-.002 14.85-.002 8.498c0-2.27.885-4.403 2.49-6.008zm2.464 8.123h3.7a.698.698 0 100-1.396h-3.7a.698.698 0 100 1.396zm0-2.835h7.093a.698.698 0 100-1.396H4.952a.698.698 0 100 1.396z"
                            ></path>
                          </svg>
                          Inbox
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=source")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              d="M.5 6.318v6.546c.001.964.353 1.888.977 2.57.625.681 1.473 1.065 2.356 1.066h9.334c.883-.001 1.73-.385 2.356-1.066a3.815 3.815 0 00.977-2.57V6.318H.5zm9.333 7.273H4.5a.64.64 0 01-.471-.213.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h5.333a.64.64 0 01.472.213.762.762 0 01.195.515c0 .192-.07.377-.195.514a.64.64 0 01-.472.213zm2.667-2.91h-8a.64.64 0 01-.471-.212.762.762 0 01-.196-.514c0-.193.07-.378.196-.515a.64.64 0 01.471-.213h8a.64.64 0 01.471.213.762.762 0 01.196.515c0 .192-.07.377-.196.514a.64.64 0 01-.471.213zm4-5.817v-.728a3.815 3.815 0 00-.977-2.57C14.898.885 14.05.501 13.167.5H3.833c-.883.001-1.73.385-2.356 1.066A3.815 3.815 0 00.5 4.136v.728h16zm-9.333-2.91c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213zm-2 0c.132 0 .26.043.37.123.11.08.195.194.246.327.05.132.063.279.038.42a.75.75 0 01-.183.372.652.652 0 01-.341.2.615.615 0 01-.385-.042.683.683 0 01-.3-.268.775.775 0 01.083-.918.64.64 0 01.472-.213z"
                            ></path>
                          </svg>
                          Source
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => navigate("/settings?tab=analytics")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              d="M4.781 9.563H1.594a.531.531 0 00-.532.53v5.313c0 .294.238.531.532.531H4.78a.531.531 0 00.532-.53v-5.313a.531.531 0 00-.532-.531zM15.406 5.313H12.22a.531.531 0 00-.531.53v9.563c0 .294.237.531.53.531h3.188a.531.531 0 00.531-.53V5.843a.531.531 0 00-.53-.532zM10.094 1.063H6.906a.531.531 0 00-.531.53v13.813c0 .294.238.531.531.531h3.188a.531.531 0 00.531-.53V1.593a.531.531 0 00-.531-.532z"
                            ></path>
                          </svg>
                          Analytics
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
          <div className="new-settings-box">
            <div className="new-inbox-tab-sec">
              <div className="new-chat-inbox-sec">
                <div className="new-chat-inbox-header-sec">
                  <div className="new-chat-inbox-header-left-sec">
                    <h3>
                      Inbox {chatList.loading ? <Skeleton count={1} height={20} width={20} /> : <span className="count">{unreadChat}</span>}
                    </h3>
                  </div>
                  <div className="new-chat-inbox-header-right-sec">
                    <div className="new-billing-search-sec">
                      <Formik
                        initialValues={{
                          search_key: searchParams.get("search_key")
                            ? searchParams.get("search_key")
                            : "",
                          order_by: searchParams.get("order_by")
                            ? searchParams.get("order_by")
                            : filter.order_by,
                        }}
                        onSubmit={(val) => {
                          setFilter({
                            ...filter,
                            search_key: val.search_key,
                          });
                          navigate(
                            `/inbox?search_key=${
                              val.search_key
                            }&order_by=${searchParams.get("order_by")}`
                          );
                        }}
                      >
                        {({ values, setFieldValue, resetForm }) => (
                          <FORM className="form">
                            <InputGroup className="mb-0">
                              <Field
                                placeholder="Search chats"
                                type="text"
                                className="form-control"
                                name="search_key"
                              />
                              {searchParams.get("search_key") && (
                                <InputGroup.Text id="basic-addon2">
                                  <Button
                                    className="search-btn"
                                    type="button"
                                    onClick={() => {
                                      setFieldValue("search_key", "");
                                      setFilter({
                                        ...filter,
                                        search_key: "",
                                      });
                                      navigate(
                                        `/inbox?order_by=${searchParams.get(
                                          "order_by"
                                        )}`
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#3E4351"
                                      data-name="Layer 1"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M16.707 8.707L13.414 12l3.293 3.293-1.414 1.414L12 13.414l-3.293 3.293-1.414-1.414L10.586 12 7.293 8.707l1.414-1.414L12 10.586l3.293-3.293 1.414 1.414zM24 12c0 6.617-5.383 12-12 12S0 18.617 0 12 5.383 0 12 0s12 5.383 12 12zm-2 0c0-5.514-4.486-10-10-10S2 6.486 2 12s4.486 10 10 10 10-4.486 10-10z"></path>
                                    </svg>
                                  </Button>
                                </InputGroup.Text>
                              )}
                              <InputGroup.Text id="basic-addon2">
                                <Button className="search-btn" type="submit">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="#3E4351"
                                    x="0"
                                    y="0"
                                    enableBackground="new 0 0 513.749 513.749"
                                    version="1.1"
                                    viewBox="0 0 513.749 513.749"
                                    xmlSpace="preserve"
                                  >
                                    <path d="M504.352 459.061l-99.435-99.477c74.402-99.427 54.115-240.344-45.312-314.746S119.261-9.277 44.859 90.15-9.256 330.494 90.171 404.896c79.868 59.766 189.565 59.766 269.434 0l99.477 99.477c12.501 12.501 32.769 12.501 45.269 0 12.501-12.501 12.501-32.769 0-45.269l.001-.043zm-278.635-73.365c-88.366 0-160-71.634-160-160s71.634-160 160-160 160 71.634 160 160c-.094 88.326-71.673 159.906-160 160z"></path>
                                  </svg>
                                </Button>
                              </InputGroup.Text>
                            </InputGroup>
                          </FORM>
                        )}
                      </Formik>
                    </div>
                    <div className="new-chat-inbox-filter-sec">
                      <Select
                        options={options}
                        styles={customStyles}
                        // menuIsOpen={true}
                        value={selectedFilter}
                        isSearchable={false}
                        onChange={(selectedOption) => {
                          setFilter({
                            ...filter,
                            order_by: selectedOption.value,
                          });
                          navigate(
                            `/inbox?search_key=${filter.search_key}&order_by=${selectedOption.value}`
                          );
                        }}
                        placeholder={
                          <div className="placeholder-flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="#3E4351"
                              viewBox="0 0 24 24"
                            >
                              <path d="M10 6h13a1 1 0 000-2H10a1 1 0 000 2zM23 11H10a1 1 0 000 2h13a1 1 0 000-2zM23 18H10a1 1 0 000 2h13a1 1 0 000-2zM6.087 6a.5.5 0 00.353-.854L4 2.707a1 1 0 00-1.414 0L.147 5.146A.5.5 0 00.5 6h1.794v12H.5a.5.5 0 00-.354.854l2.44 2.439a1 1 0 001.414 0l2.44-2.439A.5.5 0 006.087 18H4.294V6z"></path>
                            </svg>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="new-chat-inbox-body-sec">
                  <div className="new-chat-inbox-list-sec">
                    {chatList.loading ? (
                      [...Array(5)].map((i) => (
                        <>
                          <Skeleton count={1} height={100} />
                        </>
                      ))
                    ) : Object.keys(chatList.data).length > 0 &&
                      chatList.data.chats.length > 0 ? (
                      <>
                        <div className="new-chat-inbox-list-box">
                          {chatList.data.chats
                            .filter(
                              (chat) =>
                                selectedBotDetail.value == "" ||
                                chat.bot_id == selectedBotDetail.value
                            )
                            .map((chat, i) => (
                              <div
                                className={`new-chat-inbox-list-card ${
                                  chatId == chat.chat_id && "active"
                                }`}
                                onClick={() => {
                                  setChatId(chat.chat_id);
                                  // setUnreadChat(
                                  //   chat.notify && unreadChat > 0
                                  //     ? unreadChat - 1
                                  //     : unreadChat
                                  // );
                                  dispatch(disableChatNotify(chat.chat_id));
                                }}
                              >
                                {chat.notify ? (
                                  <div className="pulsating-circle"></div>
                                ) : null}
                                <div className="new-chat-inbox-list-user-details-sec">
                                  <div
                                    className={`new-chat-preview-user-img-sec ${getChatTypeBg(
                                      chat.widget_type
                                    )}`}
                                  >
                                    <Image
                                      className="new-chat-preview-user-img"
                                      src={
                                        window.location.origin +
                                        "/images/your-bot/your-bot-img.svg"
                                      }
                                    />
                                  </div>
                                  <div className="new-chat-inbox-list-user-details-info">
                                    <h4>
                                      {chat.chat_unique_id}{" "}
                                      <span className="count">
                                        {chat.chat_messages_count}
                                      </span>
                                    </h4>
                                    {chat.email ? (
                                      <h4>
                                        Email :
                                        <p>
                                          {chat.email}
                                          <CopyToClipboard
                                            text={chat.email}
                                            onCopy={() =>
                                              getSuccessNotificationMessage(
                                                "Email copied to clipboard!"
                                              )
                                            }
                                          >
                                            <Button
                                              type="button"
                                              className="search-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                fill="#3E4351"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        </p>
                                      </h4>
                                    ) : null}
                                    {chat.contact ? (
                                      <h4>
                                        Contact :
                                        <p>
                                          {chat.contact}
                                          <CopyToClipboard
                                            text={chat.contact}
                                            onCopy={() =>
                                              getSuccessNotificationMessage(
                                                "Contact copied to clipboard!"
                                              )
                                            }
                                          >
                                            <Button
                                              type="button"
                                              className="search-btn"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="22"
                                                height="22"
                                                fill="#3E4351"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M21.155 3.272L18.871.913A3.02 3.02 0 0016.715 0H12a5.009 5.009 0 00-4.9 4H7a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h6a5.006 5.006 0 005-5v-.1a5.009 5.009 0 004-4.9V5.36a2.988 2.988 0 00-.845-2.088zM13 22H7a3 3 0 01-3-3V9a3 3 0 013-3v8a5.006 5.006 0 005 5h4a3 3 0 01-3 3zm4-5h-5a3 3 0 01-3-3V5a3 3 0 013-3h4v2a2 2 0 002 2h2v8a3 3 0 01-3 3z"></path>
                                              </svg>
                                            </Button>
                                          </CopyToClipboard>
                                        </p>
                                      </h4>
                                    ) : null}
                                    {Object.keys(chat.last_chat_message)
                                      .length > 0 &&
                                      (chat.last_chat_message.message ? (
                                        <p>{chat.last_chat_message.message}</p>
                                      ) : <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="#03363d"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M19.5 0h-15A4.505 4.505 0 000 4.5v15A4.505 4.505 0 004.5 24h15a4.505 4.505 0 004.5-4.5v-15A4.505 4.505 0 0019.5 0zm-15 3h15A1.5 1.5 0 0121 4.5v15a1.492 1.492 0 01-.44 1.06l-8.732-8.732a4 4 0 00-5.656 0L3 15V4.5A1.5 1.5 0 014.5 3z"></path>
                                      <circle cx="15.5" cy="7.5" r="2.5"></circle>
                                    </svg>)}
                                    <div className="new-chat-inbox-list-info-last-seen">
                                      <p>{chat.age}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="new-chat-inbox-list-info">
                                  <div className="new-chat-inbox-list-info-top-sec">
                                    {/* <div className="auto-reply-turn-off-badge">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 16 13"
                                  >
                                    <path
                                      fill="#03363d"
                                      stroke="#03363d"
                                      strokeWidth="0.2"
                                      d="M15.5 6.363v2.4c0 .662-.505 1.2-1.125 1.2h-.402c-.182 1.356-1.278 2.4-2.598 2.4h-6.75c-1.32 0-2.416-1.044-2.598-2.4h-.402c-.62 0-1.125-.538-1.125-1.2v-2.4c0-.661.505-1.2 1.125-1.2H2v-.4c0-1.544 1.178-2.8 2.625-2.8h3v-1.2c0-.22.168-.4.375-.4s.375.18.375.4v1.2h3c1.447 0 2.625 1.256 2.625 2.8v.4h.375c.62 0 1.125.539 1.125 1.2zm-13.875 2.8H2v-3.2h-.375c-.207 0-.375.18-.375.4v2.4c0 .22.168.4.375.4zm11.625.4v-4.8c0-1.103-.841-2-1.875-2h-6.75c-1.034 0-1.875.897-1.875 2v4.8c0 1.103.841 2 1.875 2h6.75c1.034 0 1.875-.897 1.875-2zm1.5-3.2c0-.22-.168-.4-.375-.4H14v3.2h.375c.207 0 .375-.18.375-.4v-2.4zm-7.5 1.2c0 .883-.673 1.6-1.5 1.6s-1.5-.717-1.5-1.6c0-.882.673-1.6 1.5-1.6s1.5.718 1.5 1.6zm-.75 0c0-.441-.337-.8-.75-.8s-.75.359-.75.8c0 .442.337.8.75.8s.75-.358.75-.8zm5.25 0c0 .883-.673 1.6-1.5 1.6s-1.5-.717-1.5-1.6c0-.882.673-1.6 1.5-1.6s1.5.718 1.5 1.6zm-.75 0c0-.441-.337-.8-.75-.8s-.75.359-.75.8c0 .442.337.8.75.8s.75-.358.75-.8z"
                                    ></path>
                                  </svg>
                                  <span>Bot</span>
                                </div> */}
                                  {chat.bot_name ? <div className="new-chat-inbox-list-bot-name">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="none"
                                      viewBox="0 0 20 15"
                                    >
                                      <path
                                        fill="#000"
                                        d="M18.5 6H18c0-2.206-1.794-4-4-4h-3V1a1 1 0 10-2 0v1H6C3.794 2 2 3.794 2 6h-.5C.673 6 0 6.673 0 7.5v2c0 .827.673 1.5 1.5 1.5H2c0 2.206 1.794 4 4 4h8c2.206 0 4-1.794 4-4h.5c.827 0 1.5-.673 1.5-1.5v-2c0-.827-.673-1.5-1.5-1.5zM8.457 9.043a.999.999 0 11-1.414 1.414L6.5 9.914l-.543.543a.997.997 0 01-1.414 0 .999.999 0 010-1.414l.543-.543-.543-.543a.999.999 0 111.414-1.414l.543.543.543-.543a.999.999 0 111.414 1.414l-.543.543.543.543zm7 0a.999.999 0 11-1.414 1.414l-.543-.543-.543.543a.997.997 0 01-1.414 0 .999.999 0 010-1.414l.543-.543-.543-.543a.999.999 0 111.414-1.414l.543.543.543-.543a.999.999 0 111.414 1.414l-.543.543.543.543z"
                                      ></path>
                                    </svg>
                                    <span>{chat.bot_name}</span>
                                  </div> : null}
                                    <div
                                      className={getChatTypeName(
                                        chat.widget_type
                                      )}
                                    >
                                      {chat.widget_type_formatted}
                                    </div>
                                    {/* <Toggle
                                    backgroundColorChecked="#048A89"
                                    backgroundColorUnchecked="#E5E4F6"
                                    width={50}
                                    height={25}
                                    sliderWidth={19}
                                    sliderHeight={19}
                                    translate={23}
                                  /> */}
                                  </div>
                                  {/* <div className="new-chat-inbox-list-info-last-seen">
                                    <p>{chat.age}</p>
                                  </div> */}
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="new-billings-footer-sec">
                          <div className="new-billings-pagination-sec">
                            <Pagination
                              page={page}
                              pageCount={Math.ceil(
                                chatList.data.total_chats / 5
                              )}
                              handlePageClick={handlePageClick}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="new-settings-preview-sec">
              {chatId ? <InboxChat chatId={chatId} /> : <NotChatFound />}
            </div>
          </div>
        </div>
      </div>
      ) : (!botsList.loading && Object.keys(botsList.data).length > 0 && Object.keys(botsList.data.bots).length <= 0) &&
        <div className="mt-5">
          <NoBotExists setAddBot={setAddBot} />
        </div>
    }
    {addBot && (
        <AddBotModal
          addBot={addBot}
          closeAddBotModal={closeAddBotModal}
          setAddBot={setAddBot}
        />
      )}
    </>
  ) : (
    <SomethingWentWrong
      buttonText="Retry"
      handleClick={() => dispatch(profileStart())}
    />
  );
};

export default NewChatInbox;
