import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from '../index';
import {
  billingAddressSaveFailure,
  billingAddressSaveSuccess,
  currrentSubscriptionPlanFailure,
  currrentSubscriptionPlanSuccess,
  generateStripeIntentFailure,
  generateStripeIntentSuccess,
  subscriptionListFailure,
  subscriptionListSuccess,
  subscriptionPlansFailure,
  subscriptionPlansSuccess,
  subscriptionSaveFailure,
  subscriptionSaveSuccess,
  subscriptionViewFailure,
  subscriptionViewSuccess,
  billingInvoiceDownloadFailure,
  billingInvoiceDownloadSuccess,
  requestEnterprisePlanSuccess,
  requestEnterprisePlanFailure,
} from "../slices/SubscriptionSlice";
import { profileSuccess } from "../slices/AdminSlice";

function* subscriptionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions",
    successNotify: false,
    payload: action.payload,
    success: subscriptionListSuccess,
    failure: subscriptionListFailure,
  });
}

function* subscriptionViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/show",
    successNotify: false,
    payload: action.payload,
    success: subscriptionViewSuccess,
    failure: subscriptionViewFailure,
  });
}

function* subscriptionSaveAPI(action) {
  let profileData = yield select(state => state.admin.profile.data);
  yield getCommonSaga({
    apiUrl: "subscriptions/store",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(subscriptionSaveSuccess(data));
      store.dispatch(generateStripeIntentSuccess({}));
      if (Object.keys(profileData).length > 0) {
        profileData = {...profileData, onboarding_step: 6}
        store.dispatch(profileSuccess(profileData))
      }
    },
    failure: subscriptionSaveFailure,
  });
}

function* generateStripeIntentAPI(action) {
  let profileData = yield select(state => state.admin.profile.data);
  yield getCommonSaga({
    apiUrl: "subscriptions/create",
    payload: action.payload,
    successCallback: true,
    successNotify: false,
    success: data => {
      if (!data.is_needs_to_pay && Object.keys(profileData).length > 0) {
        profileData = {...profileData, onboarding_step: 6}
        store.dispatch(profileSuccess(profileData))
      }
      store.dispatch(generateStripeIntentSuccess(data));
    },
    failure: generateStripeIntentFailure,
  })
}

function* subscriptionPlansAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscription_plans",
    payload: action.payload,
    successNotify: false,
    success: subscriptionPlansSuccess,
    failure: subscriptionPlansFailure,
  })
}

function* currrentSubscriptionPlanAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/current_subscription_plan",
    payload: action.payload,
    successNotify: false,
    success: currrentSubscriptionPlanSuccess,
    failure: currrentSubscriptionPlanFailure,
  })
}

function* billingAddressSaveAPI(action) {
  const currentSubscriptionData = yield select(state => state.subscription.currentSubscription.data);
  yield getCommonSaga({
    apiUrl: "user_billing_store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(billingAddressSaveSuccess(data));
      if (Object.keys(currentSubscriptionData).length > 0)
        store.dispatch(currrentSubscriptionPlanSuccess({
          ...currentSubscriptionData,
          user_billing_address: data.user_billing_address,
        }));
    },
    failure: billingAddressSaveFailure,
  })
}

function* billingInvoiceDownloadAPI(action) {
  yield getCommonSaga({
    apiUrl: "subscriptions/invoice",
    successNotify: false,
    payload: action.payload,
    success: billingInvoiceDownloadSuccess,
    failure: billingInvoiceDownloadFailure,
  });
}

function* requestEnterprisePlanAPI(action) {
  let profileData = yield select(state => state.admin.profile.data);
  yield getCommonSaga({
    apiUrl: "subscriptions/request_enterprise_plan",
    successNotify: true,
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(requestEnterprisePlanSuccess(data));
      if (action.payload.onboarding && Object.keys(profileData).length > 0) {
        profileData = {...profileData, onboarding_step: 6}
        store.dispatch(profileSuccess(profileData))
      }
    },
    failure: requestEnterprisePlanFailure,
  });
}


export default function* SubscriptionSaga() {
  yield all([
    yield takeLatest('subscription/subscriptionListStart', subscriptionListAPI),
    yield takeLatest('subscription/subscriptionViewStart', subscriptionViewAPI),
    yield takeLatest('subscription/subscriptionSaveStart', subscriptionSaveAPI),
    yield takeLatest('subscription/generateStripeIntentStart', generateStripeIntentAPI),
    yield takeLatest('subscription/subscriptionPlansStart', subscriptionPlansAPI),
    yield takeLatest('subscription/currrentSubscriptionPlanStart', currrentSubscriptionPlanAPI),
    yield takeLatest('subscription/billingAddressSaveStart', billingAddressSaveAPI),
    yield takeLatest('subscription/billingInvoiceDownloadStart', billingInvoiceDownloadAPI),
    yield takeLatest('subscription/requestEnterprisePlanStart', requestEnterprisePlanAPI),
  ]);
}

